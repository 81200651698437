import { useContext, useMemo } from 'react'
import Button from '~components/ui/Button/Button'
import ArchivedNotificationsIcon from '~components/ui/Icon/ArchivedNotificationsIcon'
import MenuVsIcon from '~components/ui/Icon/MenuVsIcon'
import { NotificationPanelTabs, NotificationsContext } from '~context/NotificationsContext'
import EmptyNotificationsLogo from './EmptyNotificationsLogo'

export default function EmptyNotifications() {
  const { notifications, dismissedNotifications, currentTab, setCurrentTab } =
    useContext(NotificationsContext)

  const { otherNotifications, Icon } = useMemo(() => {
    if (currentTab === NotificationPanelTabs.Active) {
      return { otherNotifications: dismissedNotifications, Icon: ArchivedNotificationsIcon }
    }
    return { otherNotifications: notifications, Icon: MenuVsIcon }
  }, [currentTab, notifications, dismissedNotifications])

  const navigateHandler = () => {
    if (currentTab === NotificationPanelTabs.Active) {
      setCurrentTab(NotificationPanelTabs.Dismissed)
    } else {
      setCurrentTab(NotificationPanelTabs.Active)
    }
  }

  return (
    <div className="flex flex-col w-full h-full items-center justify-center bg-gradient-to-b from-indigo-100 to-zinc-100">
      <div className="items-center">
        <EmptyNotificationsLogo />
      </div>
      <div className="mt-2">
        <p className="text-brand font-semibold">{`You don't have any
          ${currentTab === NotificationPanelTabs.Active ? 'new' : ''}${
          currentTab === NotificationPanelTabs.Cancelled ? 'cancelled' : ''
        }${currentTab === NotificationPanelTabs.CheaperOffers ? 'monitoring' : ''}${
          currentTab === NotificationPanelTabs.Dismissed ? 'dismissed' : ''
        }
        notifications`}</p>
      </div>
      {otherNotifications.length > 0 && (
        <Button variant="outline" className="mt-4 items-center" onClick={navigateHandler}>
          <Icon height={20} className="mt-1" />
          <p>{`${
            currentTab === NotificationPanelTabs.Active ? 'Dismissed' : 'New'
          } notifications`}</p>
        </Button>
      )}
    </div>
  )
}
