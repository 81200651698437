import { useMemo } from 'react'
import { Role, UserSummary } from '../../../types'

export interface AvatarProps {
  user: UserSummary
}

function AvatarProps({ user }: AvatarProps): JSX.Element {
  const initials = useMemo(() => {
    const fromName = [user.firstName?.[0], user.lastName?.[0]]
      .filter(Boolean)
      .join('')
      .toLocaleUpperCase()

    if (fromName.length > 0) {
      return fromName
    }

    return user.email[0]?.toLocaleUpperCase()
  }, [user])

  return (
    <div
      className={[
        'flex h-6 w-6 items-center justify-center rounded-full text-xxxs font-semibold leading-none text-white',
        user.role === Role.CrewSupervisor && 'bg-green-600',
        user.role === Role.CrewOperator && 'bg-green-600',
        user.role === Role.VesselMaster && 'bg-blue-300',
        user.role === Role.Manning && 'bg-fuchsia-600',
        user.role === Role.CrewManager && 'bg-green-600',
        user.role === Role.PortAgent && 'bg-orange-600',
        user.role === Role.TillaBackoffice && 'bg-brand',
        user.role === Role.TravelAgent && 'bg-yellow-300',
      ]
        .filter(Boolean)
        .join(' ')}
    >
      {initials}
    </div>
  )
}

export default AvatarProps
