/* eslint-disable */
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { isProd } from 'utils/checkEnv'
import { Me } from './UserContext'

interface IFernandContext {
  isFernandLoaded: boolean
  initializeFernand: (appId: string) => void
  initUser: (me: Me) => void
}

const FernandContext = createContext<IFernandContext | undefined>(undefined)

export const useFernand = (): IFernandContext => {
  const context = useContext(FernandContext)
  if (!context) {
    throw new Error('useFernand must be used within a FernandProvider')
  }
  return context
}

interface Props {
  children: ReactNode
}

interface FernandWindow extends Window {
  Fernand: Function
}

export default function FernandProvider({ children }: Props) {
  const [isFernandLoaded, setIsFernandLoaded] = useState(false)
  const appId = 'tilla-technologies'

  const initializeFernand = useCallback((appId: string) => {
    const w: FernandWindow = window as unknown as FernandWindow
    if (typeof w.Fernand !== 'function') {
      const f = function () {
        ;(f.q[arguments[0] === 'set' ? 'unshift' : 'push'] as any)(arguments)
      }
      f.q = [] as any
      w.Fernand = f
    }

    w.Fernand('init', appId)
  }, [])

  const initUser = useCallback(
    (me: Me) => {
      if (isFernandLoaded) {
        ;(window as unknown as FernandWindow).Fernand('set', {
          appId,
          user: {
            name: `${me.firstName} ${me.lastName}`,
            email: me.email,
            hash: me.fernandHash,
          },
        })
      }
    },
    [isFernandLoaded]
  )

  useEffect(() => {
    initializeFernand(appId)
  }, [])

  useEffect(() => {
    if (!isProd()) {
      setIsFernandLoaded(false)

      return () => {
        // empty return
      }
    }

    // Load Fernand scripts dynamically
    const script = document.createElement('script')
    script.src = 'https://messenger.getfernand.com/client.js'
    script.async = true
    script.onload = () => {
      // Fernand scripts have loaded
      setIsFernandLoaded(true)
    }
    document.body.appendChild(script)

    return () => {
      // Clean up script tag if the component unmounts
      document.body.removeChild(script)
    }
  }, [])

  const value = useMemo(
    () => ({
      isFernandLoaded,
      initializeFernand,
      initUser,
    }),
    [isFernandLoaded, initializeFernand, initUser]
  )

  return <FernandContext.Provider value={value}>{children}</FernandContext.Provider>
}
