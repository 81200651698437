import { DateTime } from 'luxon'
import {
  CrewChangesDateFilteringOption,
  CrewChangesSearchFilters,
  CrewChangesSearchParams,
} from '../types'

export const FAR_FUTURE = '9999-12-31T00:00:00.000Z'
export const FAR_PAST = '2000-01-01T00:00:00.000Z'

/**
 * Interprets the filter readable values (recent/current, all) and translates them into
 * search params for the query
 */
export default function getCrewChangesSearchParams(filters: CrewChangesSearchFilters) {
  let params: CrewChangesSearchParams = {
    completed: false,
    cancelled: filters.cancelled,
    archived: filters.archived,
    startAt: DateTime.fromISO(FAR_PAST).toISO(),
    etd: DateTime.fromISO(FAR_PAST).toISO(),
    endAt: DateTime.fromISO(FAR_FUTURE).toISO(),
  } // Default params

  if (filters.completed) {
    params = {
      ...params,
      completed: true,
      cancelled: undefined,
      ...(filters.dateFilterOption === CrewChangesDateFilteringOption.Current
        ? {
            startAt: DateTime.utc().minus({ days: 30 }).startOf('day').toISO(),
            endAt: DateTime.fromISO(FAR_FUTURE).toISO(),
            etd: DateTime.utc().minus({ days: 30 }).startOf('day').toISO(),
          }
        : {}),
    }
  } else {
    params = {
      ...params,
      completed: false,
      ...(filters.dateFilterOption === CrewChangesDateFilteringOption.Current
        ? {
            startAt: DateTime.utc().minus({ days: 14 }).startOf('day').toISO(),
            endAt: DateTime.utc().plus({ days: 30 }).startOf('day').toISO(),
            etd: DateTime.utc().minus({ days: 2 }).startOf('day').toISO(),
          }
        : {}),
    }

    if (filters.archived) {
      params = {
        ...params,
        completed: undefined,
        cancelled: undefined,
      }
    } else if (filters.cancelled) {
      params = {
        ...params,
        cancelled: true,
        completed: undefined,
        archived: false,
      }
    }
  }

  return params
}
