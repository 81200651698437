import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { useLoadTenantTravelAgentQuery } from '../api/tenants-gql.generated'
import { UserContext } from './UserContext'

export interface TravelAgent {
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
}

const DEFAULT_TRAVEL_AGENT: TravelAgent = {
  email: 'travel-tilla@tilla.cloud',
  firstName: 'Travel',
  lastName: 'Agent',
  phoneNumber: '',
}

interface ITenantPreferencesContext {
  travelAgent: TravelAgent
  updateTravelAgent: (value: { [key in keyof TravelAgent]?: string }) => void
  loading: boolean
}

export const TravelAgentContext = React.createContext<ITenantPreferencesContext>({
  travelAgent: DEFAULT_TRAVEL_AGENT,
  updateTravelAgent: () => undefined,
  loading: true,
})

const TravelAgentProvider = ({ children }: { children: ReactNode }) => {
  const [travelAgent, setTravelAgent] = useState<TravelAgent>(DEFAULT_TRAVEL_AGENT)

  const { isAuthenticated } = useContext(UserContext)
  const { loading } = useLoadTenantTravelAgentQuery({
    skip: !isAuthenticated,
    onCompleted(data) {
      const agent = data?.currentTenant?.preferences.travelAgent
      setTravelAgent(
        agent
          ? {
              email: agent.email,
              firstName: agent.firstName ?? '',
              lastName: agent.lastName ?? '',
              phoneNumber: agent.phoneNumber ?? '',
            }
          : DEFAULT_TRAVEL_AGENT
      )
    },
  })

  const updateTravelAgent = useCallback(
    (value: Partial<TravelAgent>) => {
      setTravelAgent({
        ...travelAgent,
        ...value,
      })
    },
    [travelAgent]
  )

  const context = useMemo(() => {
    return {
      loading,
      travelAgent,
      updateTravelAgent,
    }
  }, [loading, travelAgent, updateTravelAgent])

  return <TravelAgentContext.Provider value={context}>{children}</TravelAgentContext.Provider>
}

export default TravelAgentProvider
