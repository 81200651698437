/* eslint-disable react/jsx-props-no-spreading */
import { SVGProps } from 'react'

export default function PlusIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12.6666 7.66634V8.33301C12.6666 8.5171 12.5173 8.66634 12.3333 8.66634H8.66659V12.333C8.66659 12.5171 8.51735 12.6663 8.33325 12.6663H7.66659C7.48249 12.6663 7.33325 12.5171 7.33325 12.333V8.66634H3.66659C3.48249 8.66634 3.33325 8.5171 3.33325 8.33301V7.66634C3.33325 7.48225 3.48249 7.33301 3.66659 7.33301H7.33325V3.66634C7.33325 3.48225 7.48249 3.33301 7.66659 3.33301H8.33325C8.51735 3.33301 8.66659 3.48225 8.66659 3.66634V7.33301H12.3333C12.5173 7.33301 12.6666 7.48225 12.6666 7.66634Z" />
    </svg>
  )
}
