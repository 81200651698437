import React, { MouseEventHandler, ReactNode, SVGProps } from 'react'

export interface TabBarNavigationItemProps {
  href?: string
  isActive?: boolean
  children?: ReactNode
  onClick?: MouseEventHandler
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element
}

const TabBarNavigationItem = React.forwardRef<HTMLAnchorElement, TabBarNavigationItemProps>(
  function TabBarNavigation(
    { href, isActive, children, onClick, icon: NavigationItemIcon }: TabBarNavigationItemProps,
    ref
  ) {
    return (
      <a
        href={href}
        className={`h-full cursor-pointer pt-0.5 border-b-2 border-transparent flex items-center text-gray-800 font-semibold text-sm group ${
          isActive ? 'text-brand !border-brand' : 'hover:text-blue-400 hover:border-blue-50'
        }`}
        onClick={onClick}
        ref={ref}
      >
        {NavigationItemIcon && (
          <NavigationItemIcon
            className={[
              'w-6 h-6 mr-2.5',
              isActive ? 'text-brand' : 'text-gray-600 group-hover:text-blue-400',
            ]
              .filter(Boolean)
              .join(' ')}
          />
        )}
        {children}
      </a>
    )
  }
)

export default TabBarNavigationItem
