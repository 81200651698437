import clsx from 'clsx'
import { ReactNode } from 'react'

export interface TabBarHeaderProps {
  children?: ReactNode
  inset?: boolean
  className?: string
  fixedHeight?: boolean
}

function TabBarHeader({
  children,
  className,
  fixedHeight = true,
  inset = true,
}: TabBarHeaderProps): JSX.Element {
  return (
    <div
      className={clsx(
        'flex items-center border-b border-gray-200',
        { 'md:px-8': inset },
        { 'h-16': fixedHeight },
        className
      )}
    >
      {children}
    </div>
  )
}

export default TabBarHeader
