import clsx from 'clsx'
import { SVGProps } from 'react'
import { UiSizes } from '../types'

export interface IconProps {
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
  className?: string
  size?: UiSizes
  flip?: 'y' | 'x'
}

export default function Icon({ icon: BaseIcon, size = 'regular', flip, className }: IconProps) {
  const sizeClasses = clsx({
    'w-3 h-3': size === 'small',
    'w-5 h-5': size === 'large',
    'w-4 h-4': size === 'regular',
  })

  const dynamicClasses = clsx(className, sizeClasses, 'fill-current', {
    'transform-gpu -scale-x-100': flip === 'x',
    'transform-gpu -scale-y-100': flip === 'y',
  })

  return <BaseIcon className={dynamicClasses} />
}
