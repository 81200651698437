import { Transition } from '@headlessui/react'
import React, { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ErrorEventEmitter } from 'utils/errorHandling'
import Toast, { ToastType } from '~components/ui/Toast/Toast'

interface IToastContext {
  toast: (content: string, duration?: number, type?: ToastType) => void
}

export const ToastContext = React.createContext<IToastContext>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toast: (content: string, duration?: number, type?: ToastType) => undefined,
})

interface Props {
  children: ReactNode
}

export default function ToastProvider({ children }: Props) {
  const [isShowing, setIsShowing] = useState(false)
  const [toastContent, setToastContent] = useState<string>('')
  const [toastType, setToastType] = useState<ToastType>('info')

  const timer = useRef<ReturnType<typeof setTimeout>>()

  const toast = (content: string, duration = 5000, type: ToastType = 'info') => {
    setToastContent(content)
    setIsShowing(true)
    setToastType(type)

    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => {
      setIsShowing(false)
    }, duration)
  }

  const value = useMemo(
    () => ({
      toast,
    }),
    []
  )

  return (
    <ToastContext.Provider value={value}>
      {children}
      <Transition
        show={isShowing}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-1000"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Toast type={toastType}>{toastContent}</Toast>
      </Transition>
    </ToastContext.Provider>
  )
}

export function useToast() {
  const { toast } = useContext(ToastContext)

  useEffect(() => {
    const unsubscribe = ErrorEventEmitter.subscribe((error, context) => {
      if (context?.notifyUser) {
        toast(error.message, 5000, 'error')
      }
    })

    return () => {
      unsubscribe()
    }
  }, [toast])

  return toast
}
