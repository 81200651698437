import clsx from 'clsx'
import { ReactNode, SVGProps } from 'react'
import Icon from '../Icon/Icon'
import { UiSizes } from '../types'
import Button, { ButtonProps } from './Button'

export interface ButtonIconProps extends ButtonProps {
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
  children?: ReactNode
  iconOnly?: boolean
  iconDirection?: 'left' | 'right'
  iconSize?: UiSizes
  gapSize?: UiSizes
  iconFlip?: 'y' | 'x'
}

export default function ButtonIcon({
  iconOnly = false,
  children,
  iconDirection = 'left',
  icon,
  iconFlip,
  iconSize = undefined,
  gapSize = 'regular',
  ...buttonProps
}: ButtonIconProps) {
  const gapSizeClasses = clsx({
    'gap-x-2': gapSize === 'regular',
    'gap-x-1': gapSize === 'small',
    'gap-x-3': gapSize === 'large',
  })

  return (
    <>
      {iconOnly ? (
        <Button {...buttonProps}>
          <Icon icon={icon} flip={iconFlip} size={iconSize || buttonProps.size} />
        </Button>
      ) : (
        <Button {...buttonProps} className={clsx('group', buttonProps.className)}>
          <div
            className={clsx('flex items-center', gapSizeClasses, {
              'flex-row-reverse': iconDirection === 'right',
            })}
          >
            <Icon icon={icon} flip={iconFlip} size={iconSize || buttonProps.size} />
            <div>{children}</div>
          </div>
        </Button>
      )}
    </>
  )
}
