/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import { SeamanFieldsFragmentDoc } from './fragments.generated'
import { FlightSegmentFieldsFragmentDoc } from './flight-fragments.generated'
import { AirlineFieldsFragmentDoc } from './airlines-gql.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type DetectFlightItineraryChangesMutationVariables = Types.Exact<{
  params: Types.DetectFlightItineraryChangesParams
}>

export type DetectFlightItineraryChangesMutation = { detectFlightItineraryChanges: boolean }

export type FlightSegmentChangeFieldsFragment = {
  id: string
  changeType: Types.FlightSegmentChangeType
  active: boolean
  bucketId: string
  flightBookingId: string
  flightNumber: number | null
  seaman: {
    id: string
    firstName: string
    lastName: string
    nationalityIso: string
    rank: number | null
    birthday: string | null
    placeOfBirth: string | null
    firstVaccinationDate: string | null
    firstVaccinationBrand: string | null
    secondVaccinationDate: string | null
    secondVaccinationBrand: string | null
    updatedAt: string
  }
  flightSegment: {
    id: string
    flightNumber: number
    index: number
    departure: {
      timezoneOlson: string | null
      time: string
      name: string | null
      iata: string
      countryAlpha2: string | null
      city: string | null
      date: string
      terminal: string | null
    }
    arrival: {
      timezoneOlson: string | null
      time: string
      name: string | null
      iata: string
      countryAlpha2: string | null
      city: string | null
      date: string
      terminal: string | null
    }
    marketingCarrier: {
      id: string
      createdAt: string
      updatedAt: string
      iata: string
      name: string
      logo: string | null
    } | null
    operatingCarrier: {
      id: string
      createdAt: string
      updatedAt: string
      iata: string
      name: string
      logo: string | null
    } | null
  }
  departure: {
    date: string | null
    time: string | null
    iata: string | null
    timezoneOlson: string | null
    countryAlpha2: string | null
    name: string | null
    terminal: string | null
    city: string | null
  } | null
  arrival: {
    date: string | null
    time: string | null
    iata: string | null
    timezoneOlson: string | null
    countryAlpha2: string | null
    name: string | null
    terminal: string | null
    city: string | null
  } | null
  marketingCarrier: {
    id: string
    createdAt: string
    updatedAt: string
    iata: string
    name: string
    logo: string | null
  } | null
  operatingCarrier: {
    id: string
    createdAt: string
    updatedAt: string
    iata: string
    name: string
    logo: string | null
  } | null
}

export const FlightSegmentChangeFieldsFragmentDoc = gql`
  fragment FlightSegmentChangeFields on FlightSegmentChange {
    id
    changeType
    active
    bucketId
    flightBookingId
    seaman {
      ...SeamanFields
    }
    flightSegment {
      ...FlightSegmentFields
    }
    departure {
      date
      time
      iata
      timezoneOlson
      countryAlpha2
      name
      terminal
      city
    }
    arrival {
      date
      time
      iata
      timezoneOlson
      countryAlpha2
      name
      terminal
      city
    }
    marketingCarrier {
      ...AirlineFields
    }
    operatingCarrier {
      ...AirlineFields
    }
    flightNumber
  }
  ${SeamanFieldsFragmentDoc}
  ${FlightSegmentFieldsFragmentDoc}
  ${AirlineFieldsFragmentDoc}
`
export const DetectFlightItineraryChangesDocument = gql`
  mutation DetectFlightItineraryChanges($params: DetectFlightItineraryChangesParams!) {
    detectFlightItineraryChanges(params: $params)
  }
`
export type DetectFlightItineraryChangesMutationFn = Apollo.MutationFunction<
  DetectFlightItineraryChangesMutation,
  DetectFlightItineraryChangesMutationVariables
>

/**
 * __useDetectFlightItineraryChangesMutation__
 *
 * To run a mutation, you first call `useDetectFlightItineraryChangesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetectFlightItineraryChangesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detectFlightItineraryChangesMutation, { data, loading, error }] = useDetectFlightItineraryChangesMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useDetectFlightItineraryChangesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetectFlightItineraryChangesMutation,
    DetectFlightItineraryChangesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DetectFlightItineraryChangesMutation,
    DetectFlightItineraryChangesMutationVariables
  >(DetectFlightItineraryChangesDocument, options)
}
export type DetectFlightItineraryChangesMutationHookResult = ReturnType<
  typeof useDetectFlightItineraryChangesMutation
>
export type DetectFlightItineraryChangesMutationResult =
  Apollo.MutationResult<DetectFlightItineraryChangesMutation>
export type DetectFlightItineraryChangesMutationOptions = Apollo.BaseMutationOptions<
  DetectFlightItineraryChangesMutation,
  DetectFlightItineraryChangesMutationVariables
>
