/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type SearchAirlineQueryVariables = Types.Exact<{
  iata?: Types.InputMaybe<Types.Scalars['String']['input']>
  name?: Types.InputMaybe<Types.Scalars['String']['input']>
}>

export type SearchAirlineQuery = {
  airline: {
    id: string
    createdAt: string
    updatedAt: string
    iata: string
    name: string
    logo: string | null
  } | null
}

export type AirlineFieldsFragment = {
  id: string
  createdAt: string
  updatedAt: string
  iata: string
  name: string
  logo: string | null
}

export const AirlineFieldsFragmentDoc = gql`
  fragment AirlineFields on Airline {
    id
    createdAt
    updatedAt
    iata
    name
    logo
  }
`
export const SearchAirlineDocument = gql`
  query SearchAirline($iata: String, $name: String) {
    airline(iata: $iata, name: $name) {
      ...AirlineFields
    }
  }
  ${AirlineFieldsFragmentDoc}
`

/**
 * __useSearchAirlineQuery__
 *
 * To run a query within a React component, call `useSearchAirlineQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAirlineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAirlineQuery({
 *   variables: {
 *      iata: // value for 'iata'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSearchAirlineQuery(
  baseOptions?: Apollo.QueryHookOptions<SearchAirlineQuery, SearchAirlineQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchAirlineQuery, SearchAirlineQueryVariables>(
    SearchAirlineDocument,
    options
  )
}
export function useSearchAirlineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchAirlineQuery, SearchAirlineQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchAirlineQuery, SearchAirlineQueryVariables>(
    SearchAirlineDocument,
    options
  )
}
export function useSearchAirlineSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SearchAirlineQuery, SearchAirlineQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SearchAirlineQuery, SearchAirlineQueryVariables>(
    SearchAirlineDocument,
    options
  )
}
export type SearchAirlineQueryHookResult = ReturnType<typeof useSearchAirlineQuery>
export type SearchAirlineLazyQueryHookResult = ReturnType<typeof useSearchAirlineLazyQuery>
export type SearchAirlineSuspenseQueryHookResult = ReturnType<typeof useSearchAirlineSuspenseQuery>
export type SearchAirlineQueryResult = Apollo.QueryResult<
  SearchAirlineQuery,
  SearchAirlineQueryVariables
>
