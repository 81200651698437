import { ActiveCrewOperatorType } from 'api/types.generated'
import { useAssignVesselScheduleCrewOperatorMutation } from 'api/vessels-gql.generated'
import { useTrackEvent } from '~hooks/useTrackEvent'
import { CrewChangeSummary } from '../types'

export default function useUpdateCrewOperatorHook(
  crewChange: CrewChangeSummary,
  assigneeId: string
) {
  const trackEvent = useTrackEvent()
  const [updateCrewOperator, { data: coResult, loading: coLoading, error: coError }] =
    useAssignVesselScheduleCrewOperatorMutation({
      onCompleted() {
        trackEvent({
          event: 'Transferred vessel',
          metadata: { intercomMetadata: { pageUrl: window.location.href } },
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError() {},
    })

  const updateActiveCrewOperator = async () => {
    if (!assigneeId) {
      return
    }
    await updateCrewOperator({
      variables: {
        id: crewChange.vesselId,
        crewOperatorId: assigneeId,
        type:
          crewChange.activeCrewOperatorType === 'Primary'
            ? ActiveCrewOperatorType.Backup
            : ActiveCrewOperatorType.Primary,
      },
      refetchQueries: ['LoadCrewChanges'],
    })
  }

  return { updateActiveCrewOperator, coResult, coLoading, coError }
}
