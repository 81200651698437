/* istanbul ignore file */

// Gets the environment for the current context
export const DOMAIN_MAP = {
  development: 'tilla.test',
  staging: 'tilla.cloud',
  production: 'tilla.tech',
  uat: 'uat.tilla.cloud',
}

type VALID_ENVIRONMENTS = keyof typeof DOMAIN_MAP

export const getEnvironment = (): VALID_ENVIRONMENTS =>
  (process.env.APP_ENV as unknown as VALID_ENVIRONMENTS) || 'development'

export const getDomain = (environment: keyof typeof DOMAIN_MAP) => {
  return DOMAIN_MAP[environment] || DOMAIN_MAP.development
}
