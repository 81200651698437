import { useCallback, useEffect, useMemo, useState } from 'react'
import { globalErrorHandler } from 'utils/errorHandling'
import { useRestoreCrewChangeMutation } from '~api/crew-changes-gql.generated'
import {
  GetConflictingSeamenForCrewChangeQuery,
  useGetConflictingSeamenForCrewChangeLazyQuery,
} from '~api/manage-crew-changes-gql.generated'
import { CrewChangeSummary } from '~components/CrewChanges/types'
import Modal from '~components/ui/Modal/Modal'
import RestoreCrewChangeModalCompleteState from './CompleteState'
import RestoreCrewChangeModalConflictCrewChangeState from './ConflictCrewChangeState'
import RestoreCrewChangeModalConflictSeamanState from './ConflictSeamanState'
import RestoreCrewChangeModalErrorState from './ErrorState'
import RestoreCrewChangeModalRestoringState from './RestoringState'

interface Props {
  crewChange: CrewChangeSummary
  isOpen: boolean
  onCancel: () => void
}

enum RestoreCrewChangeState {
  Error,
  Restoring,
  ConflictSeaman,
  ConflictCrewChange,
  Complete,
}

export default function RestoreCrewChangeModal({ onCancel, isOpen, crewChange }: Props) {
  const [state, setState] = useState<RestoreCrewChangeState>(RestoreCrewChangeState.Restoring)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [conflictingCrewChanges, setConflictingCrewChanges] = useState<CrewChangeSummary[]>([])

  const [conflictingCrewChangeSeamen, setConflictingCrewChangeSeamen] = useState<
    GetConflictingSeamenForCrewChangeQuery['getConflictingSeamenForCrewChange']
  >([])

  const [getConflictingSeamenForCrewChange, { loading: isGettingConflictingSeamen }] =
    useGetConflictingSeamenForCrewChangeLazyQuery()

  const [restoreCrewChange, { loading: isRestoringCrewChange, reset }] =
    useRestoreCrewChangeMutation()

  const handleRestoreCrewChange = useCallback(
    async (bucketId: string) => {
      return restoreCrewChange({
        variables: {
          bucketId,
        },
        onCompleted({ crewChange: crewChangeData }) {
          const { restoreCrewChange: restoreCrewChangeData } = crewChangeData
          const {
            conflictingCrewChanges: conflictingCrewChangesData,
            success,
            error,
          } = restoreCrewChangeData

          if (success) {
            setState(RestoreCrewChangeState.Complete)
            return
          }

          if (conflictingCrewChangesData && conflictingCrewChangesData.length) {
            setConflictingCrewChanges(conflictingCrewChangesData)
            setState(RestoreCrewChangeState.ConflictCrewChange)
          }

          if (error) {
            setState(RestoreCrewChangeState.Error)
            setErrorMessage(error)
            globalErrorHandler(new Error(error), {
              isAsync: true,
              notifyUser: true,
            })
          }
        },
        onError(error) {
          globalErrorHandler(new Error(error.message), {
            isAsync: true,
            notifyUser: true,
          })
        },
      })
    },

    []
  )

  const handleCancel = useCallback(() => {
    onCancel()
    reset()

    if (state === RestoreCrewChangeState.Complete) {
      window.location.reload()
    }
  }, [onCancel, reset, state])

  const isLoading = useMemo(() => {
    return isRestoringCrewChange || isGettingConflictingSeamen
  }, [isRestoringCrewChange, isGettingConflictingSeamen])

  useEffect(() => {
    getConflictingSeamenForCrewChange({
      variables: {
        bucketId: crewChange.bucketId,
      },
      onCompleted({ getConflictingSeamenForCrewChange: data }) {
        if (data.length) {
          setConflictingCrewChangeSeamen(data)
          setState(RestoreCrewChangeState.ConflictSeaman)
        } else {
          // no conflicting seamen
          setState(RestoreCrewChangeState.Restoring)
          handleRestoreCrewChange(crewChange.bucketId)
        }
      },
    })
  }, [])

  const renderContent = () => {
    switch (state) {
      case RestoreCrewChangeState.Restoring:
        return (
          <RestoreCrewChangeModalRestoringState isLoading={isLoading} onCancel={handleCancel} />
        )
      case RestoreCrewChangeState.Complete:
        return (
          <RestoreCrewChangeModalCompleteState onCancel={handleCancel} crewChange={crewChange} />
        )
      case RestoreCrewChangeState.ConflictSeaman:
        return (
          <RestoreCrewChangeModalConflictSeamanState
            conflictingCrewChangeSeamen={conflictingCrewChangeSeamen}
            onCancel={handleCancel}
            onRestore={() => {
              setState(RestoreCrewChangeState.Restoring)
              handleRestoreCrewChange(crewChange.bucketId)
            }}
          />
        )
      case RestoreCrewChangeState.ConflictCrewChange:
        return (
          <RestoreCrewChangeModalConflictCrewChangeState
            conflictingCrewChanges={conflictingCrewChanges}
            onCancel={handleCancel}
          />
        )
      case RestoreCrewChangeState.Error:
        return (
          <RestoreCrewChangeModalErrorState errorMessage={errorMessage} onCancel={handleCancel} />
        )
      default:
        return null
    }
  }

  return (
    <Modal
      title="Restore Crew Change"
      open={isOpen}
      disableClosingWhenDismissed
      width={
        state === RestoreCrewChangeState.ConflictSeaman ||
        state === RestoreCrewChangeState.ConflictCrewChange
          ? '3xl'
          : 'lg'
      }
      onClose={() => handleCancel()}
      disableClosing={state === RestoreCrewChangeState.Restoring}
      showCloseButton={state !== RestoreCrewChangeState.Restoring}
    >
      {renderContent()}
    </Modal>
  )
}
