import * as Sentry from '@sentry/nextjs'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { UserSummary } from '../types'

let sessionUrl = ''

export function getLogrocketUrl() {
  let url = LogRocket.sessionURL ?? sessionUrl
  if (url.toLowerCase().includes('must call')) {
    // Do not return an invalid URL (eg. "Must call LogRocket.init() before sessionURL becomes available.)
    url = ''
  }
  return url
}

export function initLogrocket(sessionId?: string) {
  const appID = process.env.NEXT_PUBLIC_LOGROCKET_APP_ID

  if (!appID) {
    return
  }
  LogRocket.init(appID, {
    release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  })

  setupLogRocketReact(LogRocket)
  LogRocket.getSessionURL((url) => {
    sessionUrl = url
    Sentry.getCurrentScope().setExtra('sessionURL', url).setExtra('sessionId', sessionId)
  })
}

export function logrocketIdentify(user: UserSummary, sessionId: string) {
  LogRocket.identify(user.id, {
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    sessionId,
  })
}

export function sentryIdentify(user: UserSummary) {
  Sentry.setUser({
    id: user.id,
    username: `${user.firstName} ${user.lastName}`,
    email: user.email,
  })
}
