import { UserRole } from 'api/types.generated'

export default function getCrewChangeRouteForUserRole(role: UserRole, crewChangeBucketId: string) {
  switch (role) {
    case UserRole.PortAgent:
      return `/port-agent/${crewChangeBucketId}`
    case UserRole.TravelAgent:
      return `/travel-agent/${crewChangeBucketId}`
    default:
      return `/crew-changes/${crewChangeBucketId}`
  }
}
