import { sum } from 'lodash'
import { useMemo } from 'react'
import ArrowRightIcon from '~components/ui/Icon/ArrowRightIcon'
import Icon from '~components/ui/Icon/Icon'
import WarningIcon from '~components/ui/Icon/WarningIcon'
import useIsBackofficeUser from '~hooks/useIsBackofficeUser'
import { CrewChangeSummary } from './types'

interface FlightsWarningBannerProps {
  archivedCrewChanges: CrewChangeSummary[]
  cancelledCrewChanges: CrewChangeSummary[]
  showArchivedCrewChanges: () => void
  showCancelledCrewChanges: () => void
}

export default function FlightsWarningBanner({
  archivedCrewChanges,
  cancelledCrewChanges,
  showArchivedCrewChanges,
  showCancelledCrewChanges,
}: FlightsWarningBannerProps) {
  const isBackOfficeUser = useIsBackofficeUser()

  const numberOfActiveFlightsFromCancelled = useMemo(() => {
    return cancelledCrewChanges.reduce(
      (acc, crewChange) =>
        sum([crewChange.ticketedFlightBookings, crewChange.bookedFlightBookings]) + acc,
      0
    )
  }, [cancelledCrewChanges])

  const numberOfCancelledCrewChangesWithActiveFlights = useMemo(() => {
    return cancelledCrewChanges.filter(
      (crewChange) => crewChange.ticketedFlightBookings > 0 || crewChange.bookedFlightBookings > 0
    )
  }, [cancelledCrewChanges])

  const numberOfActiveFlightsFromArchived = useMemo(() => {
    return archivedCrewChanges.reduce(
      (acc, crewChange) =>
        sum([crewChange.ticketedFlightBookings, crewChange.bookedFlightBookings]) + acc,
      0
    )
  }, [archivedCrewChanges])

  // If there are no active flights from cancelled or archived crew changes, don't show the banner
  if (numberOfActiveFlightsFromCancelled === 0 && numberOfActiveFlightsFromArchived === 0) {
    return <></>
  }

  // If there are no active flights from cancelled crew changes and the user is not backoffice, don't show the banner
  if (numberOfActiveFlightsFromCancelled === 0 && !isBackOfficeUser) {
    return <></>
  }

  return (
    <div className="space-y-3 sm:space-y-0 sm:flex p-3 text-sm leading-none border rounded-lg text-gray-800 bg-red-50 border-red-200">
      <span className="block w-5 h-5 sm:mr-3 grow-0 shrink-0 text-red-600" aria-label="Warning">
        <WarningIcon />
      </span>
      <div className="flex flex-col items-start gap-3">
        {/* Title */}
        <div className="text-red-500 font-semibold text-base inline-flex items-center space-x-1">
          <span>Active Flights in</span>
          {!!numberOfActiveFlightsFromCancelled && <span>Cancelled</span>}

          {/* If both cancelled and archived are present */}
          {!!numberOfActiveFlightsFromArchived &&
            !!numberOfActiveFlightsFromCancelled &&
            isBackOfficeUser && <span>&</span>}

          {!!numberOfActiveFlightsFromArchived && isBackOfficeUser && <span>Archived</span>}

          <span>Crew Changes</span>
        </div>

        {/* Cancelled Flights */}
        {!!numberOfActiveFlightsFromCancelled && (
          <div className="inline-flex space-x-3">
            <div className="text-sm space-x-1 text-black">
              <span>You have</span>

              <span className="font-bold">
                {numberOfActiveFlightsFromCancelled} active flight
                {numberOfActiveFlightsFromCancelled > 1 ? 's' : ''}
              </span>

              <span>across</span>

              <span className="font-bold space-x-1">
                <span>{numberOfCancelledCrewChangesWithActiveFlights.length} cancelled</span>

                <span>
                  crew change
                  {numberOfCancelledCrewChangesWithActiveFlights.length > 1 ? 's' : ''}
                </span>
              </span>
            </div>

            <span
              className=" text-red-500 inline-flex items-center space-x-1 cursor-pointer"
              onClick={showCancelledCrewChanges}
              aria-label="View Cancelled Crew Changes"
            >
              <span className="underline font-semibold text-sm">View</span>
              <Icon size="small" icon={ArrowRightIcon} />
            </span>
          </div>
        )}

        {/* Archived Flights */}
        {!!numberOfActiveFlightsFromArchived && isBackOfficeUser && (
          <div className="inline-flex space-x-3">
            <div className="text-sm space-x-1 text-black">
              <span>You have</span>

              <span className="font-bold">
                {numberOfActiveFlightsFromArchived} active flight
                {numberOfActiveFlightsFromArchived > 1 ? 's' : ''}
              </span>

              <span>across</span>

              <span className="font-bold space-x-1">
                <span>{archivedCrewChanges.length} archived</span>

                <span>
                  crew change
                  {archivedCrewChanges.length > 1 ? 's' : ''}
                </span>
              </span>
            </div>

            <span
              className=" text-red-500 inline-flex items-center space-x-1 cursor-pointer"
              onClick={showArchivedCrewChanges}
              aria-label="View Archived Crew Changes"
            >
              <span className="underline font-semibold text-sm">View</span>
              <Icon size="small" icon={ArrowRightIcon} />
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
