import { ClassAttributes, createElement, HTMLAttributes } from 'react'

type TableRowProps = HTMLAttributes<HTMLTableRowElement> & ClassAttributes<HTMLTableRowElement>
function TableRow(props: TableRowProps): JSX.Element {
  const { className, ...otherProps } = props

  return createElement('tr', {
    ...otherProps,
    className: ['text-sm font-normal border-b border-gray-200 last:border-b-0', className]
      .filter(Boolean)
      .join(' '),
  })
}

export default TableRow
