import { FetchResult } from '@apollo/client'
import { isNil } from 'lodash'
import { DateTime } from 'luxon'
import React, { Dispatch, ReactNode, SetStateAction, useCallback, useMemo, useState } from 'react'
import { globalErrorHandler } from 'utils/errorHandling'
import {
  CreateBookingFromPnrMutation,
  EditPnrMutation,
  useCreateBookingFromPnrMutation,
  useEditPnrMutation,
} from '~api/flight-documents-gql.generated'
import {
  CreateBookingFromPnrInput,
  Currency,
  EditPnrInput,
  FareType,
  FreeBagAllowanceType,
  FreeBagAllowanceWeightUnit,
} from '~api/types.generated'
import { CrewChangeQuery } from '~context/CrewChangeContext/types'

export interface AddFlightOfferWithPnrDetails {
  booking: CreateBookingFromPnrInput
  fields: Record<keyof CreateBookingFromPnrInput, Record<'required' | 'valid', boolean>>
  isLoading?: boolean
}

export interface EditFlightOfferWithPnrDetails {
  booking: EditPnrInput
  fields: Record<keyof EditPnrInput, Record<'required' | 'valid', boolean>>
  isLoading?: boolean
}

export enum FlightOfferModalState {
  adding = 'adding',
  editing = 'editing',
  errored = 'errored',
}

export interface FlightOfferBaggage {
  type: FreeBagAllowanceType
  amount: number
}

interface TravelAgentPnrContext {
  flightOfferAdd: AddFlightOfferWithPnrDetails
  flightOfferEdit: EditFlightOfferWithPnrDetails
  modalState: FlightOfferModalState
  additionalSeamen: CrewChangeQuery.CrewChangeSeaman[]
  setAdditionalSeamen: Dispatch<SetStateAction<CrewChangeQuery.CrewChangeSeaman[]>>
  errors: boolean
  setModalState: Dispatch<SetStateAction<FlightOfferModalState>>
  setFlightOfferAdd: Dispatch<SetStateAction<AddFlightOfferWithPnrDetails>>
  setFlightOfferEdit: Dispatch<SetStateAction<EditFlightOfferWithPnrDetails>>
  handelReset: () => void
  handleAddValidation: (flightOffer: AddFlightOfferWithPnrDetails) => boolean
  handleEditValidation: (flightOffer: EditFlightOfferWithPnrDetails) => boolean
  handleCreateBookingFromPnr: (
    booking: AddFlightOfferWithPnrDetails['booking'],
    bucketId: string,
    seamanId: string
  ) => Promise<FetchResult<CreateBookingFromPnrMutation>[]>
  handleEditBookingFromPnr: (
    booking: EditFlightOfferWithPnrDetails['booking']
  ) => Promise<FetchResult<EditPnrMutation>>
}

const initialBookingFromPnrAdd: CreateBookingFromPnrInput = {
  bucketId: '',
  comment: '',
  currency: Currency.Usd,
  fareType: FareType.UniFares,
  pnr: '',
  fbc: null,
  seamanId: '',
  lastTicketingTime: DateTime.utc().toString(),
  freeBagAllowance: 0,
  freeBagAllowanceType: FreeBagAllowanceType.NumberOfPieces,
  freeBagAllowanceWeightUnit: FreeBagAllowanceWeightUnit.Kilogram,
  fareAmountWithCommission: 0,
}

const initialBookingFromPnrEdit: EditPnrInput = {
  bookingId: '',
  comment: '',
  currency: Currency.Usd,
  fareType: FareType.UniFares,
  fareAmountWithCommission: 0,
  pnr: '',
  fbc: null,
  lastTicketingTime: DateTime.utc().toString(),
  freeBagAllowance: 0,
  freeBagAllowanceType: FreeBagAllowanceType.NumberOfPieces,
  freeBagAllowanceWeightUnit: FreeBagAllowanceWeightUnit.Kilogram,
}

const initialFlightOfferAdd = (): AddFlightOfferWithPnrDetails => ({
  isLoading: false,
  booking: initialBookingFromPnrAdd,
  // Controls the state of the required fields
  fields: {
    bucketId: { required: false, valid: true },
    comment: { required: false, valid: false },
    currency: { required: true, valid: false },
    fareType: { required: true, valid: false },
    pnr: { required: true, valid: false },
    fbc: { required: false, valid: true },
    seamanId: { required: false, valid: true },
    lastTicketingTime: { required: true, valid: false },
    freeBagAllowance: { required: false, valid: false },
    freeBagAllowanceType: { required: false, valid: false },
    freeBagAllowanceWeightUnit: { required: false, valid: false },
    fareAmountWithCommission: { required: true, valid: false },
  },
})

const initialFlightOfferEdit = (): EditFlightOfferWithPnrDetails => ({
  isLoading: false,
  booking: initialBookingFromPnrEdit,
  // Controls the state of the required fields
  fields: {
    bookingId: { required: false, valid: true },
    comment: { required: false, valid: false },
    currency: { required: true, valid: false },
    fareType: { required: true, valid: false },
    pnr: { required: true, valid: false },
    fbc: { required: false, valid: true },
    lastTicketingTime: { required: true, valid: false },
    freeBagAllowance: { required: false, valid: false },
    freeBagAllowanceType: { required: false, valid: false },
    freeBagAllowanceWeightUnit: { required: false, valid: false },
    fareAmountWithCommission: { required: true, valid: false },
  },
})

const initialContext: TravelAgentPnrContext = {
  flightOfferAdd: initialFlightOfferAdd(),
  flightOfferEdit: initialFlightOfferEdit(),
  modalState: FlightOfferModalState.adding,
  additionalSeamen: [],
  setAdditionalSeamen: () => undefined,
  errors: false,
  setModalState: () => undefined,
  setFlightOfferEdit: () => undefined,
  setFlightOfferAdd: () => undefined,
  handelReset: () => undefined,
  handleAddValidation: () => false,
  handleEditValidation: () => false,
  handleCreateBookingFromPnr: () => Promise.resolve([]),
  handleEditBookingFromPnr: () => Promise.resolve({}),
}

export const TravelAgentPnrContext = React.createContext<TravelAgentPnrContext>(initialContext)

interface Props {
  children: ReactNode
}

export default function TravelAgentPnrProvider({ children }: Props) {
  const [modalState, setModalState] = useState<FlightOfferModalState>(FlightOfferModalState.adding)

  const [flightOfferAdd, setFlightOfferAdd] = useState<AddFlightOfferWithPnrDetails>(
    initialFlightOfferAdd()
  )

  const [flightOfferEdit, setFlightOfferEdit] = useState<EditFlightOfferWithPnrDetails>(
    initialFlightOfferEdit()
  )

  const [additionalSeamen, setAdditionalSeamen] = useState<CrewChangeQuery.CrewChangeSeaman[]>([])

  const [errors, setErrors] = useState(false)

  const handelReset = useCallback(() => {
    setModalState(FlightOfferModalState.adding)
    setErrors(false)

    setFlightOfferAdd(initialFlightOfferAdd())
    setFlightOfferEdit(initialFlightOfferEdit())
  }, [])

  const [createBookingFromPnr] = useCreateBookingFromPnrMutation()
  const [editBookingFromPnr] = useEditPnrMutation()

  const handleCreateBookingFromPnr = useCallback(
    async (
      booking: AddFlightOfferWithPnrDetails['booking'],
      bucketId: string,
      seamanId: string
    ) => {
      if (!booking) {
        throw new Error('Booking is undefined')
      }

      try {
        const seamenIds = [...additionalSeamen.map((x) => x.seaman.id), seamanId]

        if (seamenIds.length === 1) {
          return [
            await createBookingFromPnr({
              variables: {
                params: {
                  ...booking,
                  bucketId,
                  seamanId: seamenIds[0],
                },
              },
            }),
          ]
        }

        return Promise.all(
          seamenIds.map((id) => {
            return createBookingFromPnr({
              variables: {
                params: {
                  ...booking,
                  bucketId,
                  seamanId: id,
                },
              },
            })
          })
        )
      } catch (error: any) {
        globalErrorHandler(error, {
          isAsync: true,
          notifyUser: true,
        })

        return Promise.resolve([])
      }
    },
    [additionalSeamen, createBookingFromPnr]
  )

  const handleEditBookingFromPnr = useCallback(
    async (booking: EditFlightOfferWithPnrDetails['booking']) => {
      if (!booking) {
        throw new Error('Booking is undefined')
      }

      const params: EditPnrInput = {
        ...booking,
        bookingId: booking.bookingId,
        fareAmountWithCommission: booking.fareAmountWithCommission,
        // PNR is not supposed to be editable
        pnr: flightOfferEdit.booking.pnr,
      }

      return editBookingFromPnr({
        variables: {
          params,
        },
      })
    },
    [editBookingFromPnr, flightOfferEdit.booking.pnr]
  )

  const validateRequiredFields = useCallback(
    (flightOfferParams: AddFlightOfferWithPnrDetails | EditFlightOfferWithPnrDetails) => {
      const { booking, fields } = flightOfferParams

      // Early return if booking or fields are undefined
      if (!booking) {
        throw new Error('Booking is undefined')
      }

      if (!fields) {
        throw new Error('Fields are undefined')
      }

      let errored = false

      // Use object destructuring and spread syntax for clarity
      const validatedFields = { ...fields }

      // Iterate through the keys of fields
      for (const field of Object.keys(fields) as (keyof typeof fields)[]) {
        const bookingValueValid = !!booking[field] && !isNil(booking[field])

        // Check if the field is required
        const isRequired = fields[field].required

        // Update the validatedFields object
        validatedFields[field] = {
          valid: bookingValueValid,
          required: isRequired,
        }

        // If the field is required and the value is undefined, set errored to true
        if (isRequired && !bookingValueValid) {
          errored = true
        }
      }

      return {
        errored,
        validatedFields,
      }
    },
    []
  )

  const handleAddValidation = useCallback(
    (flightOfferParams: AddFlightOfferWithPnrDetails) => {
      const { errored, validatedFields } = validateRequiredFields(flightOfferParams)

      setErrors(errored)

      setFlightOfferAdd((prevFlightOffer) => ({
        ...prevFlightOffer,
        fields: validatedFields as Record<
          keyof CreateBookingFromPnrInput,
          Record<'required' | 'valid', boolean>
        >,
      }))

      return errors
    },
    [errors, validateRequiredFields]
  )

  const handleEditValidation = useCallback(
    (flightOfferParams: EditFlightOfferWithPnrDetails) => {
      const { errored, validatedFields } = validateRequiredFields(flightOfferParams)

      setErrors(errored)

      setFlightOfferEdit((prevFlightOffer) => ({
        ...prevFlightOffer,
        fields: validatedFields as Record<
          keyof EditPnrInput,
          Record<'required' | 'valid', boolean>
        >,
      }))
      return errors
    },
    [errors, validateRequiredFields]
  )

  const context = useMemo(
    () => ({
      flightOfferAdd,
      flightOfferEdit,
      modalState,
      errors,
      setModalState,
      setFlightOfferAdd,
      setFlightOfferEdit,
      handelReset,
      additionalSeamen,
      setAdditionalSeamen,
      handleAddValidation,
      handleEditValidation,
      handleCreateBookingFromPnr,
      handleEditBookingFromPnr,
    }),
    [
      flightOfferAdd,
      flightOfferEdit,
      modalState,
      errors,
      handelReset,
      additionalSeamen,
      setAdditionalSeamen,
      handleAddValidation,
      handleEditValidation,
      handleCreateBookingFromPnr,
      handleEditBookingFromPnr,
    ]
  )

  return <TravelAgentPnrContext.Provider value={context}>{children}</TravelAgentPnrContext.Provider>
}
