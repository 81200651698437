import { useContext, useState } from 'react'
import Alert from '~components/ui/Alert/Alert'
import Button from '~components/ui/Button/Button'
import FormGroup from '~components/ui/Form/FormGroup'
import FormTextArea from '~components/ui/Form/FormTextArea'
import Modal from '~components/ui/Modal/Modal'
import { UserContext } from '~context/UserContext'
import { useUpdateMeMutation } from '../../api/user-gql.generated'
import ensure from '../../utils/ensure'

interface Props {
  onClose: () => void
}

export default function EditEmailSignatureModal({ onClose }: Props) {
  const userContext = useContext(UserContext)
  const [signature, setSignature] = useState(userContext.user.emailSignature ?? '')
  const [updateUser, { error, data, loading }] = useUpdateMeMutation()
  const success = !error && !!data?.me

  const handleAction = async (action: 'confirm' | 'reset') => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { data } = await updateUser({
        variables: { data: { emailSignature: action === 'confirm' ? signature : null } },
      })
      const user = ensure(data?.me)
      userContext.setUser(user)
    } catch (e) {
      // nothing
    }
  }

  return (
    <>
      <Modal open onClose={onClose} title="Edit your email signature" inset>
        {!loading && !success && (
          <>
            <div className="pt-2">
              <FormGroup label="Signature" htmlFor="edit-signature">
                <FormTextArea
                  className="h-28"
                  id="edit-signature"
                  onChange={setSignature}
                  value={signature}
                >
                  {signature}
                </FormTextArea>
              </FormGroup>
            </div>
            <div className="pt-4 flex flex-row justify-between w-full">
              <Button onClick={() => handleAction('reset')} variant="outline">
                Reset
              </Button>
              <div className="space-x-3">
                <Button onClick={onClose} variant="outline">
                  Back
                </Button>
                <Button onClick={() => handleAction('confirm')}>Confirm</Button>
              </div>
            </div>
          </>
        )}
        {success && (
          <>
            <Alert variant="success" title="Successfully updated email signature" />
            <div className="pt-4 flex flex-row justify-end w-full">
              <Button onClick={onClose} variant="outline">
                Close
              </Button>
            </div>
          </>
        )}
        {!!error && (
          <>
            <Alert variant="danger" title="Something went wrong">
              If the problem persists, please contact support.
            </Alert>
            <div className="pt-4 flex flex-row justify-end w-full">
              <Button onClick={onClose} variant="outline">
                Close
              </Button>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}
