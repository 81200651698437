import { ReactNode } from 'react'

export default function Container({ children }: { children: ReactNode }): JSX.Element {
  return <main className="px-6 md:px-8 py-10">{children}</main>
}

export function WithContainer({
  withContainer,
  children,
}: {
  withContainer: boolean
  children: ReactNode
}): JSX.Element {
  return withContainer ? <Container>{children}</Container> : <>{children}</>
}
