import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { UserContext } from '~context/UserContext'
import { isProd } from '../utils/checkEnv'
import { initHotjar } from '../utils/hotjar'
import { initLogrocket, logrocketIdentify, sentryIdentify } from '../utils/log-rocket'

export interface ILogContext {
  sessionId: string
}

const initialContext: ILogContext = {
  sessionId: '',
}

export const LogContext = createContext<ILogContext>(initialContext)

export default function LogProvider({ children }: { children: ReactNode }) {
  /*
   * This is the ID displayed to users when the app crashes and can be used to search the logs for the crashed session reported by users
   */
  const [sessionId, setSessionId] = useState('')
  const userContext = useContext(UserContext)
  const { user, isAuthenticated } = userContext

  useEffect(() => {
    if (isProd()) {
      const generatedUuid = uuid()
      setSessionId(generatedUuid)
      initHotjar()
      initLogrocket(generatedUuid)
    }
  }, [])

  useEffect(() => {
    if (user && isAuthenticated && isProd()) {
      logrocketIdentify(user, sessionId)
    }
    if (user) {
      sentryIdentify(user)
    }
  }, [isAuthenticated, user])

  const context = useMemo(
    () => ({
      sessionId,
    }),
    [sessionId]
  )

  return <LogContext.Provider value={context}>{children}</LogContext.Provider>
}
