import React from 'react'
import { UiSizes } from '../types'

interface CheckMarkIconProps extends React.SVGProps<SVGSVGElement> {
  size?: UiSizes
}

const CheckMarkIcon: React.FC<CheckMarkIconProps> = ({ size = 'regular', ...props }) => {
  const dimensions = {
    custom: undefined,
    small: 16,
    regular: 18,
    large: 20,
  }

  const iconSize = dimensions[size]

  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0303 4.46967C12.3232 4.76256 12.3232 5.23744 12.0303 5.53033L7.53033 10.0303C7.23744 10.3232 6.76256 10.3232 6.46967 10.0303L3.96967 7.53033C3.67678 7.23744 3.67678 6.76256 3.96967 6.46967C4.26256 6.17678 4.73744 6.17678 5.03033 6.46967L7 8.43934L10.9697 4.46967C11.2626 4.17678 11.7374 4.17678 12.0303 4.46967Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CheckMarkIcon
