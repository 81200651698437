const formatDuration = (durationInMilliseconds: number): string => {
  const inMinutes = Math.floor(durationInMilliseconds / 1000 / 60)

  const days = inMinutes / (24 * 60)
  const roundedDays = Math.floor(inMinutes / (24 * 60))
  const hours =
    days >= 1.5 ? Math.floor((inMinutes - roundedDays * 24 * 60) / 60) : Math.floor(inMinutes / 60)
  const minutes = inMinutes % 60

  if (days >= 1.5) {
    return hours === 0 ? `${roundedDays}d` : `${roundedDays}d ${hours}hr`
  }

  if (hours === 0) {
    return minutes === 0 ? 'Instant' : `${minutes}min`
  }

  if (minutes === 0) {
    return `${hours}hr`
  }

  return `${hours}hr ${minutes}min`
}

export default formatDuration
