import { useContext } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Button from '~components/ui/Button/Button'
import ButtonIcon from '~components/ui/Button/ButtonIcon'
import Card from '~components/ui/Card/Card'
import CopyIcon from '~components/ui/Icon/CopyIcon'
import ErrorIcon from '~components/ui/Icon/ErrorIcon'
import { LogContext } from '~context/LogContext'
import { useToast } from '~context/ToastContext'

export default function ErrorFallback() {
  const logContext = useContext(LogContext)
  const { sessionId } = logContext
  const toast = useToast()

  const copyHandler = () => {
    toast('Copied reference to clipboard')
  }

  return (
    <>
      <div className="m-4">
        <Card
          title="Something went wrong"
          subtitle="We encountered an error while processing your request. Our engineers are looking into the issue."
          icon={ErrorIcon}
        >
          <div className="justify-between w-full h-full content-center items-center">
            {sessionId && (
              <div>
                <h4 className="text-xl text-center leading-none text-gray-500 mt-3">
                  Please use the following reference when reporting this issue:
                </h4>
                <div className="flex justify-center w-full h-full content-center items-center">
                  <CopyToClipboard text={sessionId} onCopy={copyHandler}>
                    <h4 className="font-bold text-center m-1 leading-none text-gray-600 rounded-md">
                      {sessionId}
                    </h4>
                  </CopyToClipboard>
                  <CopyToClipboard text={sessionId} onCopy={copyHandler}>
                    <ButtonIcon className="self-center" icon={CopyIcon} iconOnly size="small" />
                  </CopyToClipboard>
                </div>
              </div>
            )}
            <Button type="button" className="self-center" href="/">
              Return to Home
            </Button>
          </div>
        </Card>
      </div>
    </>
  )
}
