import getDateTimeForFlightLeg from '@tilla/backend/shared/utils/get-date-time-for-flight-leg'
import Link from 'next/link'
import { MouseEvent, useCallback, useContext, useMemo, useState } from 'react'
import formatDateTime from 'utils/formatDateTime'
import getCrewChangeRouteForUserRole from 'utils/getCrewChangeRouteForUserRole'
import { NotificationsQuery } from '~api/notifications-gql.generated'
import { NotificationType } from '~api/types.generated'
import FlightCancelledIcon from '~components/ui/Icon/FlightCancelledIcon'
import Icon from '~components/ui/Icon/Icon'
import { NotificationsContext } from '~context/NotificationsContext'
import useCurrentUser from '~hooks/useCurrentUser'
import useRelativeDateTime from '~hooks/useRelativeDateTime'
import { useTrackEvent } from '~hooks/useTrackEvent'
import DismissNotificationButton from './DismissNotificationButton'

interface FlightSegmentChangedCardProps {
  notification: NotificationsQuery['notifications'][number]
  changedSegmentNotification: NotificationsQuery['notifications'][number]['changedSegmentsNotifications'][number]
}

export default function FlightSegmentChangedCard(props: FlightSegmentChangedCardProps) {
  const { changedSegmentNotification, notification } = props
  const { dismissNotifications, markNotificationsAsRead } = useContext(NotificationsContext)
  const { user } = useCurrentUser()
  const [isHovered, setIsHovered] = useState(false)
  const [isDismissing, setIsDismissing] = useState(false)

  const relativeCreatedAt = useRelativeDateTime(changedSegmentNotification.notification.createdAt)
  const trackEvent = useTrackEvent()

  const roleRoute = useMemo<string>(() => {
    return getCrewChangeRouteForUserRole(user.role, changedSegmentNotification.change.bucketId)
  }, [user.role, changedSegmentNotification.change.bucketId])

  const dismissHandler = useCallback(
    async (e: MouseEvent, restore?: boolean) => {
      e.preventDefault()
      setIsDismissing(true)
      await dismissNotifications(
        [changedSegmentNotification.notification],
        NotificationType.ChangedSegments,
        restore
      )
      setIsDismissing(false)
    },
    [changedSegmentNotification.notification, dismissNotifications]
  )

  const onClickHandler = useCallback(() => {
    if (!changedSegmentNotification.notification.read) {
      markNotificationsAsRead([changedSegmentNotification.notification.id])
    }
    trackEvent({
      event: 'Clicked on a notification',
      metadata: {
        mixpanelProperties: {
          type: NotificationType.ChangedSegments,
          age: relativeCreatedAt,
          vessel: notification.vessel.charterName,
          ETA: notification.vesselSchedule.ETA,
          ccUrl: `https://${window.location.host}/crew-changes/${changedSegmentNotification.change.bucketId}`,
        },
      },
    })
  }, [
    changedSegmentNotification.notification.read,
    changedSegmentNotification.notification.id,
    changedSegmentNotification.change.bucketId,
    trackEvent,
    relativeCreatedAt,
    notification.vessel.charterName,
    notification.vesselSchedule.ETA,
    markNotificationsAsRead,
  ])

  const renderFlightSegmentInfo = useMemo(() => {
    const { departure, arrival, flightSegment } = changedSegmentNotification.change

    if (!departure || !arrival) {
      return null
    }

    const date = departure.date || flightSegment.departure.date
    const time = departure.time || flightSegment.departure.time
    const departureIata = departure.iata || flightSegment.departure.iata
    const arrivalIata = arrival.iata || flightSegment.arrival.iata
    const { timezoneOlson } = notification.vesselSchedule.seaport

    return (
      <p className="text-sm font-light">{`${formatDateTime(
        getDateTimeForFlightLeg({
          date,
          time,
          timezoneOlson,
        })
      )} ${departureIata} -> ${formatDateTime(
        getDateTimeForFlightLeg({
          date,
          time,
          timezoneOlson,
        })
      )} ${arrivalIata}`}</p>
    )
  }, [changedSegmentNotification.change, notification.vesselSchedule.seaport])

  return (
    <div
      className="mx-2 my-2 cursor-pointer hover:bg-indigo-50 active:bg-indigo-100 hover:rounded-lg"
      onMouseLeave={() => setIsHovered(false)}
      onMouseEnter={() => setIsHovered(true)}
    >
      <Link href={roleRoute} passHref onClick={onClickHandler}>
        <div className="flex flex-row my-2 mx-2 gap-3">
          <div className="text-brand my-2 items-start">
            <Icon icon={FlightCancelledIcon} size="regular" className=" text-indigo-500" />
          </div>

          <div className="flex flex-row justify-between w-full">
            <div className="flex flex-col justify-start">
              <p className="text-sm font-semibold">Flight Segment Cancelled</p>

              <p className="text-sm font-light">{`${changedSegmentNotification.change.seaman.firstName} ${changedSegmentNotification.change.seaman.lastName}`}</p>

              <div className="space-y-2 mt-2">{renderFlightSegmentInfo}</div>

              <p className="text-sm mt-2">{relativeCreatedAt}</p>
            </div>
            {isHovered && (
              <DismissNotificationButton
                dismissHandler={dismissHandler}
                isDismissing={isDismissing}
              />
            )}
          </div>
        </div>
      </Link>
    </div>
  )
}
