import Alert from '~components/ui/Alert/Alert'
import Button from '~components/ui/Button/Button'

interface Props {
  errorMessage: string | null
  onCancel: () => void
}

export default function RestoreCrewChangeModalErrorState({ errorMessage, onCancel }: Props) {
  return (
    <>
      <div className="flex flex-col mb-6 px-6">
        <Alert variant="danger" title={errorMessage} />
      </div>
      <hr />
      <div className="flex justify-between py-4 px-6">
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </>
  )
}
