import { useContext, useMemo, useState } from 'react'
import { Role } from 'types'
import Alert from '~components/ui/Alert/Alert'
import Button from '~components/ui/Button/Button'
import FormGroup from '~components/ui/Form/FormGroup'
import FormSelect from '~components/ui/Form/FormSelect'
import Spinner from '~components/ui/Icon/Spinner'
import Modal from '~components/ui/Modal/Modal'
import { UserContext } from '~context/UserContext'
import {
  useLoadTenantCrewOperatorsQuery,
  useLoadTenantCrewSupervisorsQuery,
} from '../../api/tenants-gql.generated'
import useUpdateCrewOperatorHook from './hooks/useUpdateCrewOperatorHook'
import useUpdateCrewSupervisorHook from './hooks/useUpdateCrewSupervisorHook'
import { CrewChangeSummary } from './types'

interface Props {
  crewChange: CrewChangeSummary
  onClose: () => void
}

interface FormProps {
  crewChange: CrewChangeSummary
  assigneeId: string
  setAssigneeId: (id: string) => void
}

function CrewOperatorForm({ crewChange, assigneeId, setAssigneeId }: FormProps) {
  const { data } = useLoadTenantCrewOperatorsQuery()
  const crewOperators = useMemo(() => data?.currentTenant?.crewOperators || [], [data])

  const eligibleCrewOperators = useMemo(() => {
    const activeCrewOperatorId =
      crewChange.activeCrewOperatorType === 'Primary'
        ? crewChange.primaryCrewOperatorId
        : crewChange.backupCrewOperatorId

    return crewOperators.filter((crewOperator) => crewOperator.id !== activeCrewOperatorId)
  }, [crewChange, crewOperators])

  return (
    <FormSelect
      id="crew-form"
      value={assigneeId}
      onChange={(v) => setAssigneeId(v)}
      options={eligibleCrewOperators.map(({ id, firstName, lastName }) => ({
        value: id,
        label: `${firstName} ${lastName}`,
      }))}
      placeholder="Fleet operator"
    />
  )
}

function CrewSupervisorForm({ crewChange, assigneeId, setAssigneeId }: FormProps) {
  const { data } = useLoadTenantCrewSupervisorsQuery()
  const crewSupervisors = useMemo(() => data?.currentTenant?.crewSupervisors || [], [data])

  const eligibleCrewSupervisors = useMemo(() => {
    const activeCrewSupervisorId =
      crewChange.activeCrewSupervisorType === 'Primary'
        ? crewChange.primaryCrewSupervisorId
        : crewChange.backupCrewSupervisorId

    return crewSupervisors.filter((crewSupervisor) => crewSupervisor.id !== activeCrewSupervisorId)
  }, [crewChange, crewSupervisors])

  return (
    <FormSelect
      id="crew-form"
      value={assigneeId}
      onChange={(v) => setAssigneeId(v)}
      options={eligibleCrewSupervisors.map(({ id, firstName, lastName }) => ({
        value: id,
        label: `${firstName} ${lastName}`,
      }))}
      placeholder="Crew Supervisor"
    />
  )
}

export default function CrewChangeTransferVesselModal({ crewChange, onClose }: Props) {
  const { user } = useContext(UserContext)
  const isCrewSupervisor = useMemo(() => user.role === Role.CrewSupervisor, [user])
  const operatorCopy = useMemo(
    () => (user.role === Role.CrewSupervisor ? 'Crew Supervisor' : 'Fleet Operator'),
    [user]
  )
  const [assigneeId, setAssigneeId] = useState<string>('')

  const { updateActiveCrewSupervisor, csiLoading, csiError, csiResult } =
    useUpdateCrewSupervisorHook(crewChange, assigneeId)
  const { updateActiveCrewOperator, coLoading, coError, coResult } = useUpdateCrewOperatorHook(
    crewChange,
    assigneeId
  )

  const error = useMemo(() => csiError || coError, [csiError, coError])
  const loading = useMemo(() => csiLoading || coLoading, [csiLoading, coLoading])
  const updateResult = useMemo(() => csiResult || coResult, [csiResult, coResult])

  return (
    <Modal
      open
      onClose={onClose}
      title={
        <>
          Transfer <span className="underline">{crewChange.vesselName}</span> to another{' '}
          {operatorCopy}
        </>
      }
      inset
    >
      {!loading && !updateResult && (
        <>
          <div className="flex flex-col space-y-5 items-center mb-2 text-base text-gray-800">
            <span>
              Transferring will reassign all{' '}
              <span className="underline">{crewChange.vesselName}</span> crew changes to another{' '}
              {operatorCopy}.
            </span>
            <span className="font-semibold">
              Once transferred, you will need to contact the assigned {operatorCopy} to have{' '}
              {crewChange.vesselName} transferred back.
            </span>
          </div>
          <div className="mt-4">
            <FormGroup htmlFor="crew-form" label={`Select a ${operatorCopy}`}>
              {isCrewSupervisor ? (
                <CrewSupervisorForm
                  crewChange={crewChange}
                  assigneeId={assigneeId}
                  setAssigneeId={setAssigneeId}
                />
              ) : (
                <CrewOperatorForm
                  crewChange={crewChange}
                  assigneeId={assigneeId}
                  setAssigneeId={setAssigneeId}
                />
              )}
            </FormGroup>
          </div>
          <div className="flex flex-row-reverse space-x-3 space-x-reverse mt-12">
            <Button
              type="submit"
              onClick={isCrewSupervisor ? updateActiveCrewSupervisor : updateActiveCrewOperator}
              disabled={assigneeId === ''}
            >
              Transfer
            </Button>
            <Button variant="outline" type="reset" onClick={onClose}>
              Back
            </Button>
          </div>
        </>
      )}
      {updateResult && !loading && (
        <Alert
          title={`Vessel ${crewChange.vesselName} has been successfully transferred.`}
          variant="success"
        />
      )}
      {loading && (
        <Alert
          title={`Vessel ${crewChange.vesselName} is being transferred.`}
          variant="info"
          icon={Spinner}
        />
      )}
      {error && (
        <Alert title="Something went wrong" variant="danger">
          If the problem persists, please contact support.
        </Alert>
      )}
    </Modal>
  )
}
