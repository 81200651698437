/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import { AirlineFieldsFragmentDoc } from './airlines-gql.generated'
export type CompleteFastFlightFragment = {
  id: string
  travelOfficeConfigurationId: string
  flightHash: string
  majorityCarrier: string | null
  validatingCarrier: string | null
  tenantId: string
  amadeusSessionId: string
  travelOfficeConfiguration: { agencyName: string }
  segments: Array<{
    id: string
    flightNumber: number
    index: number
    departure: {
      timezoneOlson: string | null
      time: string
      name: string | null
      iata: string
      countryAlpha2: string | null
      city: string | null
      date: string
      terminal: string | null
    }
    arrival: {
      timezoneOlson: string | null
      time: string
      name: string | null
      iata: string
      countryAlpha2: string | null
      city: string | null
      date: string
      terminal: string | null
    }
    marketingCarrier: {
      id: string
      createdAt: string
      updatedAt: string
      iata: string
      name: string
      logo: string | null
    } | null
    operatingCarrier: {
      id: string
      createdAt: string
      updatedAt: string
      iata: string
      name: string
      logo: string | null
    } | null
  }>
  offer: {
    id: string
    amount: number
    tax: number | null
    passengerType: Types.PassengerType | null
    currency: string
    amadeusSessionId: string
    segmentFares: Array<{
      availableSeats: number | null
      fareTypes: Array<Types.FareType>
      fareBasisCode: string
      bookingClass: string | null
      flightSegmentId: string
      baggageDetails: {
        freeBagAllowance: number | null
        freeBagAllowanceType: Types.FreeBagAllowanceType | null
      }
    }>
    rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
    minirules: {
      beforeDeparture: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
      beforeDepartureNoShow: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
      afterDeparture: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
      afterDepartureNoShow: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
    } | null
  }
}

export type FastFlightSegmentFragment = {
  id: string
  flightNumber: number
  index: number
  departure: {
    timezoneOlson: string | null
    time: string
    name: string | null
    iata: string
    countryAlpha2: string | null
    city: string | null
    date: string
    terminal: string | null
  }
  arrival: {
    timezoneOlson: string | null
    time: string
    name: string | null
    iata: string
    countryAlpha2: string | null
    city: string | null
    date: string
    terminal: string | null
  }
  marketingCarrier: {
    id: string
    createdAt: string
    updatedAt: string
    iata: string
    name: string
    logo: string | null
  } | null
  operatingCarrier: {
    id: string
    createdAt: string
    updatedAt: string
    iata: string
    name: string
    logo: string | null
  } | null
}

export type CompleteFastFlightOfferFragment = {
  id: string
  amount: number
  tax: number | null
  passengerType: Types.PassengerType | null
  currency: string
  amadeusSessionId: string
  segmentFares: Array<{
    availableSeats: number | null
    fareTypes: Array<Types.FareType>
    fareBasisCode: string
    bookingClass: string | null
    flightSegmentId: string
    baggageDetails: {
      freeBagAllowance: number | null
      freeBagAllowanceType: Types.FreeBagAllowanceType | null
    }
  }>
  rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
  minirules: {
    beforeDeparture: {
      changeAllowed: boolean | null
      changeMaxPenaltyAmount: number | null
      refundAllowed: boolean | null
      refundMaxPenaltyAmount: number | null
      revalidationAllowed: boolean | null
      revalidationMaxPenaltyAmount: number | null
      isoTicketValidityDate: string | null
      taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
    } | null
    beforeDepartureNoShow: {
      changeAllowed: boolean | null
      changeMaxPenaltyAmount: number | null
      refundAllowed: boolean | null
      refundMaxPenaltyAmount: number | null
      revalidationAllowed: boolean | null
      revalidationMaxPenaltyAmount: number | null
      isoTicketValidityDate: string | null
      taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
    } | null
    afterDeparture: {
      changeAllowed: boolean | null
      changeMaxPenaltyAmount: number | null
      refundAllowed: boolean | null
      refundMaxPenaltyAmount: number | null
      revalidationAllowed: boolean | null
      revalidationMaxPenaltyAmount: number | null
      isoTicketValidityDate: string | null
      taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
    } | null
    afterDepartureNoShow: {
      changeAllowed: boolean | null
      changeMaxPenaltyAmount: number | null
      refundAllowed: boolean | null
      refundMaxPenaltyAmount: number | null
      revalidationAllowed: boolean | null
      revalidationMaxPenaltyAmount: number | null
      isoTicketValidityDate: string | null
      taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
    } | null
  } | null
}

export type FastFlightSegmentFareFieldsFragment = {
  availableSeats: number | null
  fareTypes: Array<Types.FareType>
  fareBasisCode: string
  bookingClass: string | null
  flightSegmentId: string
  baggageDetails: {
    freeBagAllowance: number | null
    freeBagAllowanceType: Types.FreeBagAllowanceType | null
  }
}

export type FastFlightOfferFieldsFragment = {
  id: string
  amount: number
  tax: number | null
  passengerType: Types.PassengerType | null
  currency: string
  amadeusSessionId: string
  rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
  minirules: {
    beforeDeparture: {
      changeAllowed: boolean | null
      changeMaxPenaltyAmount: number | null
      refundAllowed: boolean | null
      refundMaxPenaltyAmount: number | null
      revalidationAllowed: boolean | null
      revalidationMaxPenaltyAmount: number | null
      isoTicketValidityDate: string | null
      taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
    } | null
    beforeDepartureNoShow: {
      changeAllowed: boolean | null
      changeMaxPenaltyAmount: number | null
      refundAllowed: boolean | null
      refundMaxPenaltyAmount: number | null
      revalidationAllowed: boolean | null
      revalidationMaxPenaltyAmount: number | null
      isoTicketValidityDate: string | null
      taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
    } | null
    afterDeparture: {
      changeAllowed: boolean | null
      changeMaxPenaltyAmount: number | null
      refundAllowed: boolean | null
      refundMaxPenaltyAmount: number | null
      revalidationAllowed: boolean | null
      revalidationMaxPenaltyAmount: number | null
      isoTicketValidityDate: string | null
      taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
    } | null
    afterDepartureNoShow: {
      changeAllowed: boolean | null
      changeMaxPenaltyAmount: number | null
      refundAllowed: boolean | null
      refundMaxPenaltyAmount: number | null
      revalidationAllowed: boolean | null
      revalidationMaxPenaltyAmount: number | null
      isoTicketValidityDate: string | null
      taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
    } | null
  } | null
}

export type CompleteFlightFragment = {
  id: string
  updatedAt: string
  createdAt: string
  flightHash: string
  majorityCarrier: string | null
  travelOfficeConfigurationId: string
  validatingCarrier: string | null
  segments: Array<{
    id: string
    flightNumber: number
    index: number
    departure: {
      timezoneOlson: string | null
      time: string
      name: string | null
      iata: string
      countryAlpha2: string | null
      city: string | null
      date: string
      terminal: string | null
    }
    arrival: {
      timezoneOlson: string | null
      time: string
      name: string | null
      iata: string
      countryAlpha2: string | null
      city: string | null
      date: string
      terminal: string | null
    }
    marketingCarrier: {
      id: string
      createdAt: string
      updatedAt: string
      iata: string
      name: string
      logo: string | null
    } | null
    operatingCarrier: {
      id: string
      createdAt: string
      updatedAt: string
      iata: string
      name: string
      logo: string | null
    } | null
  }>
  offers: Array<{
    id: string
    amount: number
    tax: number | null
    passengerType: Types.PassengerType | null
    currency: string
    createdAt: string
    updatedAt: string
    flightId: string
    amadeusSessionId: string | null
    segmentFares: Array<{
      availableSeats: number | null
      fareTypes: Array<Types.FareType>
      fareBasisCode: string
      flightSegmentId: string
      bookingClass: string | null
      baggageDetails: {
        freeBagAllowance: number | null
        freeBagAllowanceType: Types.FreeBagAllowanceType | null
      }
    }>
    rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
    minirules: {
      beforeDeparture: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
      beforeDepartureNoShow: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
      afterDeparture: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
      afterDepartureNoShow: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
    } | null
    taxDetails: Array<{
      amount: number
      code: string | null
      isNonRefundable: boolean | null
    }> | null
  }>
  travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
}

export type FlightWithSegmentsFragment = {
  id: string
  updatedAt: string
  createdAt: string
  flightHash: string
  majorityCarrier: string | null
  travelOfficeConfigurationId: string
  validatingCarrier: string | null
  segments: Array<{
    id: string
    flightNumber: number
    index: number
    departure: {
      timezoneOlson: string | null
      time: string
      name: string | null
      iata: string
      countryAlpha2: string | null
      city: string | null
      date: string
      terminal: string | null
    }
    arrival: {
      timezoneOlson: string | null
      time: string
      name: string | null
      iata: string
      countryAlpha2: string | null
      city: string | null
      date: string
      terminal: string | null
    }
    marketingCarrier: {
      id: string
      createdAt: string
      updatedAt: string
      iata: string
      name: string
      logo: string | null
    } | null
    operatingCarrier: {
      id: string
      createdAt: string
      updatedAt: string
      iata: string
      name: string
      logo: string | null
    } | null
  }>
  travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
}

export type FlightFieldsFragment = {
  id: string
  updatedAt: string
  createdAt: string
  flightHash: string
  majorityCarrier: string | null
  travelOfficeConfigurationId: string
  validatingCarrier: string | null
  travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
}

export type FlightSegmentFieldsFragment = {
  id: string
  flightNumber: number
  index: number
  departure: {
    timezoneOlson: string | null
    time: string
    name: string | null
    iata: string
    countryAlpha2: string | null
    city: string | null
    date: string
    terminal: string | null
  }
  arrival: {
    timezoneOlson: string | null
    time: string
    name: string | null
    iata: string
    countryAlpha2: string | null
    city: string | null
    date: string
    terminal: string | null
  }
  marketingCarrier: {
    id: string
    createdAt: string
    updatedAt: string
    iata: string
    name: string
    logo: string | null
  } | null
  operatingCarrier: {
    id: string
    createdAt: string
    updatedAt: string
    iata: string
    name: string
    logo: string | null
  } | null
}

export type CompleteOfferFragment = {
  id: string
  amount: number
  tax: number | null
  passengerType: Types.PassengerType | null
  currency: string
  createdAt: string
  updatedAt: string
  flightId: string
  amadeusSessionId: string | null
  segmentFares: Array<{
    availableSeats: number | null
    fareTypes: Array<Types.FareType>
    fareBasisCode: string
    flightSegmentId: string
    bookingClass: string | null
    baggageDetails: {
      freeBagAllowance: number | null
      freeBagAllowanceType: Types.FreeBagAllowanceType | null
    }
  }>
  rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
  minirules: {
    beforeDeparture: {
      changeAllowed: boolean | null
      changeMaxPenaltyAmount: number | null
      refundAllowed: boolean | null
      refundMaxPenaltyAmount: number | null
      revalidationAllowed: boolean | null
      revalidationMaxPenaltyAmount: number | null
      isoTicketValidityDate: string | null
      taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
    } | null
    beforeDepartureNoShow: {
      changeAllowed: boolean | null
      changeMaxPenaltyAmount: number | null
      refundAllowed: boolean | null
      refundMaxPenaltyAmount: number | null
      revalidationAllowed: boolean | null
      revalidationMaxPenaltyAmount: number | null
      isoTicketValidityDate: string | null
      taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
    } | null
    afterDeparture: {
      changeAllowed: boolean | null
      changeMaxPenaltyAmount: number | null
      refundAllowed: boolean | null
      refundMaxPenaltyAmount: number | null
      revalidationAllowed: boolean | null
      revalidationMaxPenaltyAmount: number | null
      isoTicketValidityDate: string | null
      taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
    } | null
    afterDepartureNoShow: {
      changeAllowed: boolean | null
      changeMaxPenaltyAmount: number | null
      refundAllowed: boolean | null
      refundMaxPenaltyAmount: number | null
      revalidationAllowed: boolean | null
      revalidationMaxPenaltyAmount: number | null
      isoTicketValidityDate: string | null
      taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
    } | null
  } | null
  taxDetails: Array<{ amount: number; code: string | null; isNonRefundable: boolean | null }> | null
}

export type FlightSegmentFareFieldsFragment = {
  availableSeats: number | null
  fareTypes: Array<Types.FareType>
  fareBasisCode: string
  flightSegmentId: string
  bookingClass: string | null
  baggageDetails: {
    freeBagAllowance: number | null
    freeBagAllowanceType: Types.FreeBagAllowanceType | null
  }
}

export type FlightOfferFieldsFragment = {
  id: string
  amount: number
  tax: number | null
  passengerType: Types.PassengerType | null
  currency: string
  createdAt: string
  updatedAt: string
  flightId: string
  amadeusSessionId: string | null
  rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
  minirules: {
    beforeDeparture: {
      changeAllowed: boolean | null
      changeMaxPenaltyAmount: number | null
      refundAllowed: boolean | null
      refundMaxPenaltyAmount: number | null
      revalidationAllowed: boolean | null
      revalidationMaxPenaltyAmount: number | null
      isoTicketValidityDate: string | null
      taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
    } | null
    beforeDepartureNoShow: {
      changeAllowed: boolean | null
      changeMaxPenaltyAmount: number | null
      refundAllowed: boolean | null
      refundMaxPenaltyAmount: number | null
      revalidationAllowed: boolean | null
      revalidationMaxPenaltyAmount: number | null
      isoTicketValidityDate: string | null
      taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
    } | null
    afterDeparture: {
      changeAllowed: boolean | null
      changeMaxPenaltyAmount: number | null
      refundAllowed: boolean | null
      refundMaxPenaltyAmount: number | null
      revalidationAllowed: boolean | null
      revalidationMaxPenaltyAmount: number | null
      isoTicketValidityDate: string | null
      taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
    } | null
    afterDepartureNoShow: {
      changeAllowed: boolean | null
      changeMaxPenaltyAmount: number | null
      refundAllowed: boolean | null
      refundMaxPenaltyAmount: number | null
      revalidationAllowed: boolean | null
      revalidationMaxPenaltyAmount: number | null
      isoTicketValidityDate: string | null
      taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
    } | null
  } | null
  taxDetails: Array<{ amount: number; code: string | null; isNonRefundable: boolean | null }> | null
}

export type MinirulesFieldsFragment = {
  beforeDeparture: {
    changeAllowed: boolean | null
    changeMaxPenaltyAmount: number | null
    refundAllowed: boolean | null
    refundMaxPenaltyAmount: number | null
    revalidationAllowed: boolean | null
    revalidationMaxPenaltyAmount: number | null
    isoTicketValidityDate: string | null
    taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
  } | null
  beforeDepartureNoShow: {
    changeAllowed: boolean | null
    changeMaxPenaltyAmount: number | null
    refundAllowed: boolean | null
    refundMaxPenaltyAmount: number | null
    revalidationAllowed: boolean | null
    revalidationMaxPenaltyAmount: number | null
    isoTicketValidityDate: string | null
    taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
  } | null
  afterDeparture: {
    changeAllowed: boolean | null
    changeMaxPenaltyAmount: number | null
    refundAllowed: boolean | null
    refundMaxPenaltyAmount: number | null
    revalidationAllowed: boolean | null
    revalidationMaxPenaltyAmount: number | null
    isoTicketValidityDate: string | null
    taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
  } | null
  afterDepartureNoShow: {
    changeAllowed: boolean | null
    changeMaxPenaltyAmount: number | null
    refundAllowed: boolean | null
    refundMaxPenaltyAmount: number | null
    revalidationAllowed: boolean | null
    revalidationMaxPenaltyAmount: number | null
    isoTicketValidityDate: string | null
    taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
  } | null
}

export const FastFlightSegmentFragmentDoc = gql`
  fragment FastFlightSegment on FastFlightSegment {
    id
    flightNumber
    index
    departure {
      timezoneOlson
      time
      name
      iata
      countryAlpha2
      city
      date
      terminal
    }
    arrival {
      timezoneOlson
      time
      name
      iata
      countryAlpha2
      city
      date
      terminal
    }
    marketingCarrier {
      ...AirlineFields
    }
    operatingCarrier {
      ...AirlineFields
    }
  }
  ${AirlineFieldsFragmentDoc}
`
export const MinirulesFieldsFragmentDoc = gql`
  fragment MinirulesFields on FlightFareMinirules {
    beforeDeparture {
      changeAllowed
      changeMaxPenaltyAmount
      refundAllowed
      refundMaxPenaltyAmount
      revalidationAllowed
      revalidationMaxPenaltyAmount
      taxInformation {
        areYqYrTaxesNonRefundable
      }
      isoTicketValidityDate
    }
    beforeDepartureNoShow {
      changeAllowed
      changeMaxPenaltyAmount
      refundAllowed
      refundMaxPenaltyAmount
      revalidationAllowed
      revalidationMaxPenaltyAmount
      taxInformation {
        areYqYrTaxesNonRefundable
      }
      isoTicketValidityDate
    }
    afterDeparture {
      changeAllowed
      changeMaxPenaltyAmount
      refundAllowed
      refundMaxPenaltyAmount
      revalidationAllowed
      revalidationMaxPenaltyAmount
      taxInformation {
        areYqYrTaxesNonRefundable
      }
      isoTicketValidityDate
    }
    afterDepartureNoShow {
      changeAllowed
      changeMaxPenaltyAmount
      refundAllowed
      refundMaxPenaltyAmount
      revalidationAllowed
      revalidationMaxPenaltyAmount
      taxInformation {
        areYqYrTaxesNonRefundable
      }
      isoTicketValidityDate
    }
  }
`
export const FastFlightOfferFieldsFragmentDoc = gql`
  fragment FastFlightOfferFields on FastFlightOffer {
    id
    amount
    tax
    passengerType
    currency
    amadeusSessionId
    rules {
      rules
      id
      ruleType
    }
    minirules {
      ...MinirulesFields
    }
  }
  ${MinirulesFieldsFragmentDoc}
`
export const FastFlightSegmentFareFieldsFragmentDoc = gql`
  fragment FastFlightSegmentFareFields on FastFlightSegmentFare {
    availableSeats
    fareTypes
    fareBasisCode
    bookingClass
    flightSegmentId
    baggageDetails {
      freeBagAllowance
      freeBagAllowanceType
    }
  }
`
export const CompleteFastFlightOfferFragmentDoc = gql`
  fragment CompleteFastFlightOffer on FastFlightOffer {
    ...FastFlightOfferFields
    segmentFares {
      ...FastFlightSegmentFareFields
    }
  }
  ${FastFlightOfferFieldsFragmentDoc}
  ${FastFlightSegmentFareFieldsFragmentDoc}
`
export const CompleteFastFlightFragmentDoc = gql`
  fragment CompleteFastFlight on FastFlight {
    id
    travelOfficeConfigurationId
    flightHash
    majorityCarrier
    validatingCarrier
    tenantId
    amadeusSessionId
    travelOfficeConfiguration {
      agencyName
    }
    segments {
      ...FastFlightSegment
    }
    offer {
      ...CompleteFastFlightOffer
    }
  }
  ${FastFlightSegmentFragmentDoc}
  ${CompleteFastFlightOfferFragmentDoc}
`
export const FlightFieldsFragmentDoc = gql`
  fragment FlightFields on Flight {
    id
    updatedAt
    createdAt
    flightHash
    majorityCarrier
    travelOfficeConfigurationId
    validatingCarrier
    travelOfficeConfiguration {
      agencyName
      phonePrimary
    }
  }
`
export const FlightSegmentFieldsFragmentDoc = gql`
  fragment FlightSegmentFields on FlightSegment {
    id
    flightNumber
    index
    departure {
      timezoneOlson
      time
      name
      iata
      countryAlpha2
      city
      date
      terminal
    }
    arrival {
      timezoneOlson
      time
      name
      iata
      countryAlpha2
      city
      date
      terminal
    }
    marketingCarrier {
      ...AirlineFields
    }
    operatingCarrier {
      ...AirlineFields
    }
  }
  ${AirlineFieldsFragmentDoc}
`
export const FlightOfferFieldsFragmentDoc = gql`
  fragment FlightOfferFields on FlightOffer {
    id
    amount
    tax
    passengerType
    currency
    createdAt
    updatedAt
    flightId
    amadeusSessionId
    rules {
      rules
      id
      ruleType
    }
    minirules {
      ...MinirulesFields
    }
    taxDetails {
      amount
      code
      isNonRefundable
    }
  }
  ${MinirulesFieldsFragmentDoc}
`
export const FlightSegmentFareFieldsFragmentDoc = gql`
  fragment FlightSegmentFareFields on FlightSegmentFare {
    availableSeats
    fareTypes
    fareBasisCode
    flightSegmentId
    bookingClass
    baggageDetails {
      freeBagAllowance
      freeBagAllowanceType
    }
  }
`
export const CompleteOfferFragmentDoc = gql`
  fragment CompleteOffer on FlightOffer {
    ...FlightOfferFields
    segmentFares {
      ...FlightSegmentFareFields
    }
  }
  ${FlightOfferFieldsFragmentDoc}
  ${FlightSegmentFareFieldsFragmentDoc}
`
export const CompleteFlightFragmentDoc = gql`
  fragment CompleteFlight on Flight {
    ...FlightFields
    segments {
      ...FlightSegmentFields
    }
    offers {
      ...CompleteOffer
    }
  }
  ${FlightFieldsFragmentDoc}
  ${FlightSegmentFieldsFragmentDoc}
  ${CompleteOfferFragmentDoc}
`
export const FlightWithSegmentsFragmentDoc = gql`
  fragment FlightWithSegments on Flight {
    ...FlightFields
    segments {
      ...FlightSegmentFields
    }
  }
  ${FlightFieldsFragmentDoc}
  ${FlightSegmentFieldsFragmentDoc}
`
