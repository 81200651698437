import { useMixpanel } from '~context/MixpanelContext'

const baseTrackableEvents = [
  'Viewed crew change details',
  'Booking deferred to agent',
  'Ticketing deferred to agent',
  'Clicked on download all documents',
  'Downloaded all documents',
  'Clicked on upload documents',
  'Uploaded documents',
  'Clicked on edit port in Hanseatic',
  'Viewed seafarers details',
  'Clicked edit seafarers on hanseatic',
  'Shared flight',
  'Started crew change',
  'Updated vessel schedule',
  'Clicked on share flights',
  'Clicked on issue tickets',
  'Clicked on reserve flights',
  'Aborted flight booking cancellation',
  'Clicked finish flight booking cancellation',
  'Clicked on cancel and refund',
  'Cancelled flight bookings',
  'Calculated flight bookings refunds',
  'Confirmed flight bookings refunds',
  'Deferred flight booking refund and cancellation',
  'Clicked on search flight',
  'Clicked on change flight',
  'Switched and reserved flight booking',
  'Switched and ticketed flight booking',
  'Exported booking history',
  'Start crew change clicked',
  'Reserved flights partial success',
  'Ticketed flights partial success',
  'Reserved flights',
  'Ticketed flights',
  'Included additional seafarers',
  'Selected flight',
  'Searched flights',
  'Cancelled search',
  'View CrewChange click',
  'Transferred vessel',
  'Clicked on view travel restrictions',
  'Viewed overview screen',
  'Crew change status set manually',
  'Searched second flight',
] as const

const mixpanelEvents = [
  ...baseTrackableEvents,
  'Enabled past seafarers',
  'Search deferred to agent',
  'Flight monitored',
  'Flight unmonitored',
  'Cleared flight search filters',
  'Downloaded document',
  'Clicked on Port Planning tab',
  'Viewed port planning',
  'Calculated port flights and fees',
  'Viewed on-signers (Port Planning)',
  'Viewed off-signers (Port Planning)',
  'Viewed port agencies',
  'Changed port agency',
  'Clicked on Add Flight',
  'Clicked on fare rules',
  'Viewed fare rules',
  'Clicked on update availability',
  'Updated availability',
  'Clicked on Deferrals Overview',
  'Selected Deferral Type',
  'Viewed Deferrals Overview',
  'Clicked on Recommend Flights',
  'Recommended flights',
  'Clicked on Add Split Ticket',
  'Clicked on Cancel Split Ticket',
  'Clicked on Select Split Tickets',
  'Clicked on Book Split Tickets',
  'Clicked on Ticket Split Tickets',
  'Clicked on Change Split Ticket Flight',
  'Selected split tickets',
  'Reserved split tickets',
  'Ticketed split tickets',
  'Reserved split tickets partial success',
  'Ticketed split tickets partial success',
  'Priced offer with alternative booking class',
  'Switched to new UI for flight card',
  'Viewed notifications center',
  'Viewed notification tab',
  'Dismissed notification',
  'Clicked on a notification',
  'Cheaper offer expired',
  'Restored notification',
] as const

const intercomEvents = [
  ...baseTrackableEvents,
  'Time on crew change screen',
  'Time on overview screen',
] as const

const trackableEvents = [...new Set([...mixpanelEvents, ...intercomEvents])] as const

export type MixpanelEvent = (typeof mixpanelEvents)[number]
export type IntercomEvent = (typeof intercomEvents)[number]
export type TrackableEvent = (typeof trackableEvents)[number]

export interface TrackEventProps {
  event: TrackableEvent
  metadata?: {
    intercomMetadata?: object
    mixpanelProperties?: Record<string, unknown>
  }
}

function isMixpanelEvent(event: TrackableEvent): event is MixpanelEvent {
  return mixpanelEvents.includes(event as MixpanelEvent)
}

export function useTrackEvent() {
  const { trackMixpanelEvent } = useMixpanel()

  const trackEvent = ({ event, metadata }: TrackEventProps): void => {
    if (isMixpanelEvent(event)) {
      trackMixpanelEvent(event, metadata?.mixpanelProperties)
    }
  }

  return trackEvent
}
