import clsx from 'clsx'
import { ReactNode, useMemo } from 'react'

export type ToastType = 'success' | 'error' | 'warning' | 'info'

interface Props {
  className?: string
  children: ReactNode
  type?: ToastType
}

/**
 * Static toast component, to be used via useToast() hook
 */
export default function Toast({ children, className, type = 'info' }: Props) {
  const typeClasses = useMemo(() => {
    switch (type) {
      case 'success':
        return 'border-green-500'
      case 'error':
        return 'border-red-500'
      case 'warning':
        return 'border-yellow-500'
      case 'info':
        return 'border-brand'
      default:
        return 'border-brand'
    }
  }, [type])

  return (
    <div
      className={clsx(
        `bg-white border-2 fixed bottom-16 z-20 mx-auto inset-x-0 max-w-fit p-2 rounded-md`,
        { className: `${className}` },
        typeClasses
      )}
    >
      {children}
    </div>
  )
}
