export enum Action {
  SearchFlight,
  BookFlight,
  RebookFlight,
  IssueTicket,
  DeactivateBooking,
  ToggleBookingMonitoring,
  RepriceBooking,
  RequestConfirmation,
  UpdateCrewChangeStatus,
  CancelFlight,
  DeferFlightToTravelAgent,
  CancelFlightDeferral,
  ShareFlightDetails,
  CreateBookingFromPnr,
  CreateBookingFromScratch,
  EditBookingFromScratch,
  DeletePNR,
  UpdatePNR,
  EditPNR,
  ModifyCOVIDRestrictions,
  ModifyVisaDetails,
  InvitePortAgent,
  InviteTravelAgent,
  EditSeafarersInHanseatic,
  StartCrewChange,
  NotifyFlightsBooked,
  FetchETicket,
  RefundFlight,
  ValidatePort,
  CancelPNR,
  LoadPNRInfo,
  CancelTicket,
  AssignCrewOperator,
  FetchBookingHistory,
  NotifyLastTicketingDateNearby,
  ResetCrewChange,
  ResetPortPlanning,
  ParseFlightOffers,
  DeleteTST,
  CreateCrewChange,
  CancelCrewChange,
  RestoreCrewChange,
  UpdateCrewChange,
  DetectFlightItineraryChanges,
  DataSync,
}
