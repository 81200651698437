import { useCallback } from 'react'
import { useCancelCrewChangeMutation } from '~api/crew-changes-gql.generated'
import { CrewChangeSummary } from '~components/CrewChanges/types'
import Button from '~components/ui/Button/Button'
import Modal from '~components/ui/Modal/Modal'

interface Props {
  crewChange: CrewChangeSummary
  isOpen: boolean
  onCancel: () => void
}

export default function CancelCrewChangeModal({ onCancel, isOpen, crewChange }: Props) {
  const [cancelCrewChange] = useCancelCrewChangeMutation()

  const handleCancellation = useCallback(
    async (bucketId: string) => {
      await cancelCrewChange({
        variables: {
          bucketId,
        },
        onCompleted: () => window.location.reload(),
      })
    },
    [cancelCrewChange]
  )

  return (
    <Modal title="Cancel crew change?" open={isOpen} onClose={onCancel} draggable>
      <div className="flex flex-col space-y-5 py-4 px-6 mb-2 text-base leading-5">
        <span>You will now cancel the following crew change:</span>
        <span>
          Vessel: <strong>{crewChange.vesselName}</strong>
        </span>
        <span>
          Port: <strong>{crewChange.portName}</strong>
        </span>
      </div>
      <div className="py-4 px-6 flex flex-row items-center justify-between">
        <Button variant="outline" onClick={onCancel}>
          Back
        </Button>
        <Button variant="danger" onClick={() => handleCancellation(crewChange.bucketId)}>
          Cancel crew change
        </Button>
      </div>
    </Modal>
  )
}
