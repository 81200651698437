import { FetchResult } from '@apollo/client'
import { isNil } from 'lodash'
import { DateTime } from 'luxon'
import React, { Dispatch, ReactNode, SetStateAction, useCallback, useMemo, useState } from 'react'
import { globalErrorHandler } from 'utils/errorHandling'
import { v4 as uuidv4 } from 'uuid'
import {
  CreateBookingFromScratchMutation,
  EditBookingFromScratchMutation,
  useCreateBookingFromScratchMutation,
  useEditBookingFromScratchMutation,
} from '~api/flight-documents-gql.generated'
import {
  Airport,
  CreateBookingFromScratchInput,
  Currency,
  EditBookingFromScratchInput,
  FareType,
  FlightBookingStatus,
  FreeBagAllowanceType,
  ParsedRefundInfo,
  ParsedSegmentInput,
} from '~api/types.generated'
import { CrewChangeQuery } from '~context/CrewChangeContext/types'
import usePnrValidation from '~hooks/usePnrValidation'

export interface FlightOfferDetails {
  id: string
  rawData: string
  bookingFromScratch: CreateBookingFromScratchInput | EditBookingFromScratchInput
  fields: Record<
    keyof CreateBookingFromScratchInput,
    Record<'required' | 'valid' | 'isVisible', boolean>
  >
  isLoading?: boolean
}

export enum ParsedFlightOfferModalState {
  Adding,
  Updating,
  Editing,
  Parsed,
  Errored,
}

export interface ParsedFlightOfferBaggage {
  type: FreeBagAllowanceType
  amount: number
}

export interface ParsedFlightOfferSegment extends ParsedSegmentInput {
  departure: {
    dateTime: string
    iata: string
    airport: Airport | undefined
  }
  arrival: {
    dateTime: string
    iata: string
    airport: Airport | undefined
  }
  carrier: string
  flightNumber: number
}

interface TravelAgentParsedFlightContext {
  modalState: ParsedFlightOfferModalState
  flightOffers: FlightOfferDetails[]
  activeFlightOffer: FlightOfferDetails | undefined
  errors: Record<FlightOfferDetails['id'], number>
  additionalSeamen: CrewChangeQuery.CrewChangeSeaman[]
  setAdditionalSeamen: Dispatch<SetStateAction<CrewChangeQuery.CrewChangeSeaman[]>>
  setActiveFlightOffer: Dispatch<SetStateAction<FlightOfferDetails | undefined>>
  setModalState: Dispatch<SetStateAction<ParsedFlightOfferModalState>>
  updateFlightOffer: (flightOffer: FlightOfferDetails) => FlightOfferDetails | undefined
  updateFlightOfferFields: (
    flightOffer: FlightOfferDetails,
    fields: FlightOfferDetails['fields']
  ) => FlightOfferDetails['fields']
  addFlightOffer: () => void
  deleteFlightOffer: (id: string) => void
  handelReset: () => void
  getFlightOffer: (id: string) => FlightOfferDetails | undefined
  handleFlightOffersValidation: (flightOffers: FlightOfferDetails[]) => {
    [key: FlightOfferDetails['id']]: {
      validatedFields: FlightOfferDetails['fields']
      errorCount: number
    }
  }
  updateFlightOffersOnValidation: (flightOffers: FlightOfferDetails[]) => void
  handleCreateBookingFromScratch: (
    flightOffer: FlightOfferDetails,
    bucketId: string
  ) => Promise<FetchResult<CreateBookingFromScratchMutation>[]>
  handleEditBookingFromScratch: (
    flightOffer: FlightOfferDetails
  ) => Promise<FetchResult<EditBookingFromScratchMutation>>
  removeAirports: (segments: ParsedFlightOfferSegment[]) => ParsedSegmentInput[]
}

const initialBookingFromScratch: CreateBookingFromScratchInput = {
  bucketId: '',
  baggagePieces: 0,
  baggageWeight: 0,
  seamanId: '',
  status: FlightBookingStatus.Selected,
  travelOfficeConfigurationId: '',
  validatingCarrier: '',
  fareAmountWithoutCommission: 0,
  fareBasisCode: null,
  fareCurrency: Currency.Usd,
  segments: [],
  refundable: ParsedRefundInfo.NonRefundable,
  fareType: FareType.UniFares,
  ltd: DateTime.now().toISO(),
}

export const defaultFields = {
  fareCurrency: { required: true, valid: false, isVisible: true },
  fareBasisCode: { required: false, valid: true, isVisible: true },
  bucketId: { required: true, valid: false, isVisible: true },
  travelOfficeConfigurationId: { required: true, valid: false, isVisible: true },
  fareAmountWithoutCommission: { required: true, valid: false, isVisible: true },
  baggagePieces: { required: false, valid: false, isVisible: true },
  baggageWeight: { required: false, valid: false, isVisible: true },
  validatingCarrier: { required: true, valid: false, isVisible: true },
  segments: { required: false, valid: false, isVisible: true },
  refundable: { required: false, valid: false, isVisible: true },
  fareType: { required: false, valid: false, isVisible: true },
  ltd: { required: false, valid: false, isVisible: true },
  comment: { required: false, valid: false, isVisible: true },
  pnr: { required: false, valid: false, isVisible: true },
  ticketLine: { required: false, valid: false, isVisible: true },
  isAmadeus: { required: false, valid: true, isVisible: false },
  seamanId: { required: true, valid: false, isVisible: false },
  status: { required: true, valid: false, isVisible: false },
}

const initialFlightOffer = ({
  fields,
}: {
  fields?: Record<string, { required: boolean; valid: boolean }>
}): FlightOfferDetails => {
  const mergedFields = { ...defaultFields, ...fields }

  return {
    rawData: '',
    id: `dev-id-${uuidv4()}`,
    isLoading: false,
    bookingFromScratch: initialBookingFromScratch,
    fields: mergedFields,
  }
}

const initialContext: TravelAgentParsedFlightContext = {
  modalState: ParsedFlightOfferModalState.Adding,
  flightOffers: [initialFlightOffer({})],
  activeFlightOffer: undefined,
  errors: {},
  additionalSeamen: [],
  setAdditionalSeamen: () => null,
  setActiveFlightOffer: () => null,
  setModalState: () => null,
  updateFlightOffer: () => initialFlightOffer({}),
  updateFlightOfferFields: () => defaultFields,
  addFlightOffer: () => null,
  deleteFlightOffer: () => null,
  handelReset: () => null,
  getFlightOffer: () => undefined,
  handleFlightOffersValidation: () => ({}),
  updateFlightOffersOnValidation: () => null,
  handleCreateBookingFromScratch: async () => [],
  handleEditBookingFromScratch: () => Promise.resolve({ data: undefined }),
  removeAirports: () => [],
}

export const TravelAgentParsedFlightContext =
  React.createContext<TravelAgentParsedFlightContext>(initialContext)

interface Props {
  children: ReactNode
}

export default function TravelAgentParsedFlightProvider({ children }: Props) {
  const [flightOffers, setFlightOffers] = useState<FlightOfferDetails[]>(
    initialContext.flightOffers
  )

  const [modalState, setModalState] = useState<ParsedFlightOfferModalState>(
    ParsedFlightOfferModalState.Adding
  )

  const [activeFlightOffer, setActiveFlightOffer] = useState<FlightOfferDetails>()

  const [errors, setErrors] = useState<Record<string, number>>({})

  const [additionalSeamen, setAdditionalSeamen] = useState<CrewChangeQuery.CrewChangeSeaman[]>([])

  const { isPnrInvalid } = usePnrValidation()

  const updateFlightOffer = useCallback(
    ({ id, ...rest }: FlightOfferDetails) => {
      let updatedOffer: FlightOfferDetails | undefined

      setFlightOffers(
        flightOffers.map((flightOffer) => {
          if (flightOffer.id === id) {
            updatedOffer = { ...flightOffer, ...rest }

            return updatedOffer
          }

          return flightOffer
        })
      )

      if (activeFlightOffer && activeFlightOffer.id === id) {
        setActiveFlightOffer(updatedOffer)
      }

      return updatedOffer
    },
    [activeFlightOffer, flightOffers]
  )

  const addFlightOffer = useCallback(() => {
    if (flightOffers.length === 3) {
      return
    }

    if (modalState !== ParsedFlightOfferModalState.Adding) {
      setModalState(ParsedFlightOfferModalState.Updating)
    }

    setFlightOffers((prevFlightOffers) => [...prevFlightOffers, initialFlightOffer({})])
  }, [flightOffers.length, modalState])

  const deleteFlightOffer = useCallback((id: string) => {
    setFlightOffers((prevFlightOffers) =>
      prevFlightOffers.filter((flightOffer) => flightOffer.id !== id)
    )
  }, [])

  const getFlightOffer = useCallback(
    (id: string) => {
      return flightOffers.find((flightOffer) => flightOffer.id === id)
    },
    [flightOffers]
  )

  const updateFlightOfferFields = useCallback(
    (flightOffer: FlightOfferDetails, fields: FlightOfferDetails['fields']) => {
      if (!flightOffer.fields || !flightOffer) {
        globalErrorHandler(new Error('Flight offer or fields are undefined'), {
          critical: true,
        })
      }

      const updatedFields = {
        ...flightOffer.fields,
        ...fields,
      }

      updateFlightOffer({
        ...flightOffer,
        fields: updatedFields,
      })

      return updatedFields
    },
    [updateFlightOffer]
  )

  const handelReset = useCallback(() => {
    setModalState(ParsedFlightOfferModalState.Adding)
    setFlightOffers(initialContext.flightOffers)
    setActiveFlightOffer(undefined)
    setErrors({})
    setAdditionalSeamen([])
  }, [])

  const removeAirports = useCallback(
    (segments: ParsedFlightOfferSegment[]): ParsedSegmentInput[] => {
      if (!segments) {
        throw new Error('Segments are undefined')
      }

      const updatedSegments = segments.map((segment) => {
        const { arrival, departure, ...rest } = segment

        const { airport: arrivalAirport, ...arrivalRest } = arrival
        const { airport: departureAirport, ...departureRest } = departure

        return {
          ...rest,
          arrival: arrivalRest,
          departure: departureRest,
        }
      })

      return updatedSegments
    },
    []
  )

  const [createBookingFromScratch] = useCreateBookingFromScratchMutation()

  const [editBookingFromScratch] = useEditBookingFromScratchMutation()

  const handleCreateBookingFromScratch = useCallback(
    async ({ bookingFromScratch }: FlightOfferDetails, bucketId: string) => {
      if (!bookingFromScratch) {
        throw new Error('Booking from scratch is undefined')
      }

      try {
        const seamenIds = [...additionalSeamen.map((x) => x.seaman.id), bookingFromScratch.seamanId]

        if (seamenIds.length === 1) {
          return [
            await createBookingFromScratch({
              variables: {
                params: {
                  ...bookingFromScratch,
                  bucketId,
                  seamanId: seamenIds[0],
                },
              },
            }),
          ]
        }

        return Promise.all(
          seamenIds.map((seamanId) => {
            return createBookingFromScratch({
              variables: {
                params: {
                  ...bookingFromScratch,
                  bucketId,
                  seamanId,
                },
              },
            })
          })
        )
      } catch (error: any) {
        globalErrorHandler(error, {
          isAsync: true,
          notifyUser: true,
        })

        return Promise.resolve([])
      }
    },
    [additionalSeamen, createBookingFromScratch]
  )

  const handleEditBookingFromScratch = useCallback(
    async ({ bookingFromScratch, id }: FlightOfferDetails) => {
      if (!bookingFromScratch) {
        throw new Error('Booking from scratch is undefined')
      }

      try {
        return editBookingFromScratch({
          variables: {
            params: {
              id,
              ...bookingFromScratch,
            },
          },
        })
      } catch (error: any) {
        globalErrorHandler(error, {
          isAsync: true,
          notifyUser: true,
        })

        return Promise.resolve({ data: undefined })
      }
    },
    [editBookingFromScratch]
  )

  const validateRequiredFields = useCallback(
    ({
      booking,
      fields,
    }: {
      booking: FlightOfferDetails['bookingFromScratch']
      fields: FlightOfferDetails['fields']
    }) => {
      // Early return if bookingFromScratch or fields are undefined
      if (!booking) {
        throw new Error('Booking from scratch is undefined')
      }

      if (!fields) {
        throw new Error('Fields are undefined')
      }

      // Use object destructuring and spread syntax for clarity
      const validatedFields = { ...fields }
      let errorCount = 0

      // Iterate through the keys of fields
      for (const field of Object.keys(fields) as (keyof CreateBookingFromScratchInput)[]) {
        const bookingValueValid =
          field === 'pnr' && booking.pnr
            ? !isPnrInvalid({ lazyValidation: false, pnr: booking.pnr }) // Special PNR validation
            : !!booking[field] && !isNil(booking[field])

        const { isVisible, required } = fields[field]

        // Update the validatedFields object
        validatedFields[field] = {
          valid: bookingValueValid,
          required,
          isVisible,
        }

        // If the field is required and the value is undefined, set errored to true
        if (required && !bookingValueValid) {
          ++errorCount
        }
      }

      return {
        validatedFields,
        errorCount,
      }
    },
    [isPnrInvalid]
  )

  const handleFlightOffersValidation = useCallback(
    (flightOffersParam: FlightOfferDetails[]) => {
      const data: {
        [key: FlightOfferDetails['id']]: {
          validatedFields: FlightOfferDetails['fields']
          errorCount: number
        }
      } = {}

      flightOffersParam.forEach((flightOffer) => {
        const { errorCount, validatedFields } = validateRequiredFields({
          booking: flightOffer.bookingFromScratch,
          fields: flightOffer.fields,
        })

        setErrors({
          ...errors,
          [flightOffer.id]: errorCount,
        })

        data[flightOffer.id] = {
          validatedFields,
          errorCount,
        }
      })

      return data
    },
    [errors, validateRequiredFields]
  )

  const updateFlightOffersOnValidation = useCallback(
    (flightOffersParam: FlightOfferDetails[]) => {
      if (!flightOffersParam.length) {
        globalErrorHandler(new Error(`Flight Offers missing when validating`), {
          isAsync: false,
          notifyUser: false,
        })
      }

      const validatedOffers = handleFlightOffersValidation(flightOffersParam)

      flightOffersParam.forEach((flightOfferParam) => {
        const updatedFields = updateFlightOfferFields(
          flightOfferParam,
          validatedOffers[flightOfferParam.id].validatedFields
        )

        const updatedFlightOffer = {
          ...flightOfferParam,
          fields: updatedFields,
        }

        updateFlightOffer(updatedFlightOffer)
      })
    },
    [handleFlightOffersValidation, updateFlightOffer, updateFlightOfferFields]
  )

  const context = useMemo(
    () => ({
      modalState,
      activeFlightOffer,
      flightOffers,
      errors,
      additionalSeamen,
      setAdditionalSeamen,
      setActiveFlightOffer,
      setModalState,
      updateFlightOffer,
      updateFlightOfferFields,
      addFlightOffer,
      deleteFlightOffer,
      removeAirports,
      getFlightOffer,
      handelReset,
      handleFlightOffersValidation,
      handleCreateBookingFromScratch,
      handleEditBookingFromScratch,
      updateFlightOffersOnValidation,
    }),
    [
      modalState,
      activeFlightOffer,
      flightOffers,
      additionalSeamen,
      setAdditionalSeamen,
      errors,
      updateFlightOffer,
      updateFlightOfferFields,
      addFlightOffer,
      deleteFlightOffer,
      removeAirports,
      getFlightOffer,
      handelReset,
      handleFlightOffersValidation,
      handleCreateBookingFromScratch,
      handleEditBookingFromScratch,
      updateFlightOffersOnValidation,
    ]
  )

  return (
    <TravelAgentParsedFlightContext.Provider value={context}>
      {children}
    </TravelAgentParsedFlightContext.Provider>
  )
}
