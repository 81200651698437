/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import { SeaportFieldsFragmentDoc } from './seaports-gql.generated'
import { AirportFieldsFragmentDoc } from './airports-gql.generated'
import {
  FlightRecommendationResultFieldsFragmentDoc,
  CompletePortAgencyFragmentDoc,
  VesselFieldsFragmentDoc,
} from './fragments.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ValidSchedulesForVesselQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input']
}>

export type ValidSchedulesForVesselQuery = {
  validSchedulesForVessel: Array<{
    id: string
    ETA: string
    ETB: string
    ETD: string
    planned: boolean
    sourceId: string
    source: Types.VesselScheduleSource
    isTemporary: boolean | null
    isPlannedSeaport: boolean
    plannedSeaportId: string | null
    voyageNumber: string | null
    crewChangeLinkage: { isLinked: boolean; bucketId: string | null; isTemporary: boolean } | null
    seaport: {
      id: string
      name: string
      nameAlpha2: string | null
      alternateNames: Array<string>
      countryAlpha2: string
      locode: string
      latitude: number
      longitude: number
      timezoneOlson: string | null
    }
    nearestAirports: Array<{
      id: string
      alternativeIatas: Array<string>
      city: string
      countryAlpha2: string
      iata: string
      name: string
      timezoneOlson: string | null
    }>
    vessel: { id: string }
    recommendations: Array<{
      result: Types.FlightRecommendationResultType
      scheduleId: string
      seamanId: string
      flights: Array<{
        id: string
        status: Types.FlightBookingStatus
        active: boolean
        scheduleId: string
        planned: string
        createdAt: string
        updatedAt: string
        bucketId: string | null
        flight: {
          id: string
          updatedAt: string
          createdAt: string
          flightHash: string
          majorityCarrier: string | null
          travelOfficeConfigurationId: string
          validatingCarrier: string | null
          segments: Array<{
            id: string
            flightNumber: number
            index: number
            departure: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            arrival: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            marketingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
            operatingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
          }>
          offers: Array<{
            id: string
            amount: number
            tax: number | null
            passengerType: Types.PassengerType | null
            currency: string
            createdAt: string
            updatedAt: string
            flightId: string
            amadeusSessionId: string | null
            segmentFares: Array<{
              availableSeats: number | null
              fareTypes: Array<Types.FareType>
              fareBasisCode: string
              flightSegmentId: string
              bookingClass: string | null
              baggageDetails: {
                freeBagAllowance: number | null
                freeBagAllowanceType: Types.FreeBagAllowanceType | null
              }
            }>
            rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
            minirules: {
              beforeDeparture: {
                changeAllowed: boolean | null
                changeMaxPenaltyAmount: number | null
                refundAllowed: boolean | null
                refundMaxPenaltyAmount: number | null
                revalidationAllowed: boolean | null
                revalidationMaxPenaltyAmount: number | null
                isoTicketValidityDate: string | null
                taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
              } | null
              beforeDepartureNoShow: {
                changeAllowed: boolean | null
                changeMaxPenaltyAmount: number | null
                refundAllowed: boolean | null
                refundMaxPenaltyAmount: number | null
                revalidationAllowed: boolean | null
                revalidationMaxPenaltyAmount: number | null
                isoTicketValidityDate: string | null
                taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
              } | null
              afterDeparture: {
                changeAllowed: boolean | null
                changeMaxPenaltyAmount: number | null
                refundAllowed: boolean | null
                refundMaxPenaltyAmount: number | null
                revalidationAllowed: boolean | null
                revalidationMaxPenaltyAmount: number | null
                isoTicketValidityDate: string | null
                taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
              } | null
              afterDepartureNoShow: {
                changeAllowed: boolean | null
                changeMaxPenaltyAmount: number | null
                refundAllowed: boolean | null
                refundMaxPenaltyAmount: number | null
                revalidationAllowed: boolean | null
                revalidationMaxPenaltyAmount: number | null
                isoTicketValidityDate: string | null
                taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
              } | null
            } | null
            taxDetails: Array<{
              amount: number
              code: string | null
              isNonRefundable: boolean | null
            }> | null
          }>
          travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
        }
        offer: {
          id: string
          amount: number
          tax: number | null
          passengerType: Types.PassengerType | null
          currency: string
          createdAt: string
          updatedAt: string
          flightId: string
          amadeusSessionId: string | null
          segmentFares: Array<{
            availableSeats: number | null
            fareTypes: Array<Types.FareType>
            fareBasisCode: string
            flightSegmentId: string
            bookingClass: string | null
            baggageDetails: {
              freeBagAllowance: number | null
              freeBagAllowanceType: Types.FreeBagAllowanceType | null
            }
          }>
          rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
          minirules: {
            beforeDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            beforeDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
          } | null
          taxDetails: Array<{
            amount: number
            code: string | null
            isNonRefundable: boolean | null
          }> | null
        }
      }> | null
    }>
    portAgencies: Array<{
      id: string
      name: string
      type: Types.PortAgencyType
      countryAlpha2: string
      state: string | null
      zipCode: string | null
      city: string | null
      address: string | null
      email: string | null
      phone: string | null
      portAgents: Array<{
        id: string
        role: string | null
        mobile: string | null
        user: {
          id: string
          firstName: string | null
          lastName: string | null
          email: string
          countryAlpha2: string | null
          timezoneOlson: string | null
        }
      }>
      portAgencyServices: Array<{
        id: string
        name: string
        category: Types.PortAgencyServiceCategory
        unit: Types.PortAgencyServiceUnit
        priceMin: string | null
        priceMax: string | null
        currencyAlpha3: string | null
      }>
    }>
  }>
}

export type UpdateVesselScheduleMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input']
  ETA: Types.Scalars['DateTime']['input']
  ETB: Types.Scalars['DateTime']['input']
  ETD: Types.Scalars['DateTime']['input']
  seaportId?: Types.InputMaybe<Types.Scalars['UUID']['input']>
}>

export type UpdateVesselScheduleMutation = {
  vesselSchedule: {
    update: {
      id: string
      ETA: string
      ETB: string
      ETD: string
      updatedAt: string
      createdAt: string
      bunkering: boolean
      cancelled: boolean
      COSP: string | null
      discharging: boolean
      EOSP: string | null
      loading: boolean
      portCountry: string | null
      portName: string | null
      portTerminal: string | null
      sourceId: string | null
      source: Types.VesselScheduleSource
      TOA: string | null
      TOD: string | null
      validFrom: string | null
      validUntil: string | null
      isPlannedSeaport: boolean
      plannedSeaportId: string | null
      voyageNumber: string | null
    }
  } | null
}

export type AddVesselScheduleMutationVariables = Types.Exact<{
  params: Types.AddVesselScheduleInput
}>

export type AddVesselScheduleMutation = {
  addVesselSchedule: {
    id: string
    ETA: string
    ETB: string
    ETD: string
    updatedAt: string
    createdAt: string
    bunkering: boolean
    cancelled: boolean
    COSP: string | null
    discharging: boolean
    EOSP: string | null
    loading: boolean
    portCountry: string | null
    portName: string | null
    portTerminal: string | null
    sourceId: string | null
    source: Types.VesselScheduleSource
    TOA: string | null
    TOD: string | null
    validFrom: string | null
    validUntil: string | null
    isPlannedSeaport: boolean
    plannedSeaportId: string | null
    voyageNumber: string | null
  }
}

export type DeleteVesselScheduleMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input']
}>

export type DeleteVesselScheduleMutation = { vesselSchedule: { delete: boolean } | null }

export type VesselScheduleFieldsFragment = {
  id: string
  ETA: string
  ETB: string
  ETD: string
  updatedAt: string
  createdAt: string
  bunkering: boolean
  cancelled: boolean
  COSP: string | null
  discharging: boolean
  EOSP: string | null
  loading: boolean
  portCountry: string | null
  portName: string | null
  portTerminal: string | null
  sourceId: string | null
  source: Types.VesselScheduleSource
  TOA: string | null
  TOD: string | null
  validFrom: string | null
  validUntil: string | null
  isPlannedSeaport: boolean
  plannedSeaportId: string | null
  voyageNumber: string | null
}

export type ValidScheduleFieldsFragment = {
  id: string
  sourceId: string
  source: Types.VesselScheduleSource
  ETA: string
  ETB: string
  ETD: string
  isPlannedSeaport: boolean
  plannedSeaportId: string | null
  voyageNumber: string | null
}

export const VesselScheduleFieldsFragmentDoc = gql`
  fragment VesselScheduleFields on VesselSchedule {
    id
    ETA
    ETB
    ETD
    updatedAt
    createdAt
    bunkering
    cancelled
    COSP
    discharging
    EOSP
    loading
    portCountry
    portName
    portTerminal
    sourceId
    source
    TOA
    TOD
    validFrom
    validUntil
    isPlannedSeaport
    plannedSeaportId
    voyageNumber
  }
`
export const ValidScheduleFieldsFragmentDoc = gql`
  fragment ValidScheduleFields on ValidSchedule {
    id
    sourceId
    source
    ETA
    ETB
    ETD
    isPlannedSeaport
    plannedSeaportId
    voyageNumber
  }
`
export const ValidSchedulesForVesselDocument = gql`
  query ValidSchedulesForVessel($id: UUID!) {
    validSchedulesForVessel(id: $id) {
      id
      ETA
      ETB
      ETD
      planned
      sourceId
      source
      isTemporary
      isPlannedSeaport
      plannedSeaportId
      voyageNumber
      crewChangeLinkage {
        isLinked
        bucketId
        isTemporary
      }
      seaport {
        ...SeaportFields
      }
      nearestAirports(take: 1) {
        ...AirportFields
      }
      vessel {
        id
      }
      recommendations {
        ...FlightRecommendationResultFields
      }
      portAgencies {
        ...CompletePortAgency
      }
    }
  }
  ${SeaportFieldsFragmentDoc}
  ${AirportFieldsFragmentDoc}
  ${FlightRecommendationResultFieldsFragmentDoc}
  ${CompletePortAgencyFragmentDoc}
`

/**
 * __useValidSchedulesForVesselQuery__
 *
 * To run a query within a React component, call `useValidSchedulesForVesselQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidSchedulesForVesselQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidSchedulesForVesselQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useValidSchedulesForVesselQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidSchedulesForVesselQuery,
    ValidSchedulesForVesselQueryVariables
  > &
    ({ variables: ValidSchedulesForVesselQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ValidSchedulesForVesselQuery, ValidSchedulesForVesselQueryVariables>(
    ValidSchedulesForVesselDocument,
    options
  )
}
export function useValidSchedulesForVesselLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidSchedulesForVesselQuery,
    ValidSchedulesForVesselQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ValidSchedulesForVesselQuery, ValidSchedulesForVesselQueryVariables>(
    ValidSchedulesForVesselDocument,
    options
  )
}
export function useValidSchedulesForVesselSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ValidSchedulesForVesselQuery,
    ValidSchedulesForVesselQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ValidSchedulesForVesselQuery,
    ValidSchedulesForVesselQueryVariables
  >(ValidSchedulesForVesselDocument, options)
}
export type ValidSchedulesForVesselQueryHookResult = ReturnType<
  typeof useValidSchedulesForVesselQuery
>
export type ValidSchedulesForVesselLazyQueryHookResult = ReturnType<
  typeof useValidSchedulesForVesselLazyQuery
>
export type ValidSchedulesForVesselSuspenseQueryHookResult = ReturnType<
  typeof useValidSchedulesForVesselSuspenseQuery
>
export type ValidSchedulesForVesselQueryResult = Apollo.QueryResult<
  ValidSchedulesForVesselQuery,
  ValidSchedulesForVesselQueryVariables
>
export const UpdateVesselScheduleDocument = gql`
  mutation UpdateVesselSchedule(
    $id: UUID!
    $ETA: DateTime!
    $ETB: DateTime!
    $ETD: DateTime!
    $seaportId: UUID
  ) {
    vesselSchedule(id: $id) {
      update(ETA: $ETA, ETB: $ETB, ETD: $ETD, seaportId: $seaportId) {
        ...VesselScheduleFields
      }
    }
  }
  ${VesselScheduleFieldsFragmentDoc}
`
export type UpdateVesselScheduleMutationFn = Apollo.MutationFunction<
  UpdateVesselScheduleMutation,
  UpdateVesselScheduleMutationVariables
>

/**
 * __useUpdateVesselScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateVesselScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVesselScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVesselScheduleMutation, { data, loading, error }] = useUpdateVesselScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      ETA: // value for 'ETA'
 *      ETB: // value for 'ETB'
 *      ETD: // value for 'ETD'
 *      seaportId: // value for 'seaportId'
 *   },
 * });
 */
export function useUpdateVesselScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVesselScheduleMutation,
    UpdateVesselScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateVesselScheduleMutation, UpdateVesselScheduleMutationVariables>(
    UpdateVesselScheduleDocument,
    options
  )
}
export type UpdateVesselScheduleMutationHookResult = ReturnType<
  typeof useUpdateVesselScheduleMutation
>
export type UpdateVesselScheduleMutationResult = Apollo.MutationResult<UpdateVesselScheduleMutation>
export type UpdateVesselScheduleMutationOptions = Apollo.BaseMutationOptions<
  UpdateVesselScheduleMutation,
  UpdateVesselScheduleMutationVariables
>
export const AddVesselScheduleDocument = gql`
  mutation AddVesselSchedule($params: AddVesselScheduleInput!) {
    addVesselSchedule(params: $params) {
      ...VesselScheduleFields
    }
  }
  ${VesselScheduleFieldsFragmentDoc}
`
export type AddVesselScheduleMutationFn = Apollo.MutationFunction<
  AddVesselScheduleMutation,
  AddVesselScheduleMutationVariables
>

/**
 * __useAddVesselScheduleMutation__
 *
 * To run a mutation, you first call `useAddVesselScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVesselScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVesselScheduleMutation, { data, loading, error }] = useAddVesselScheduleMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useAddVesselScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddVesselScheduleMutation,
    AddVesselScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddVesselScheduleMutation, AddVesselScheduleMutationVariables>(
    AddVesselScheduleDocument,
    options
  )
}
export type AddVesselScheduleMutationHookResult = ReturnType<typeof useAddVesselScheduleMutation>
export type AddVesselScheduleMutationResult = Apollo.MutationResult<AddVesselScheduleMutation>
export type AddVesselScheduleMutationOptions = Apollo.BaseMutationOptions<
  AddVesselScheduleMutation,
  AddVesselScheduleMutationVariables
>
export const DeleteVesselScheduleDocument = gql`
  mutation DeleteVesselSchedule($id: UUID!) {
    vesselSchedule(id: $id) {
      delete
    }
  }
`
export type DeleteVesselScheduleMutationFn = Apollo.MutationFunction<
  DeleteVesselScheduleMutation,
  DeleteVesselScheduleMutationVariables
>

/**
 * __useDeleteVesselScheduleMutation__
 *
 * To run a mutation, you first call `useDeleteVesselScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVesselScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVesselScheduleMutation, { data, loading, error }] = useDeleteVesselScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVesselScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVesselScheduleMutation,
    DeleteVesselScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteVesselScheduleMutation, DeleteVesselScheduleMutationVariables>(
    DeleteVesselScheduleDocument,
    options
  )
}
export type DeleteVesselScheduleMutationHookResult = ReturnType<
  typeof useDeleteVesselScheduleMutation
>
export type DeleteVesselScheduleMutationResult = Apollo.MutationResult<DeleteVesselScheduleMutation>
export type DeleteVesselScheduleMutationOptions = Apollo.BaseMutationOptions<
  DeleteVesselScheduleMutation,
  DeleteVesselScheduleMutationVariables
>
