import { Switch } from '@headlessui/react'
import clsx from 'clsx'

interface ToggleSwitchProps {
  onToggle: (toggle: boolean) => void
  toggled: boolean
  label?: string
  placement?: 'space-between'
  disabled?: boolean
  color?: 'blue' | 'green'
  size?: 'sm' | 'md'
}

const ToggleSwitch = ({
  onToggle,
  toggled,
  label,
  placement,
  disabled,
  color = 'blue',
  size = 'md',
}: ToggleSwitchProps) => {
  return (
    <Switch.Group>
      <div className={clsx('flex items-center text-sm', placement && 'justify-between')}>
        {label && (
          <Switch.Label className={clsx('mr-4', !toggled && 'text-gray-500')}>{label}</Switch.Label>
        )}
        <Switch
          disabled={disabled}
          checked={toggled}
          onChange={onToggle}
          className={clsx(
            color === 'green'
              ? {
                  'bg-emerald-600': toggled,
                  'focus:ring-emerald-500': true,
                }
              : {
                  'bg-blue-600': toggled,
                  'focus:ring-indigo-500': true,
                },
            !toggled && 'bg-gray-200',
            size === 'sm' ? 'h-2.5 w-4' : 'h-6 w-11',
            'relative inline-flex items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2'
          )}
        >
          <span
            className={clsx(
              size === 'sm'
                ? {
                    'right-[1px]': toggled,
                    'left-[1px]': !toggled,
                    'h-2 w-2': true,
                  }
                : {
                    'right-[4px]': toggled,
                    'left-[4px]': !toggled,
                    'h-4 w-4': true,
                  },
              'inline-block absolute transform rounded-full bg-white transition-transform'
            )}
          />
        </Switch>
      </div>
    </Switch.Group>
  )
}

export default ToggleSwitch
