import Link from 'next/link'
import { CrewChangeSummary } from '~components/CrewChanges/types'
import Alert from '~components/ui/Alert/Alert'
import Button from '~components/ui/Button/Button'
import ButtonIcon from '~components/ui/Button/ButtonIcon'
import OpenInNewIcon from '~components/ui/Icon/OpenInNewIcon'

interface Props {
  onCancel: (reload?: boolean) => void
  crewChange: CrewChangeSummary
}

export default function RestoreCrewChangeModalCompleteState({ onCancel, crewChange }: Props) {
  return (
    <>
      <div className="flex flex-col mb-6 px-6">
        <Alert variant="success" title="Crew Change Restored" />
      </div>
      <hr />
      <div className="flex justify-between py-4 px-6">
        <Button variant="outline" onClick={() => onCancel(true)}>
          Done
        </Button>
        <Link target="_blank" href={`/crew-changes/${crewChange.bucketId}`} passHref>
          <ButtonIcon variant="success" icon={OpenInNewIcon} passEvent>
            View Crew Change
          </ButtonIcon>
        </Link>
      </div>
    </>
  )
}
