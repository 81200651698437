import clsx from 'clsx'
import ChevronDownIcon from '~components/ui/Icon/ChevronDownIcon'

interface Props {
  isOpen: boolean
  onToggleOpen: () => void
}

export default function VesselFilterToggle({ isOpen, onToggleOpen }: Props) {
  return (
    <>
      <button
        type="button"
        className="font-semibold text-sm text-neutral-500 flex flex-row items-center gap-2 bg-neutral-100 px-2 py-1 rounded-md border border-neutral-200"
        onClick={onToggleOpen}
      >
        Vessel Filter <ChevronDownIcon className={clsx('h-4', !isOpen && 'transform rotate-180')} />
      </button>
    </>
  )
}
