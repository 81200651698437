/* eslint-disable */
const hotjarSites: { [domain: string]: { id: number; sv: number } } = {
  'doehle.tilla.tech': {
    id: 2740868,
    sv: 6,
  },
}

export function initHotjar() {
  const site = hotjarSites[window.location.hostname]
  if (!site) {
    return
  }
  ;(function (h: any, o, t, j, a?: any, r?: any) {
    h.hj =
      h.hj ||
      function (...args: any[]) {
        ;(h.hj.q = h.hj.q || []).push(args)
      }
    h._hjSettings = { hjid: site.id, hjsv: site.sv, hjdebug: true }
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script')
    r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}
