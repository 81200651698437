import clsx from 'clsx'
import React, { ReactNode } from 'react'

export interface TabBarNavigationProps {
  children?: ReactNode
  className?: string
}

function TabBarNavigation({ children, className }: TabBarNavigationProps): JSX.Element {
  return (
    <nav className={clsx('flex-initial h-16 lg:flex-1', className)}>
      <ul className="flex h-full space-x-8">
        {React.Children.map(children, (child) => {
          return <li className="h-full">{child}</li>
        })}
      </ul>
    </nav>
  )
}

export default TabBarNavigation
