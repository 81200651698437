import { useMediaQuery } from 'react-responsive'

export const useTabletAndAboveMediaQuery = () => useMediaQuery({ minWidth: 1024 })

export const useMobileMediaQuery = () => useMediaQuery({ maxWidth: 1023 })

export const TabletAndAbove = (props: { children: JSX.Element }) => {
  const isDesktop = useTabletAndAboveMediaQuery()

  return isDesktop ? props.children : null
}

export const Mobile = (props: { children: JSX.Element }) => {
  const isTabletAndBelow = useMobileMediaQuery()

  return isTabletAndBelow ? props.children : null
}
