/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type MeQueryVariables = Types.Exact<{ [key: string]: never }>

export type MeQuery = {
  me: {
    id: string
    email: string
    firstName: string | null
    lastName: string | null
    role: Types.UserRole
    phoneNumber: string | null
    city: string | null
    countryAlpha2: string | null
    timezoneOlson: string | null
    emailSignature: string | null
    fernandHash: string | null
    tenant: {
      id: string
      name: string
      dataSourceSystem: Types.DataSourceSystem
      preferences: {
        id: string
        preferredCurrency: string
        preferredFlightHistoryExportCurrency: string
        autoTicketHoursBeforeLTD: number
        defaultETicketEmailAttachmentFormat: Types.ETicketEmailAttachmentFormat
        preferredTravelOffice: {
          id: string
          agencyName: string
          commission: number
          currency: string
          officeId: string
          emailPrimary: string
          active: boolean
          updatedAt: string
        } | null
        monitoringPreferences: {
          unmonitorInHoursBeforeDeparture: number
          autoSwitchTicketedFlights: boolean
        } | null
      }
    }
  }
}

export type TenantsForUserQueryVariables = Types.Exact<{ [key: string]: never }>

export type TenantsForUserQuery = { tenantsForUser: Array<{ name: string; subdomain: string }> }

export type RefreshAuthQueryVariables = Types.Exact<{ [key: string]: never }>

export type RefreshAuthQuery = { refreshAuth: void | null }

export type LogoutMutationVariables = Types.Exact<{ [key: string]: never }>

export type LogoutMutation = { logout: void | null }

export type UpdateMeMutationVariables = Types.Exact<{
  data: Types.MeUpdateInput
}>

export type UpdateMeMutation = {
  me: {
    id: string
    email: string
    firstName: string | null
    lastName: string | null
    role: Types.UserRole
    phoneNumber: string | null
    city: string | null
    countryAlpha2: string | null
    timezoneOlson: string | null
    emailSignature: string | null
    fernandHash: string | null
    tenant: {
      id: string
      name: string
      dataSourceSystem: Types.DataSourceSystem
      preferences: {
        id: string
        preferredCurrency: string
        preferredFlightHistoryExportCurrency: string
        autoTicketHoursBeforeLTD: number
        defaultETicketEmailAttachmentFormat: Types.ETicketEmailAttachmentFormat
        preferredTravelOffice: {
          id: string
          agencyName: string
          commission: number
          currency: string
          officeId: string
          emailPrimary: string
          active: boolean
          updatedAt: string
        } | null
        monitoringPreferences: {
          unmonitorInHoursBeforeDeparture: number
          autoSwitchTicketedFlights: boolean
        } | null
      }
    }
  }
}

export type UserFieldsFragment = {
  id: string
  email: string
  firstName: string | null
  lastName: string | null
  role: Types.UserRole
  phoneNumber: string | null
  city: string | null
  countryAlpha2: string | null
  timezoneOlson: string | null
  emailSignature: string | null
  fernandHash: string | null
  tenant: {
    id: string
    name: string
    dataSourceSystem: Types.DataSourceSystem
    preferences: {
      id: string
      preferredCurrency: string
      preferredFlightHistoryExportCurrency: string
      autoTicketHoursBeforeLTD: number
      defaultETicketEmailAttachmentFormat: Types.ETicketEmailAttachmentFormat
      preferredTravelOffice: {
        id: string
        agencyName: string
        commission: number
        currency: string
        officeId: string
        emailPrimary: string
        active: boolean
        updatedAt: string
      } | null
      monitoringPreferences: {
        unmonitorInHoursBeforeDeparture: number
        autoSwitchTicketedFlights: boolean
      } | null
    }
  }
}

export type PreferredTravelOfficeFragment = {
  id: string
  agencyName: string
  commission: number
  currency: string
  officeId: string
  emailPrimary: string
  active: boolean
  updatedAt: string
}

export type SafeUserFieldsFragment = {
  id: string
  email: string
  firstName: string | null
  lastName: string | null
  role: Types.UserRole
  phoneNumber: string | null
  city: string | null
  countryAlpha2: string | null
  timezoneOlson: string | null
  emailSignature: string | null
  fernandHash: string | null
}

export const PreferredTravelOfficeFragmentDoc = gql`
  fragment PreferredTravelOffice on TravelOfficeConfiguration {
    id
    agencyName
    commission
    currency
    officeId
    emailPrimary
    active
    updatedAt
  }
`
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    id
    email
    firstName
    lastName
    role
    firstName
    lastName
    phoneNumber
    city
    countryAlpha2
    timezoneOlson
    emailSignature
    fernandHash
    tenant {
      id
      name
      dataSourceSystem
      preferences {
        id
        preferredCurrency
        preferredFlightHistoryExportCurrency
        preferredTravelOffice {
          ...PreferredTravelOffice
        }
        autoTicketHoursBeforeLTD
        defaultETicketEmailAttachmentFormat
        monitoringPreferences {
          unmonitorInHoursBeforeDeparture
          autoSwitchTicketedFlights
        }
      }
    }
  }
  ${PreferredTravelOfficeFragmentDoc}
`
export const SafeUserFieldsFragmentDoc = gql`
  fragment SafeUserFields on SafePartialUser {
    id
    email
    firstName
    lastName
    role
    firstName
    lastName
    phoneNumber
    city
    countryAlpha2
    timezoneOlson
    emailSignature
    fernandHash
  }
`
export const MeDocument = gql`
  query me {
    me {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
export const TenantsForUserDocument = gql`
  query TenantsForUser {
    tenantsForUser {
      name
      subdomain
    }
  }
`

/**
 * __useTenantsForUserQuery__
 *
 * To run a query within a React component, call `useTenantsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantsForUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantsForUserQuery(
  baseOptions?: Apollo.QueryHookOptions<TenantsForUserQuery, TenantsForUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TenantsForUserQuery, TenantsForUserQueryVariables>(
    TenantsForUserDocument,
    options
  )
}
export function useTenantsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TenantsForUserQuery, TenantsForUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TenantsForUserQuery, TenantsForUserQueryVariables>(
    TenantsForUserDocument,
    options
  )
}
export function useTenantsForUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TenantsForUserQuery, TenantsForUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<TenantsForUserQuery, TenantsForUserQueryVariables>(
    TenantsForUserDocument,
    options
  )
}
export type TenantsForUserQueryHookResult = ReturnType<typeof useTenantsForUserQuery>
export type TenantsForUserLazyQueryHookResult = ReturnType<typeof useTenantsForUserLazyQuery>
export type TenantsForUserSuspenseQueryHookResult = ReturnType<
  typeof useTenantsForUserSuspenseQuery
>
export type TenantsForUserQueryResult = Apollo.QueryResult<
  TenantsForUserQuery,
  TenantsForUserQueryVariables
>
export const RefreshAuthDocument = gql`
  query RefreshAuth {
    refreshAuth
  }
`

/**
 * __useRefreshAuthQuery__
 *
 * To run a query within a React component, call `useRefreshAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefreshAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefreshAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useRefreshAuthQuery(
  baseOptions?: Apollo.QueryHookOptions<RefreshAuthQuery, RefreshAuthQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RefreshAuthQuery, RefreshAuthQueryVariables>(RefreshAuthDocument, options)
}
export function useRefreshAuthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RefreshAuthQuery, RefreshAuthQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RefreshAuthQuery, RefreshAuthQueryVariables>(
    RefreshAuthDocument,
    options
  )
}
export function useRefreshAuthSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<RefreshAuthQuery, RefreshAuthQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<RefreshAuthQuery, RefreshAuthQueryVariables>(
    RefreshAuthDocument,
    options
  )
}
export type RefreshAuthQueryHookResult = ReturnType<typeof useRefreshAuthQuery>
export type RefreshAuthLazyQueryHookResult = ReturnType<typeof useRefreshAuthLazyQuery>
export type RefreshAuthSuspenseQueryHookResult = ReturnType<typeof useRefreshAuthSuspenseQuery>
export type RefreshAuthQueryResult = Apollo.QueryResult<RefreshAuthQuery, RefreshAuthQueryVariables>
export const LogoutDocument = gql`
  mutation Logout {
    logout
  }
`
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options)
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>
export const UpdateMeDocument = gql`
  mutation UpdateMe($data: MeUpdateInput!) {
    me(data: $data) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`
export type UpdateMeMutationFn = Apollo.MutationFunction<
  UpdateMeMutation,
  UpdateMeMutationVariables
>

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options)
}
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<
  UpdateMeMutation,
  UpdateMeMutationVariables
>
