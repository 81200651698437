import { DateTime } from 'luxon'

export default function getDateTimeForFlightLeg({
  date,
  time,
  format = 'yyyy-MM-dd HH:mm',
  timezoneOlson,
}: {
  date: string
  time: string
  format?: string
  timezoneOlson?: string | null
}): DateTime {
  return DateTime.fromFormat(`${date} ${time}`, format, {
    zone: timezoneOlson ?? 'Etc/UTC',
  })
}
