import { DateTime } from 'luxon'
import { useRouter } from 'next/router'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { Role } from 'types'
import { TravelOfficeConfigContext } from '~context/TravelOfficeConfigContext'

import { UserContext } from '~context/UserContext'
import { useAuthToken } from '~hooks/useAuthToken'

const DEFAULT_AUTHENTICATED_PATH = '/'
const LOGIN_PATH = '/login'
export const ALLOWED_PUBLIC_PATHS = [
  '/login',
  '/forgot-password',
  '/reset-password',
  '/refresh-token',
]

const forbiddenRouteMatchersForRole = new Map<Role, RegExp[]>([
  [Role.TravelAgent, [/crew-changes/]],
  [Role.CrewOperator, [/travel-agent/]],
])

export default function RouteGuard({ children }: { children: ReactNode }): JSX.Element {
  const router = useRouter()
  const { isLoading, isAuthenticated, popDeepLink, user } = useContext(UserContext)
  const travelOfficeConfigContext = useContext(TravelOfficeConfigContext)

  // By tracking this, we can block the flash of unauthorized content
  const [willRedirect, setWillRedirect] = useState(false)

  const cleanedPath = router.asPath.includes('?') ? router.asPath.split('?')[0] : router.asPath

  const token = useAuthToken()

  const isPathForbidden = (role: Role, path: string) => {
    const forbiddenRoutes = forbiddenRouteMatchersForRole.get(role) ?? []

    return forbiddenRoutes.some((rx) => rx.test(path))
  }

  useEffect(() => {
    // Do this before isLoading check to prevent flashing unauthorized info before redirect
    if (user.role && isAuthenticated) {
      if (isPathForbidden(user.role, cleanedPath)) {
        router.push(DEFAULT_AUTHENTICATED_PATH)
      }
    }

    if (isLoading) {
      return
    }

    if (ALLOWED_PUBLIC_PATHS.includes(cleanedPath)) {
      if (isAuthenticated) {
        setWillRedirect(true)
        const deepLink = popDeepLink()
        const hasDeepLinkAttemptedWithinDuration =
          deepLink && deepLink.attemptAt > DateTime.now().minus({ minutes: 1 })
        if (deepLink && hasDeepLinkAttemptedWithinDuration) {
          router.push(deepLink.path)
        } else {
          router.push(DEFAULT_AUTHENTICATED_PATH)
        }
      } else {
        setWillRedirect(false)
      }
    } else if (!isAuthenticated) {
      setWillRedirect(true)

      // If `route.query.token` is set but we're still not authenticated,
      // that means the token was expired.
      // If the user came in with an expired token, redirect to the /refresh-token view
      if (token) {
        const redirect = window.encodeURIComponent(cleanedPath)
        router.push(`/refresh-token?expired=${token}&redirect=${redirect}`)
      } else {
        router.push(LOGIN_PATH)
      }
    } else {
      setWillRedirect(false)
    }
  }, [router, isLoading, isAuthenticated, popDeepLink, cleanedPath, token, user])

  if (isLoading || travelOfficeConfigContext.isLoading) {
    return <></>
  }

  if (willRedirect) {
    return <></>
  }

  return <>{children}</>
}
