export type Env = 'development' | 'staging' | 'uat' | 'production'

export function isEnv(env: Env) {
  return process.env.NEXT_PUBLIC_APP_ENV === env
}

export function isProd() {
  return isEnv('production')
}

export function isDev() {
  return isEnv('development')
}

export function isStaging() {
  return isEnv('staging')
}

export function isUat() {
  return isEnv('uat')
}
