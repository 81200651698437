/* eslint-disable react/jsx-props-no-spreading */
import { SVGProps } from 'react'

function FlightCancelledIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        d="M15.1066 2.41976L15.2932 1.90598C15.3825 1.66247 15.3227 1.38922 15.1399 1.20537L14.7932 0.858405C14.6095 0.675416 14.3365 0.615563 14.0932 0.704939L13.5799 0.891768C13.2117 1.02416 12.8771 1.23601 12.5999 1.5123L9.71989 4.3948L6.15322 3.68085L12.3199 9.85285L11.6066 6.2831L14.4866 3.4006C14.7626 3.12313 14.9743 2.78827 15.1066 2.41976ZM2.01989 1.33114C2.10828 1.33114 2.19273 1.36773 2.25322 1.43224L14.5599 13.7562C14.6243 13.8168 14.6609 13.9013 14.6609 13.9898C14.6609 14.0782 14.6243 14.1628 14.5599 14.2233L14.2066 14.5769C14.1461 14.6414 14.0616 14.678 13.9732 14.678C13.8848 14.678 13.8004 14.6414 13.7399 14.5769L12.9199 13.7629C12.8613 13.9548 12.7559 14.1291 12.6132 14.27L12.4999 14.3834C12.4416 14.4462 12.3588 14.4803 12.2732 14.4769C12.1616 14.4769 12.058 14.4188 11.9999 14.3234L10.5332 11.3742L8.44655 9.2857L6.44655 10.9605L6.73989 14.4301C6.7375 14.5996 6.67076 14.7618 6.55322 14.8839L6.19989 15.2375C6.12666 15.3104 6.02278 15.3435 5.92093 15.3263C5.81909 15.309 5.7318 15.2437 5.68655 15.1508L4.07322 11.9213L0.846553 10.3066C0.753707 10.2613 0.688412 10.1739 0.671217 10.072C0.654023 9.97006 0.687043 9.86609 0.759886 9.7928L1.11322 9.43916C1.23514 9.32152 1.39722 9.25472 1.56655 9.25233L4.98655 9.53925L6.69989 7.53752L4.61989 5.45572L1.68655 4.00112C1.58921 3.94172 1.5308 3.83496 1.53322 3.72088C1.52976 3.63525 1.56385 3.55238 1.62655 3.49402L1.73989 3.38059C1.88068 3.23774 2.05481 3.13225 2.24655 3.07365L1.43322 2.25294C1.36877 2.1924 1.33221 2.10788 1.33221 2.01941C1.33221 1.93094 1.36877 1.84642 1.43322 1.78587L1.78655 1.43224C1.84704 1.36773 1.9315 1.33114 2.01989 1.33114Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default FlightCancelledIcon
