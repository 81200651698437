import { CrewChangeSummary } from '~components/CrewChanges/types'

export default function getCurrentCrewChangeStatus(
  crewChange: Pick<CrewChangeSummary, 'completed' | 'started'>
) {
  if (crewChange.completed) {
    return 'completed'
  }

  if (crewChange.started) {
    return 'started'
  }

  return 'clean'
}
