/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import {
  CompleteFlightBookingFragmentDoc,
  CompleteFlightBookingRefundFragmentDoc,
  CompleteSeamanFragmentDoc,
  VesselFieldsFragmentDoc,
  ParsedFlightOfferFieldsFragmentDoc,
} from './fragments.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type CreateBookingFromPnrMutationVariables = Types.Exact<{
  params: Types.CreateBookingFromPnrInput
}>

export type CreateBookingFromPnrMutation = {
  createBookingFromPnr: {
    id: string
    active: boolean
    monitored: boolean
    ticketLine: string | null
    comment: string | null
    pnr: string | null
    status: Types.FlightBookingStatus
    amadeusSessionId: string | null
    managedByTravelTeam: boolean
    isAmadeus: boolean
    lastTicketingTime: string | null
    lastTicketingTimeSource: Types.LastTicketingTimeSource
    pendingUpdatedByTravelAgentNotification: boolean
    switchForIdenticalCheaperOfferAutomatically: boolean
    switchForSimilarCheaperOfferAutomatically: boolean
    ticketedAt: string | null
    bucketId: string | null
    autoTicket: boolean
    travelOfficeConfigurationId: string | null
    flight: {
      id: string
      updatedAt: string
      createdAt: string
      flightHash: string
      majorityCarrier: string | null
      travelOfficeConfigurationId: string
      validatingCarrier: string | null
      segments: Array<{
        id: string
        flightNumber: number
        index: number
        departure: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        arrival: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }>
      travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
    }
    offer: {
      id: string
      amount: number
      tax: number | null
      passengerType: Types.PassengerType | null
      currency: string
      createdAt: string
      updatedAt: string
      flightId: string
      amadeusSessionId: string | null
      segmentFares: Array<{
        availableSeats: number | null
        fareTypes: Array<Types.FareType>
        fareBasisCode: string
        flightSegmentId: string
        bookingClass: string | null
        baggageDetails: {
          freeBagAllowance: number | null
          freeBagAllowanceType: Types.FreeBagAllowanceType | null
        }
      }>
      rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
      minirules: {
        beforeDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        beforeDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
      } | null
      taxDetails: Array<{
        amount: number
        code: string | null
        isNonRefundable: boolean | null
      }> | null
    }
    finalOffer: { amount: number; currency: Types.Currency } | null
    seaman: {
      id: string
      firstName: string
      lastName: string
      nationalityIso: string
      rank: number | null
      birthday: string | null
      placeOfBirth: string | null
      firstVaccinationDate: string | null
      firstVaccinationBrand: string | null
      secondVaccinationDate: string | null
      secondVaccinationBrand: string | null
      updatedAt: string
    }
    ticketLineData: { documentNumber: string } | null
    splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
  }
}

export type CreateBookingFromScratchMutationVariables = Types.Exact<{
  params: Types.CreateBookingFromScratchInput
}>

export type CreateBookingFromScratchMutation = {
  createBookingFromScratch: {
    id: string
    active: boolean
    monitored: boolean
    ticketLine: string | null
    comment: string | null
    pnr: string | null
    status: Types.FlightBookingStatus
    amadeusSessionId: string | null
    managedByTravelTeam: boolean
    isAmadeus: boolean
    lastTicketingTime: string | null
    lastTicketingTimeSource: Types.LastTicketingTimeSource
    pendingUpdatedByTravelAgentNotification: boolean
    switchForIdenticalCheaperOfferAutomatically: boolean
    switchForSimilarCheaperOfferAutomatically: boolean
    ticketedAt: string | null
    bucketId: string | null
    autoTicket: boolean
    travelOfficeConfigurationId: string | null
    flight: {
      id: string
      updatedAt: string
      createdAt: string
      flightHash: string
      majorityCarrier: string | null
      travelOfficeConfigurationId: string
      validatingCarrier: string | null
      segments: Array<{
        id: string
        flightNumber: number
        index: number
        departure: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        arrival: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }>
      travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
    }
    offer: {
      id: string
      amount: number
      tax: number | null
      passengerType: Types.PassengerType | null
      currency: string
      createdAt: string
      updatedAt: string
      flightId: string
      amadeusSessionId: string | null
      segmentFares: Array<{
        availableSeats: number | null
        fareTypes: Array<Types.FareType>
        fareBasisCode: string
        flightSegmentId: string
        bookingClass: string | null
        baggageDetails: {
          freeBagAllowance: number | null
          freeBagAllowanceType: Types.FreeBagAllowanceType | null
        }
      }>
      rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
      minirules: {
        beforeDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        beforeDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
      } | null
      taxDetails: Array<{
        amount: number
        code: string | null
        isNonRefundable: boolean | null
      }> | null
    }
    finalOffer: { amount: number; currency: Types.Currency } | null
    seaman: {
      id: string
      firstName: string
      lastName: string
      nationalityIso: string
      rank: number | null
      birthday: string | null
      placeOfBirth: string | null
      firstVaccinationDate: string | null
      firstVaccinationBrand: string | null
      secondVaccinationDate: string | null
      secondVaccinationBrand: string | null
      updatedAt: string
    }
    ticketLineData: { documentNumber: string } | null
    splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
  }
}

export type EditBookingFromScratchMutationVariables = Types.Exact<{
  params: Types.EditBookingFromScratchInput
}>

export type EditBookingFromScratchMutation = {
  editBookingFromScratch: {
    id: string
    active: boolean
    monitored: boolean
    ticketLine: string | null
    comment: string | null
    pnr: string | null
    status: Types.FlightBookingStatus
    amadeusSessionId: string | null
    managedByTravelTeam: boolean
    isAmadeus: boolean
    lastTicketingTime: string | null
    lastTicketingTimeSource: Types.LastTicketingTimeSource
    pendingUpdatedByTravelAgentNotification: boolean
    switchForIdenticalCheaperOfferAutomatically: boolean
    switchForSimilarCheaperOfferAutomatically: boolean
    ticketedAt: string | null
    bucketId: string | null
    autoTicket: boolean
    travelOfficeConfigurationId: string | null
    flight: {
      id: string
      updatedAt: string
      createdAt: string
      flightHash: string
      majorityCarrier: string | null
      travelOfficeConfigurationId: string
      validatingCarrier: string | null
      segments: Array<{
        id: string
        flightNumber: number
        index: number
        departure: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        arrival: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }>
      travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
    }
    offer: {
      id: string
      amount: number
      tax: number | null
      passengerType: Types.PassengerType | null
      currency: string
      createdAt: string
      updatedAt: string
      flightId: string
      amadeusSessionId: string | null
      segmentFares: Array<{
        availableSeats: number | null
        fareTypes: Array<Types.FareType>
        fareBasisCode: string
        flightSegmentId: string
        bookingClass: string | null
        baggageDetails: {
          freeBagAllowance: number | null
          freeBagAllowanceType: Types.FreeBagAllowanceType | null
        }
      }>
      rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
      minirules: {
        beforeDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        beforeDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
      } | null
      taxDetails: Array<{
        amount: number
        code: string | null
        isNonRefundable: boolean | null
      }> | null
    }
    finalOffer: { amount: number; currency: Types.Currency } | null
    seaman: {
      id: string
      firstName: string
      lastName: string
      nationalityIso: string
      rank: number | null
      birthday: string | null
      placeOfBirth: string | null
      firstVaccinationDate: string | null
      firstVaccinationBrand: string | null
      secondVaccinationDate: string | null
      secondVaccinationBrand: string | null
      updatedAt: string
    }
    ticketLineData: { documentNumber: string } | null
    splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
  }
}

export type CopyPnrMutationVariables = Types.Exact<{
  bookingId: Types.Scalars['UUID']['input']
  seamanIds: Array<Types.Scalars['UUID']['input']> | Types.Scalars['UUID']['input']
}>

export type CopyPnrMutation = {
  copyPnr: Array<{
    id: string
    active: boolean
    monitored: boolean
    ticketLine: string | null
    comment: string | null
    pnr: string | null
    status: Types.FlightBookingStatus
    amadeusSessionId: string | null
    managedByTravelTeam: boolean
    isAmadeus: boolean
    lastTicketingTime: string | null
    lastTicketingTimeSource: Types.LastTicketingTimeSource
    pendingUpdatedByTravelAgentNotification: boolean
    switchForIdenticalCheaperOfferAutomatically: boolean
    switchForSimilarCheaperOfferAutomatically: boolean
    ticketedAt: string | null
    bucketId: string | null
    autoTicket: boolean
    travelOfficeConfigurationId: string | null
    flight: {
      id: string
      updatedAt: string
      createdAt: string
      flightHash: string
      majorityCarrier: string | null
      travelOfficeConfigurationId: string
      validatingCarrier: string | null
      segments: Array<{
        id: string
        flightNumber: number
        index: number
        departure: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        arrival: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }>
      travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
    }
    offer: {
      id: string
      amount: number
      tax: number | null
      passengerType: Types.PassengerType | null
      currency: string
      createdAt: string
      updatedAt: string
      flightId: string
      amadeusSessionId: string | null
      segmentFares: Array<{
        availableSeats: number | null
        fareTypes: Array<Types.FareType>
        fareBasisCode: string
        flightSegmentId: string
        bookingClass: string | null
        baggageDetails: {
          freeBagAllowance: number | null
          freeBagAllowanceType: Types.FreeBagAllowanceType | null
        }
      }>
      rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
      minirules: {
        beforeDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        beforeDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
      } | null
      taxDetails: Array<{
        amount: number
        code: string | null
        isNonRefundable: boolean | null
      }> | null
    }
    finalOffer: { amount: number; currency: Types.Currency } | null
    seaman: {
      id: string
      firstName: string
      lastName: string
      nationalityIso: string
      rank: number | null
      birthday: string | null
      placeOfBirth: string | null
      firstVaccinationDate: string | null
      firstVaccinationBrand: string | null
      secondVaccinationDate: string | null
      secondVaccinationBrand: string | null
      updatedAt: string
    }
    ticketLineData: { documentNumber: string } | null
    splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
  }>
}

export type DeletePnrMutationVariables = Types.Exact<{
  bookingId: Types.Scalars['UUID']['input']
}>

export type DeletePnrMutation = { deletePnr: boolean }

export type EditPnrMutationVariables = Types.Exact<{
  params: Types.EditPnrInput
}>

export type EditPnrMutation = {
  editPnr: {
    id: string
    active: boolean
    monitored: boolean
    ticketLine: string | null
    comment: string | null
    pnr: string | null
    status: Types.FlightBookingStatus
    amadeusSessionId: string | null
    managedByTravelTeam: boolean
    isAmadeus: boolean
    lastTicketingTime: string | null
    lastTicketingTimeSource: Types.LastTicketingTimeSource
    pendingUpdatedByTravelAgentNotification: boolean
    switchForIdenticalCheaperOfferAutomatically: boolean
    switchForSimilarCheaperOfferAutomatically: boolean
    ticketedAt: string | null
    bucketId: string | null
    autoTicket: boolean
    travelOfficeConfigurationId: string | null
    flight: {
      id: string
      updatedAt: string
      createdAt: string
      flightHash: string
      majorityCarrier: string | null
      travelOfficeConfigurationId: string
      validatingCarrier: string | null
      segments: Array<{
        id: string
        flightNumber: number
        index: number
        departure: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        arrival: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }>
      travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
    }
    offer: {
      id: string
      amount: number
      tax: number | null
      passengerType: Types.PassengerType | null
      currency: string
      createdAt: string
      updatedAt: string
      flightId: string
      amadeusSessionId: string | null
      segmentFares: Array<{
        availableSeats: number | null
        fareTypes: Array<Types.FareType>
        fareBasisCode: string
        flightSegmentId: string
        bookingClass: string | null
        baggageDetails: {
          freeBagAllowance: number | null
          freeBagAllowanceType: Types.FreeBagAllowanceType | null
        }
      }>
      rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
      minirules: {
        beforeDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        beforeDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
      } | null
      taxDetails: Array<{
        amount: number
        code: string | null
        isNonRefundable: boolean | null
      }> | null
    }
    finalOffer: { amount: number; currency: Types.Currency } | null
    seaman: {
      id: string
      firstName: string
      lastName: string
      nationalityIso: string
      rank: number | null
      birthday: string | null
      placeOfBirth: string | null
      firstVaccinationDate: string | null
      firstVaccinationBrand: string | null
      secondVaccinationDate: string | null
      secondVaccinationBrand: string | null
      updatedAt: string
    }
    ticketLineData: { documentNumber: string } | null
    splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
  }
}

export const CreateBookingFromPnrDocument = gql`
  mutation CreateBookingFromPnr($params: CreateBookingFromPnrInput!) {
    createBookingFromPnr(params: $params) {
      ...CompleteFlightBooking
    }
  }
  ${CompleteFlightBookingFragmentDoc}
`
export type CreateBookingFromPnrMutationFn = Apollo.MutationFunction<
  CreateBookingFromPnrMutation,
  CreateBookingFromPnrMutationVariables
>

/**
 * __useCreateBookingFromPnrMutation__
 *
 * To run a mutation, you first call `useCreateBookingFromPnrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingFromPnrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingFromPnrMutation, { data, loading, error }] = useCreateBookingFromPnrMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateBookingFromPnrMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBookingFromPnrMutation,
    CreateBookingFromPnrMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateBookingFromPnrMutation, CreateBookingFromPnrMutationVariables>(
    CreateBookingFromPnrDocument,
    options
  )
}
export type CreateBookingFromPnrMutationHookResult = ReturnType<
  typeof useCreateBookingFromPnrMutation
>
export type CreateBookingFromPnrMutationResult = Apollo.MutationResult<CreateBookingFromPnrMutation>
export type CreateBookingFromPnrMutationOptions = Apollo.BaseMutationOptions<
  CreateBookingFromPnrMutation,
  CreateBookingFromPnrMutationVariables
>
export const CreateBookingFromScratchDocument = gql`
  mutation CreateBookingFromScratch($params: CreateBookingFromScratchInput!) {
    createBookingFromScratch(params: $params) {
      ...CompleteFlightBooking
    }
  }
  ${CompleteFlightBookingFragmentDoc}
`
export type CreateBookingFromScratchMutationFn = Apollo.MutationFunction<
  CreateBookingFromScratchMutation,
  CreateBookingFromScratchMutationVariables
>

/**
 * __useCreateBookingFromScratchMutation__
 *
 * To run a mutation, you first call `useCreateBookingFromScratchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingFromScratchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingFromScratchMutation, { data, loading, error }] = useCreateBookingFromScratchMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateBookingFromScratchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBookingFromScratchMutation,
    CreateBookingFromScratchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateBookingFromScratchMutation,
    CreateBookingFromScratchMutationVariables
  >(CreateBookingFromScratchDocument, options)
}
export type CreateBookingFromScratchMutationHookResult = ReturnType<
  typeof useCreateBookingFromScratchMutation
>
export type CreateBookingFromScratchMutationResult =
  Apollo.MutationResult<CreateBookingFromScratchMutation>
export type CreateBookingFromScratchMutationOptions = Apollo.BaseMutationOptions<
  CreateBookingFromScratchMutation,
  CreateBookingFromScratchMutationVariables
>
export const EditBookingFromScratchDocument = gql`
  mutation EditBookingFromScratch($params: EditBookingFromScratchInput!) {
    editBookingFromScratch(params: $params) {
      ...CompleteFlightBooking
    }
  }
  ${CompleteFlightBookingFragmentDoc}
`
export type EditBookingFromScratchMutationFn = Apollo.MutationFunction<
  EditBookingFromScratchMutation,
  EditBookingFromScratchMutationVariables
>

/**
 * __useEditBookingFromScratchMutation__
 *
 * To run a mutation, you first call `useEditBookingFromScratchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBookingFromScratchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBookingFromScratchMutation, { data, loading, error }] = useEditBookingFromScratchMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useEditBookingFromScratchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditBookingFromScratchMutation,
    EditBookingFromScratchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EditBookingFromScratchMutation,
    EditBookingFromScratchMutationVariables
  >(EditBookingFromScratchDocument, options)
}
export type EditBookingFromScratchMutationHookResult = ReturnType<
  typeof useEditBookingFromScratchMutation
>
export type EditBookingFromScratchMutationResult =
  Apollo.MutationResult<EditBookingFromScratchMutation>
export type EditBookingFromScratchMutationOptions = Apollo.BaseMutationOptions<
  EditBookingFromScratchMutation,
  EditBookingFromScratchMutationVariables
>
export const CopyPnrDocument = gql`
  mutation CopyPnr($bookingId: UUID!, $seamanIds: [UUID!]!) {
    copyPnr(bookingId: $bookingId, seamanIds: $seamanIds) {
      ...CompleteFlightBooking
    }
  }
  ${CompleteFlightBookingFragmentDoc}
`
export type CopyPnrMutationFn = Apollo.MutationFunction<CopyPnrMutation, CopyPnrMutationVariables>

/**
 * __useCopyPnrMutation__
 *
 * To run a mutation, you first call `useCopyPnrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyPnrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyPnrMutation, { data, loading, error }] = useCopyPnrMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      seamanIds: // value for 'seamanIds'
 *   },
 * });
 */
export function useCopyPnrMutation(
  baseOptions?: Apollo.MutationHookOptions<CopyPnrMutation, CopyPnrMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CopyPnrMutation, CopyPnrMutationVariables>(CopyPnrDocument, options)
}
export type CopyPnrMutationHookResult = ReturnType<typeof useCopyPnrMutation>
export type CopyPnrMutationResult = Apollo.MutationResult<CopyPnrMutation>
export type CopyPnrMutationOptions = Apollo.BaseMutationOptions<
  CopyPnrMutation,
  CopyPnrMutationVariables
>
export const DeletePnrDocument = gql`
  mutation DeletePnr($bookingId: UUID!) {
    deletePnr(bookingId: $bookingId)
  }
`
export type DeletePnrMutationFn = Apollo.MutationFunction<
  DeletePnrMutation,
  DeletePnrMutationVariables
>

/**
 * __useDeletePnrMutation__
 *
 * To run a mutation, you first call `useDeletePnrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePnrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePnrMutation, { data, loading, error }] = useDeletePnrMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useDeletePnrMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePnrMutation, DeletePnrMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeletePnrMutation, DeletePnrMutationVariables>(
    DeletePnrDocument,
    options
  )
}
export type DeletePnrMutationHookResult = ReturnType<typeof useDeletePnrMutation>
export type DeletePnrMutationResult = Apollo.MutationResult<DeletePnrMutation>
export type DeletePnrMutationOptions = Apollo.BaseMutationOptions<
  DeletePnrMutation,
  DeletePnrMutationVariables
>
export const EditPnrDocument = gql`
  mutation EditPnr($params: EditPnrInput!) {
    editPnr(params: $params) {
      ...CompleteFlightBooking
    }
  }
  ${CompleteFlightBookingFragmentDoc}
`
export type EditPnrMutationFn = Apollo.MutationFunction<EditPnrMutation, EditPnrMutationVariables>

/**
 * __useEditPnrMutation__
 *
 * To run a mutation, you first call `useEditPnrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPnrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPnrMutation, { data, loading, error }] = useEditPnrMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useEditPnrMutation(
  baseOptions?: Apollo.MutationHookOptions<EditPnrMutation, EditPnrMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EditPnrMutation, EditPnrMutationVariables>(EditPnrDocument, options)
}
export type EditPnrMutationHookResult = ReturnType<typeof useEditPnrMutation>
export type EditPnrMutationResult = Apollo.MutationResult<EditPnrMutation>
export type EditPnrMutationOptions = Apollo.BaseMutationOptions<
  EditPnrMutation,
  EditPnrMutationVariables
>
