import {
  useLoadCurrentTravelOfficesQuery,
  useUpdateTravelOfficeMutation,
} from 'api/travel-office.generated'
import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react'
import {
  LoadTenantTravelOfficeConfigsQuery,
  useLoadTenantTravelOfficeConfigsQuery,
} from '../api/tenants-gql.generated'
import { UserContext } from './UserContext'

export type TravelOfficeConfig =
  LoadTenantTravelOfficeConfigsQuery['currentTenant']['travelOfficeConfigurations'][number]

interface ITravelOfficeConfigContext {
  travelOfficeConfigs: TravelOfficeConfig[]
  activeTravelOfficeConfigs: TravelOfficeConfig[]
  currentTravelOffices: TravelOfficeConfig[]
  getTravelOfficeConfig: (id: string) => TravelOfficeConfig | null
  getTravelOfficeConfigCommission: (id: string) => { amount: number; currency: string }
  updateTravelOffice: (id: string, active: boolean) => Promise<void>
  isLoading: boolean
}

const initialContext = {
  travelOfficeConfigs: [],
  activeTravelOfficeConfigs: [],
  currentTravelOffices: [],
  getTravelOfficeConfig: () => null,
  getTravelOfficeConfigCommission: () => ({ amount: 0, currency: 'USD' }),
  updateTravelOffice: async () => undefined,
  isLoading: true,
}

export const TravelOfficeConfigContext = createContext<ITravelOfficeConfigContext>(initialContext)

export function useTravelOfficeConfig(travelOfficeConfigId: string) {
  const data = useContext(TravelOfficeConfigContext)
  const config = data.travelOfficeConfigs.find((c) => c.id === travelOfficeConfigId)
  return config ?? null
}

function TravelOfficeConfigProvider({ children }: { children: ReactNode }) {
  const { isAuthenticated } = useContext(UserContext)
  const [currentTravelOffices, setCurrentTravelOffices] = useState<TravelOfficeConfig[]>([])

  const { loading, data } = useLoadTenantTravelOfficeConfigsQuery({
    skip: !isAuthenticated,
    variables: { includeInactive: true },
  })

  const configs = useMemo(() => data?.currentTenant?.travelOfficeConfigurations ?? [], [data])

  const activeTravelOfficeConfigs = useMemo(() => configs.filter((v) => v.active), [configs])

  useLoadCurrentTravelOfficesQuery({
    skip: !isAuthenticated,
    fetchPolicy: 'network-only',
    onError: () => undefined,
    onCompleted(travelOfficeConfigsForUser) {
      if (travelOfficeConfigsForUser.currentTravelOffices) {
        setCurrentTravelOffices(travelOfficeConfigsForUser.currentTravelOffices)
      }
    },
  })

  const getTravelOfficeConfig = useCallback(
    (id: string) => configs.find((c) => c.id === id) ?? null,
    [configs]
  )

  const getTravelOfficeConfigCommission = useCallback(
    (id: string) => {
      const config = getTravelOfficeConfig(id)

      return {
        amount: config?.commission ?? 0,
        currency: config?.currency ?? 'USD',
      }
    },
    [getTravelOfficeConfig]
  )

  const [updateTravelOfficeConfig] = useUpdateTravelOfficeMutation()

  const updateTravelOffice = useCallback(
    async (id: string, active: boolean) => {
      await updateTravelOfficeConfig({
        variables: {
          id,
          active,
        },
        onError: () => undefined,
      })

      const updatedConfig = currentTravelOffices.find((v) => v.id)

      if (updatedConfig) {
        setCurrentTravelOffices((prev) => [
          ...prev.map((v) => {
            if (v.id === id) {
              return {
                ...v,
                active: !v.active,
              }
            }
            return v
          }),
        ])
      }
    },
    [currentTravelOffices, updateTravelOfficeConfig]
  )

  const context = useMemo(() => {
    return {
      isLoading: loading,
      getTravelOfficeConfig,
      getTravelOfficeConfigCommission,
      updateTravelOffice,
      travelOfficeConfigs: configs,
      activeTravelOfficeConfigs,
      currentTravelOffices,
    }
  }, [
    loading,
    getTravelOfficeConfig,
    getTravelOfficeConfigCommission,
    updateTravelOffice,
    configs,
    activeTravelOfficeConfigs,
    currentTravelOffices,
  ])

  return (
    <TravelOfficeConfigContext.Provider value={context}>
      {children}
    </TravelOfficeConfigContext.Provider>
  )
}

export default TravelOfficeConfigProvider
