import { forwardRef } from 'react'

const AppShellLogo = forwardRef(({ size }: { size: number }, ref) => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 60 60"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#1e0dff">
        <path d="m34.1481 20.1319v7.6002l-2.6119-1.5092c-.1036-.0597-.1897-.1456-.2495-.2491s-.0913-.2209-.0913-.3404v-5.5015c.0002-.259-.0679-.5136-.1975-.7379-.1296-.2244-.3161-.4105-.5407-.5397l-9.6673-5.5857c-.224-.1293-.4782-.1974-.7369-.1974s-.5128.0681-.7368.1974l-6.816 3.9199c-.0528.0283-.0967.0708-.1269.1225-.0302.0518-.0454.111-.0441.1709-.0016.0605.0135.1203.0437.1727.0302.0525.0742.0956.1273.1247l7.3581 4.2487c.1036.0597.1896.1456.2494.2491.0599.1035.0914.2209.0914.3404v2.8948c0 .012-.0032.0238-.0092.0342s-.0146.019-.025.025-.0222.0092-.0342.0092c-.0121 0-.0238-.0032-.0342-.0092l-9.0713-5.2369c-.5019-.2882-.9189-.7037-1.20883-1.2046-.28996-.5009-.44265-1.0694-.44265-1.6481 0-.5788.15269-1.1473.44265-1.6481.28993-.5009.70693-.9164 1.20883-1.2046l6.8094-3.9198c.6734-.3864 1.4362-.5896 2.2125-.5896.7764 0 1.5392.2032 2.2126.5896l9.6725 5.5856c.673.3896 1.2318.949 1.6207 1.6224.3888.6733.5941 1.437.5952 2.2145z" />
        <path d="m25.0229 28.4216c-.1899-.1097-.4053-.1676-.6246-.1676-.2193-.0001-.4347.0576-.6247.1672-.1899.1096-.3476.2674-.4572.4573-.1097.19-.1673.4054-.1672.6247v17.0767c-.0001.0598.0155.1186.0453.1705.0299.0518.0728.0949.1245.1249.0518.03.1105.0458.1703.046.0598.0001.1186-.0155.1704-.0453l6.7975-3.9251c.2243-.1299.4105-.3164.54-.5409.1295-.2244.1979-.4789.1982-.7381v-7.0396c0-.012.0032-.0238.0092-.0342s.0146-.019.025-.025.0222-.0092.0342-.0092c.0121 0 .0238.0032.0342.0092l2.5093 1.4474c.1037.0599.1898.146.2496.2497s.0913.2214.0912.3411v5.0619c-.0015.7772-.2067 1.5404-.5953 2.2134-.3886.6731-.9469 1.2324-1.6192 1.6223l-6.8015 3.925c-.5091.2976-1.0893.4517-1.6789.4459s-1.1667-.1712-1.6698-.4788c-.4923-.3063-.8972-.7344-1.1759-1.2428-.2786-.5084-.4216-1.0801-.415-1.6599v-16.999c0-.7376.1942-1.4622.563-2.1009.3688-.6388.8992-1.1692 1.538-1.5379s1.3634-.5628 2.101-.5627 1.4621.1943 2.1008.5633l12.7253 7.3475c.2245.1293.4791.1974.7382.1974s.5136-.0681.7381-.1974l6.8041-3.9251c.052-.0299.0951-.0729.1251-.1248.03-.0518.0458-.1107.0458-.1706s-.0158-.1188-.0458-.1706c-.03-.0519-.0731-.0949-.1251-.1248l-10.5884-6.112c-.1035-.0601-.1894-.1462-.2492-.2499s-.0914-.2212-.0916-.3409v-2.8948c.0001-.012.0032-.0238.0092-.0342s.0147-.019.0251-.025.0222-.0092.0342-.0092.0238.0032.0342.0092l12.3029 7.1054c.5013.2884.9176.7038 1.2072 1.2044.2895.5006.442 1.0687.442 1.647s-.1525 1.1464-.442 1.647c-.2896.5006-.7059.916-1.2072 1.2044l-6.7975 3.9264c-.6738.3873-1.4374.5911-2.2146.5911-.7771 0-1.5407-.2038-2.2145-.5911z" />
      </g>
    </svg>
  )
})

AppShellLogo.displayName = 'AppShellLogo'
export default AppShellLogo
