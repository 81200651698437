import { ReactNode } from 'react'
import Button from '../Button/Button'
import Modal from '../Modal/Modal'
import { UiVariants } from '../types'

export interface Props {
  title: string
  text: ReactNode
  isOpen: boolean
  onConfirm?: () => void
  onCancel?: () => void
  showCancelButton?: boolean
  children?: ReactNode
  isLoading?: boolean
  confirmText?: string
  confirmButtonVariant?: UiVariants
}

export default function ConfirmPopup({
  title,
  text,
  isOpen,
  onConfirm,
  onCancel,
  showCancelButton = true,
  children,
  isLoading = false,
  confirmText = 'Confirm',
  confirmButtonVariant,
}: Props) {
  const handleCancel = () => {
    onCancel?.()
  }
  return (
    <Modal open={isOpen} title={title} onClose={handleCancel} showCloseButton={false}>
      <div className="px-6 pb-6 flex flex-col">
        <span className="block mb-4">{text}</span>
        {children}
        <div className="self-end space-x-3">
          {showCancelButton && (
            <Button variant="outline" onClick={handleCancel} disabled={isLoading}>
              Cancel
            </Button>
          )}
          <Button variant={confirmButtonVariant} onClick={onConfirm} disabled={isLoading}>
            {confirmText}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
