import { DOMAIN_MAP, getDomain } from '@tilla/backend/src/util/environments'
import { UserRole } from 'api/types.generated'
import { compact } from 'lodash'
import { MouseEvent, useContext, useState } from 'react'
import TravelOfficeSettingsModal from '~components/TravelAgent/TravelOfficeSettingsModal'
import EditEmailSignatureModal from '~components/User/EditEmailSignatureModal'
import ActionMenu from '~components/ui/ActionMenu/ActionMenu'
import Avatar from '~components/ui/Avatar/Avatar'
import OpenInNewIcon from '~components/ui/Icon/OpenInNewIcon'
import { UserContext } from '~context/UserContext'

export default function UserMenu({ isOpen }: { isOpen?: (v: boolean) => void }) {
  const userContext = useContext(UserContext)
  const [showEditSignatureModal, setShowEditSignatureModal] = useState(false)
  const [showTravelOfficeSettingsModal, setShowTravelOfficeSettingsModal] = useState(false)

  const onLogoutClick = async (e: MouseEvent) => {
    e.preventDefault()
    await userContext.logout()
  }

  return (
    <UserContext.Consumer>
      {({ user, tenantsForUser, isAuthenticated }) => (
        <>
          {isAuthenticated && (
            <>
              <ActionMenu
                toggle={<Avatar user={user} />}
                items={compact([
                  [UserRole.TravelAgent, UserRole.TillaBackoffice].includes(user.role)
                    ? {
                        title: 'Clients',
                        data: compact(
                          tenantsForUser
                            ?.filter((v) => v.name !== user.tenant.name)
                            .map((tenant) => ({
                              icon: OpenInNewIcon,
                              iconPosition: 'end',
                              children: <span>{tenant.name}</span>,
                              href: `https://${tenant.subdomain}.${getDomain(
                                (process.env.NEXT_PUBLIC_APP_ENV ??
                                  'development') as unknown as keyof typeof DOMAIN_MAP
                              )}`,
                            }))
                        ),
                      }
                    : null,
                  {
                    data: [
                      {
                        children: 'Edit signature',
                        onClick: () => setShowEditSignatureModal(true),
                      },
                      [UserRole.TravelAgent, UserRole.TillaBackoffice].includes(user.role)
                        ? {
                            children: 'Travel office settings',
                            onClick: () => setShowTravelOfficeSettingsModal(true),
                          }
                        : {},
                      {
                        href: '#sign-out',
                        children: 'Sign out',
                        onClick: onLogoutClick,
                      },
                    ],
                  },
                ])}
              />
              {showEditSignatureModal && (
                <EditEmailSignatureModal onClose={() => setShowEditSignatureModal(false)} />
              )}
              {showTravelOfficeSettingsModal && (
                <TravelOfficeSettingsModal
                  onClose={() => setShowTravelOfficeSettingsModal(false)}
                />
              )}
            </>
          )}
        </>
      )}
    </UserContext.Consumer>
  )
}
