/* eslint-disable react/jsx-props-no-spreading */
import { SVGProps } from 'react'

function BellIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path d="M12.5867 10.42L13.1401 10.9733C13.2632 11.0975 13.3327 11.2651 13.3334 11.44V11.8333C13.3334 12.2015 13.0349 12.5 12.6667 12.5H3.33341C2.96522 12.5 2.66675 12.2015 2.66675 11.8333V11.44C2.66748 11.2651 2.73692 11.0975 2.86008 10.9733L3.41341 10.42C3.78859 10.0453 3.99961 9.53693 4.00008 9.00668V7.16668C4.00008 6.3012 4.28079 5.45906 4.80008 4.76668L5.40008 3.96668C5.77779 3.46307 6.37057 3.16668 7.00008 3.16668H7.33341V2.16668C7.33341 1.98258 7.48265 1.83334 7.66675 1.83334H8.33341C8.51751 1.83334 8.66675 1.98258 8.66675 2.16668V3.16668H9.00008C9.6296 3.16668 10.2224 3.46307 10.6001 3.96668L11.2001 4.76668C11.7194 5.45906 12.0001 6.3012 12.0001 7.16668V9.00668C12.0005 9.53693 12.2116 10.0453 12.5867 10.42ZM8.00008 15.1667C8.73646 15.1667 9.33341 14.5697 9.33341 13.8333H6.66675C6.66675 14.5697 7.2637 15.1667 8.00008 15.1667Z" />
    </svg>
  )
}

export default BellIcon
