import { MouseEvent, useContext } from 'react'
import ClearIcon from '~components/ui/Icon/ClearIcon'
import Icon from '~components/ui/Icon/Icon'
import RestoreIcon from '~components/ui/Icon/RestoreIcon'
import Spinner from '~components/ui/Icon/Spinner'
import Tooltip from '~components/ui/Tooltip/Tooltip'
import { NotificationPanelTabs, NotificationsContext } from '~context/NotificationsContext'

interface Props {
  dismissHandler: (e: MouseEvent, restore?: boolean) => void
  isDismissing: boolean
}

export default function DismissNotificationButton({ dismissHandler, isDismissing }: Props) {
  const { currentTab } = useContext(NotificationsContext)

  return (
    <div className="text-brand mb-2 flex justify-end items-start">
      {currentTab !== NotificationPanelTabs.Dismissed ? (
        <Tooltip content="Dismiss notification" placement="left">
          <div>
            {isDismissing ? (
              <Spinner height={20} color="brand" />
            ) : (
              <span onClick={(e) => dismissHandler(e, false)}>
                <Icon
                  size="regular"
                  icon={ClearIcon}
                  className="text-indigo-500 hover:text-gray-300"
                />
              </span>
            )}
          </div>
        </Tooltip>
      ) : (
        <Tooltip content="Restore notification" placement="left">
          <div>
            {isDismissing ? (
              <Spinner height={20} color="brand" />
            ) : (
              <span onClick={(e) => dismissHandler(e, true)}>
                <Icon
                  size="large"
                  icon={RestoreIcon}
                  className="text-indigo-500 hover:text-gray-300 inline-flex"
                />
              </span>
            )}
          </div>
        </Tooltip>
      )}
    </div>
  )
}
