import clsx from 'clsx'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import formatDuration from 'utils/formatDuration'
import { VesselScheduleAbridgedSummary } from './types'
import { formatVesselScheduleToLocalTime } from './utils/formatVesselSchedule'

interface Props {
  vesselSchedule: VesselScheduleAbridgedSummary | undefined
  showRelativeTime?: boolean
  timeType: 'ETA' | 'ETB' | 'ETD'
}

export default function VesselSchedule({
  vesselSchedule,
  showRelativeTime = true,
  timeType,
}: Props) {
  const [formattedEta, formattedEtb, formattedEtd] = useMemo(() => {
    if (!vesselSchedule) {
      return ['', '', '']
    }

    return formatVesselScheduleToLocalTime(vesselSchedule)
  }, [vesselSchedule])

  let formattedDate = formattedEta
  if (timeType === 'ETB') {
    formattedDate = formattedEtb
  } else if (timeType === 'ETD') {
    formattedDate = formattedEtd
  }

  const relativeTime = useMemo(() => {
    if (!vesselSchedule) {
      return 0
    }
    const timezone = vesselSchedule.seaport.timezoneOlson ?? 'Etc/UTC'

    const now = DateTime.now().setZone(timezone)

    const eta = DateTime.fromISO(vesselSchedule.ETA, {
      zone: timezone,
    })
    const etb = DateTime.fromISO(vesselSchedule.ETB, {
      zone: timezone,
    })
    const etd = DateTime.fromISO(vesselSchedule.ETD, {
      zone: timezone,
    })

    switch (timeType) {
      case 'ETA':
        return eta.toMillis() - now.toMillis()
      case 'ETB':
        return etb.toMillis() - eta.toMillis()
      case 'ETD':
        return etd.toMillis() - etb.toMillis()

      default:
        return 0
    }
  }, [vesselSchedule, timeType])

  const shouldShow = useMemo(() => {
    if (!vesselSchedule) {
      return false
    }

    switch (timeType) {
      case 'ETA':
        if (vesselSchedule.ETA.startsWith('9999')) {
          return false
        }

        return true

      case 'ETB':
        if (vesselSchedule.ETB.startsWith('9999')) {
          return false
        }

        return true

      case 'ETD':
        if (vesselSchedule.ETD.startsWith('9999')) {
          return false
        }

        return true

      default:
        return false
    }
  }, [vesselSchedule, timeType])

  const formattedRelativeTime = useMemo(() => {
    if (timeType === 'ETA') {
      return relativeTime > 0 ? `In ${formatDuration(relativeTime)}` : 'On time'
    }

    return relativeTime > 0 ? `⇥ ${formatDuration(relativeTime)}` : ''
  }, [relativeTime, timeType])

  return (
    <div>
      <span
        className={clsx(
          'block text-xs md:text-sm leading-none text-gray-800',
          showRelativeTime && 'mb-2'
        )}
      >
        {shouldShow ? formattedDate : '—'}
      </span>
      {showRelativeTime && (
        <span className="block text-xs leading-none text-gray-500 hidden md:block">
          {shouldShow ? formattedRelativeTime : ' '}
        </span>
      )}
    </div>
  )
}
