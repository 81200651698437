import clsx from 'clsx'
import Link from 'next/link'
import { Dispatch, ReactNode, SetStateAction } from 'react'
import { useMobileMediaQuery } from 'utils/getResponsiveLayout'
import Button from '../Button/Button'
import BurgerIcon from '../Icon/BurgerIcon'
import ClearIcon from '../Icon/ClearIcon'
import Icon from '../Icon/Icon'
import AppShellLogo from './AppShellLogo'

export interface AppShellHeaderProps {
  children?: ReactNode
  isBurgerToggled: boolean
  setIsBurgerToggled: Dispatch<SetStateAction<boolean>>
}

function AppShellHeader({
  children,
  isBurgerToggled,
  setIsBurgerToggled,
}: AppShellHeaderProps): JSX.Element {
  const isMobile = useMobileMediaQuery()

  return (
    <header
      className={clsx(
        `bg-white border-gray-200 h-16 gap-x-14 items-center px-4 mx-auto lg:flex lg:px-8 lg:static`
      )}
    >
      <div className="flex items-center justify-between lg:block">
        <Link
          href="/"
          className="flex items-center"
          onClick={() => {
            setIsBurgerToggled(false)
          }}
        >
          <AppShellLogo size={60} />
        </Link>

        {/* Mobile Trigger */}
        <div className="lg:hidden">
          <Button
            variant="outline"
            className="text-gray-500 hover:text-gray-800"
            onClick={() => setIsBurgerToggled(!isBurgerToggled)}
          >
            {isBurgerToggled ? (
              <Icon icon={ClearIcon} size="large" />
            ) : (
              <Icon icon={BurgerIcon} size="large" />
            )}
          </Button>
        </div>
        {/* Mobile Trigger */}
      </div>

      {isMobile ? (
        <div
          className={clsx(
            'z-10 absolute bg-white w-full left-0 p-4 flex-col overflow-y-auto flex gap-y-6 shadow-md',
            { hidden: !isBurgerToggled }
          )}
        >
          {children}
        </div>
      ) : (
        <div
          className={clsx(
            'z-10 absolute bg-white w-full left-0 p-4 flex-col overflow-y-auto flex gap-y-6 shadow-md',
            'lg:relative lg:h-full lg:flex-row lg:justify-between lg:overflow-visible lg:pb-0 lg:p-0 lg:mt-0 lg:shadow-none'
          )}
        >
          {children}
        </div>
      )}
    </header>
  )
}

export default AppShellHeader
