import * as Sentry from '@sentry/nextjs'
import LogRocket from 'logrocket'

type ErrorContext = {
  isAsync?: boolean
  notifyUser?: boolean
  critical?: boolean
  operationName?: string // For GraphQL operations
}

export function globalErrorHandler(error: Error, context?: ErrorContext): void {
  // Log the error to Sentry
  Sentry.captureException(error, {
    tags: {
      isAsync: context?.isAsync,
    },
    extra: {
      // Include the operation name for GraphQL errors
      operationName: context?.operationName,
    },
  })

  // Log the error to LogRocket
  LogRocket.captureException(error)

  // Publish the error event
  ErrorEventEmitter.publish(error, context)

  if (process.env.APP_ENV !== 'production') {
    console.error(error)
  }
}

type Subscriber = (error: Error, context?: ErrorContext) => void
type Unsubscribe = () => void

export class ErrorEventEmitter {
  private static subscribers: Subscriber[] = []

  public static subscribe(subscriber: Subscriber): Unsubscribe {
    this.subscribers.push(subscriber)

    // Return an unsubscribe function
    return () => {
      this.subscribers = this.subscribers.filter((sub) => sub !== subscriber)
    }
  }

  public static publish(error: Error, context?: ErrorContext) {
    this.subscribers.forEach((subscriber) => subscriber(error, context))
  }
}
