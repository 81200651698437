import { Menu, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment, ReactNode } from 'react'

import ActionMenuItem, { ActionMenuItemProps } from './ActionMenuItem'

export interface ActionMenuProps {
  toggle?: ReactNode
  customToggleButton?: ReactNode
  items: {
    title?: string
    data: ActionMenuItemProps[]
  }[]
  placement?: 'right' | 'left'
  isOpen?: (v: boolean) => void
  width?: string
}

function ActionMenu({
  toggle,
  items,
  placement = 'left',
  isOpen,
  customToggleButton,
  width,
}: ActionMenuProps): JSX.Element {
  return (
    <Menu as="div" className="relative flex justify-end">
      {({ open }) => (
        <>
          {customToggleButton ?? (
            <Menu.Button className="flex items-center rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200">
              {toggle}
            </Menu.Button>
          )}
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            beforeEnter={() => isOpen && isOpen(open)}
            beforeLeave={() => isOpen && isOpen(!open)}
          >
            <Menu.Items
              static
              className={clsx(
                placement === 'left' && 'right-0',
                placement === 'right' && 'left-0',
                'absolute py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg top-full ring-1 ring-black ring-opacity-5 focus:outline-none z-50',
                width ?? 'w-48'
              )}
            >
              {items.map((item, i) => {
                if (item.title) {
                  return (
                    <div className="my-2" key={item.title ?? i.toString()}>
                      <div className="px-4 pt-2 pb-1 text-xs font-semibold text-gray-800">
                        {item.title}
                      </div>
                      {item.data.map((v, idx) => (
                        <Menu.Item key={v.href ?? idx.toString()}>
                          {({ active }) => <ActionMenuItem {...v} hasFocus={active} />}
                        </Menu.Item>
                      ))}
                    </div>
                  )
                }

                return (
                  <Fragment key={item?.title ?? i.toString()}>
                    {item.data.map((v, idx) => (
                      <Menu.Item key={v.href ?? idx.toString()}>
                        {({ active }) => <ActionMenuItem {...v} hasFocus={active} />}
                      </Menu.Item>
                    ))}
                    {i !== items.length - 1 && <hr className="mx-4" />}
                  </Fragment>
                )
              })}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export default ActionMenu
