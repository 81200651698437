import { ReactNode } from 'react'

export type TableVariant = 'standalone' | 'inline'

interface TableProps {
  variant?: TableVariant
  children: ReactNode
  fixed?: boolean
  className?: string
}

function Table({ variant = 'standalone', children, fixed, className }: TableProps): JSX.Element {
  return (
    <div
      className={[
        'w-full bg-white rounded-lg overflow-hidden',
        variant === 'standalone' && 'border border-gray-200 shadow-sm',
        className,
      ]
        .filter(Boolean)
        .join(' ')}
    >
      <table className={['w-full', fixed && 'table-fixed'].filter(Boolean).join(' ')}>
        {children}
      </table>
    </div>
  )
}

Table.defaultProps = {
  variant: 'standalone',
}

export default Table
