import { useContext, useEffect } from 'react'
import Badge from '~components/ui/Badge/Badge'
import BellIcon from '~components/ui/Icon/BellIcon'
import Spinner from '~components/ui/Icon/Spinner'
import { NotificationsContext } from '~context/NotificationsContext'
import { useTrackEvent } from '~hooks/useTrackEvent'
import NotificationsPanel from './NotificationsPanel'

interface NotificationsBadgeProps {
  defaultBucketId?: string
}

export default function NotificationsBadge({ defaultBucketId }: NotificationsBadgeProps) {
  const {
    setNotificationPanelOpen,
    hasUnreadNotifications,
    setHasUnreadNotifications,
    notificationsLoading,
    fetchNotifications,
    notifications,
    notificationsCount,
    setSelectedBucketId,
  } = useContext(NotificationsContext)
  const trackEvent = useTrackEvent()

  useEffect(() => {
    if (defaultBucketId) {
      setSelectedBucketId(defaultBucketId)
    } else {
      setSelectedBucketId(null)
    }
    if (notifications.length === 0) {
      fetchNotifications()
    }
  }, [])

  const handleOpenNotificationPanel = () => {
    setNotificationPanelOpen(true)
    setHasUnreadNotifications(false)
    trackEvent({
      event: 'Viewed notifications center',
      metadata: {
        mixpanelProperties: {
          overview: !defaultBucketId,
          ccUrl: defaultBucketId
            ? `https://${window.location.host}/crew-changes/${defaultBucketId}`
            : null,
        },
      },
    })
  }

  return (
    <Badge variant={hasUnreadNotifications ? 'info' : 'default'} icon={null}>
      <div
        className="flex flex-row items-center cursor-pointer"
        onClick={handleOpenNotificationPanel}
      >
        <BellIcon height={24} className="mt-2" />
        {notificationsLoading ? <Spinner className="h-4 w-4" /> : <p>{notificationsCount}</p>}
      </div>
      <NotificationsPanel defaultBucketId={defaultBucketId} />
    </Badge>
  )
}
