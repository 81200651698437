/* eslint-disable react/jsx-props-no-spreading */
import { SVGProps } from 'react'

function MenuVsIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path d="M6.66667 5.33333V3.33333C6.66667 2.59695 6.06971 2 5.33333 2H3.33333C2.59695 2 2 2.59695 2 3.33333V5.33333C2 6.06971 2.59695 6.66667 3.33333 6.66667H5.33333C6.06971 6.66667 6.66667 6.06971 6.66667 5.33333ZM10.6667 2H12.6667C13.403 2 14 2.59695 14 3.33333V5.33333C14 6.06971 13.403 6.66667 12.6667 6.66667H10.6667C9.93029 6.66667 9.33333 6.06971 9.33333 5.33333V3.33333C9.33333 2.59695 9.93029 2 10.6667 2ZM5.33333 9.33333H3.33333C2.59695 9.33333 2 9.93029 2 10.6667V12.6667C2 13.403 2.59695 14 3.33333 14H5.33333C6.06971 14 6.66667 13.403 6.66667 12.6667V10.6667C6.66667 9.93029 6.06971 9.33333 5.33333 9.33333ZM10.6667 9.33333H12.6667C13.403 9.33333 14 9.93029 14 10.6667V12.6667C14 13.403 13.403 14 12.6667 14H10.6667C9.93029 14 9.33333 13.403 9.33333 12.6667V10.6667C9.33333 9.93029 9.93029 9.33333 10.6667 9.33333Z" />
    </svg>
  )
}

export default MenuVsIcon
