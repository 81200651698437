/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type LoadCurrentTravelOfficesQueryVariables = Types.Exact<{ [key: string]: never }>

export type LoadCurrentTravelOfficesQuery = {
  currentTravelOffices: Array<{
    id: string
    agencyName: string
    commission: number
    commissionPercentage: number
    currency: string
    officeId: string
    emailPrimary: string
    phonePrimary: string
    active: boolean
    updatedAt: string
  }>
}

export type UpdateTravelOfficeMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input']
  active: Types.Scalars['Boolean']['input']
}>

export type UpdateTravelOfficeMutation = { updateTravelOffice: void | null }

export const LoadCurrentTravelOfficesDocument = gql`
  query LoadCurrentTravelOffices {
    currentTravelOffices {
      id
      agencyName
      commission
      commissionPercentage
      currency
      officeId
      emailPrimary
      phonePrimary
      active
      updatedAt
    }
  }
`

/**
 * __useLoadCurrentTravelOfficesQuery__
 *
 * To run a query within a React component, call `useLoadCurrentTravelOfficesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCurrentTravelOfficesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCurrentTravelOfficesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadCurrentTravelOfficesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadCurrentTravelOfficesQuery,
    LoadCurrentTravelOfficesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadCurrentTravelOfficesQuery, LoadCurrentTravelOfficesQueryVariables>(
    LoadCurrentTravelOfficesDocument,
    options
  )
}
export function useLoadCurrentTravelOfficesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadCurrentTravelOfficesQuery,
    LoadCurrentTravelOfficesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadCurrentTravelOfficesQuery, LoadCurrentTravelOfficesQueryVariables>(
    LoadCurrentTravelOfficesDocument,
    options
  )
}
export function useLoadCurrentTravelOfficesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LoadCurrentTravelOfficesQuery,
    LoadCurrentTravelOfficesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LoadCurrentTravelOfficesQuery,
    LoadCurrentTravelOfficesQueryVariables
  >(LoadCurrentTravelOfficesDocument, options)
}
export type LoadCurrentTravelOfficesQueryHookResult = ReturnType<
  typeof useLoadCurrentTravelOfficesQuery
>
export type LoadCurrentTravelOfficesLazyQueryHookResult = ReturnType<
  typeof useLoadCurrentTravelOfficesLazyQuery
>
export type LoadCurrentTravelOfficesSuspenseQueryHookResult = ReturnType<
  typeof useLoadCurrentTravelOfficesSuspenseQuery
>
export type LoadCurrentTravelOfficesQueryResult = Apollo.QueryResult<
  LoadCurrentTravelOfficesQuery,
  LoadCurrentTravelOfficesQueryVariables
>
export const UpdateTravelOfficeDocument = gql`
  mutation UpdateTravelOffice($id: UUID!, $active: Boolean!) {
    updateTravelOffice(id: $id, active: $active)
  }
`
export type UpdateTravelOfficeMutationFn = Apollo.MutationFunction<
  UpdateTravelOfficeMutation,
  UpdateTravelOfficeMutationVariables
>

/**
 * __useUpdateTravelOfficeMutation__
 *
 * To run a mutation, you first call `useUpdateTravelOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTravelOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTravelOfficeMutation, { data, loading, error }] = useUpdateTravelOfficeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdateTravelOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTravelOfficeMutation,
    UpdateTravelOfficeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTravelOfficeMutation, UpdateTravelOfficeMutationVariables>(
    UpdateTravelOfficeDocument,
    options
  )
}
export type UpdateTravelOfficeMutationHookResult = ReturnType<typeof useUpdateTravelOfficeMutation>
export type UpdateTravelOfficeMutationResult = Apollo.MutationResult<UpdateTravelOfficeMutation>
export type UpdateTravelOfficeMutationOptions = Apollo.BaseMutationOptions<
  UpdateTravelOfficeMutation,
  UpdateTravelOfficeMutationVariables
>
