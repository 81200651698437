import { LazyQueryExecFunction } from '@apollo/client'
import { groupBy } from 'lodash'
import { DateTime } from 'luxon'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  NotificationsQuery,
  useNotificationsLazyQuery,
  useUpdateNotificationsStateMutation,
} from '~api/notifications-gql.generated'
import {
  Exact,
  FlightSegmentChangeType,
  NotificationType,
  NotificationUpdateType,
} from '~api/types.generated'
import { useTrackEvent } from '~hooks/useTrackEvent'

export enum NotificationPanelTabs {
  Active,
  Cancelled,
  CheaperOffers,
  Dismissed,
}

interface INotificationsContext {
  fetchNotifications: LazyQueryExecFunction<
    NotificationsQuery,
    Exact<{
      [key: string]: never
    }>
  >
  notificationPanelOpen: boolean
  setNotificationPanelOpen: Dispatch<SetStateAction<boolean>>
  hasUnreadNotifications: boolean
  setHasUnreadNotifications: Dispatch<SetStateAction<boolean>>
  notificationsLoading: boolean
  notifications: NotificationsQuery['notifications']
  filteredNotifications: NotificationsQuery['notifications']
  filteredDismissedNotifications: NotificationsQuery['notifications']
  dismissedNotifications: NotificationsQuery['notifications']
  notificationsCount: number
  dismissedNotificationsCount: number
  changedSegmentsNotificationsCount: number
  cheaperOffersNotificationsCount: number
  dismissNotifications: (
    notifications: { id: string; createdAt: string }[],
    type: NotificationType,
    restore?: boolean
  ) => Promise<void>
  markNotificationsAsRead: (notificationIds: string[]) => Promise<void>
  currentTab: NotificationPanelTabs
  setCurrentTab: Dispatch<SetStateAction<NotificationPanelTabs>>
  selectedBucketId: string | null
  setSelectedBucketId: (bucketId: string | null) => void
  resetNotificationsContext: () => void
  getChangedSegmentsNotificationsForBooking: (
    bookingId: string
  ) => NotificationsQuery['notifications'][number]['changedSegmentsNotifications']
}

const initialContext: INotificationsContext = {
  fetchNotifications: () => null as any,
  notificationPanelOpen: false,
  setNotificationPanelOpen: () => undefined,
  hasUnreadNotifications: false,
  setHasUnreadNotifications: () => undefined,
  notificationsLoading: false,
  notifications: [],
  filteredNotifications: [],
  filteredDismissedNotifications: [],
  dismissedNotifications: [],
  notificationsCount: 0,
  dismissedNotificationsCount: 0,
  changedSegmentsNotificationsCount: 0,
  cheaperOffersNotificationsCount: 0,
  dismissNotifications: async () => undefined,
  markNotificationsAsRead: async () => undefined,
  currentTab: NotificationPanelTabs.Active,
  setCurrentTab: () => undefined,
  setSelectedBucketId: () => undefined,
  selectedBucketId: null,
  resetNotificationsContext: () => undefined,
  getChangedSegmentsNotificationsForBooking: () => [],
}

export const NotificationsContext = createContext<INotificationsContext>(initialContext)

/**
 * Returns the number of notifications in a bucket, considering that some types of notifications (cheaper offers)
 * need to be grouped
 */
const getNotificationsCount = (notificationsForBuckets: NotificationsQuery['notifications']) => {
  const counts = notificationsForBuckets.reduce((acc, curr) => {
    // Count cheaper offer notifications
    const cheaperOffersCount = curr.cheaperOfferNotifications.length

    // Count all cancelled/removed segment notifications
    const changedSegmentsCount = curr.changedSegmentsNotifications
      // TODO: Temporary filter to only show cancelled changes
      .filter(
        ({ change }) =>
          change.changeType === FlightSegmentChangeType.Cancelled ||
          change.changeType === FlightSegmentChangeType.Removed
      )
      .filter(
        (notification) => !curr.bucketId || notification.change.bucketId === curr.bucketId
      ).length

    // Each notification should be counted individually
    return acc + cheaperOffersCount + changedSegmentsCount
  }, 0)

  return counts
}

export default function NotificationsProvider({ children }: { children: React.ReactNode }) {
  const [notificationPanelOpen, setNotificationPanelOpen] = useState(false)
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false)
  const [selectedBucketId, setSelectedBucketId] = useState<string | null>(null)
  const [notifications, setNotifications] = useState<NotificationsQuery['notifications']>([])
  const [filteredNotifications, setFilteredNotifications] = useState<
    NotificationsQuery['notifications']
  >([])
  const [filteredDismissedNotifications, setFilteredDismissedNotifications] = useState<
    NotificationsQuery['notifications']
  >([])
  const [dismissedNotifications, setDismissedNotifications] = useState<
    NotificationsQuery['notifications']
  >([])
  const [currentTab, setCurrentTab] = useState<NotificationPanelTabs>(NotificationPanelTabs.Active)
  const trackEvent = useTrackEvent()

  const notificationsCount = useMemo(
    () => getNotificationsCount(filteredNotifications),
    [filteredNotifications]
  )
  const dismissedNotificationsCount = useMemo(
    () => getNotificationsCount(filteredDismissedNotifications),
    [filteredDismissedNotifications]
  )

  // TODO: temporarily filtering all other notifications to only show cancelled ones
  const changedSegmentsNotificationsCount = useMemo(
    () =>
      filteredNotifications.flatMap((not) =>
        not.changedSegmentsNotifications.filter(
          ({ change }) =>
            change.changeType === FlightSegmentChangeType.Cancelled ||
            change.changeType === FlightSegmentChangeType.Removed
        )
      ).length,
    [filteredNotifications]
  )

  const cheaperOffersNotificationsCount = useMemo(
    () => filteredNotifications.filter((not) => not.cheaperOfferNotifications.length > 0).length,
    [filteredNotifications]
  )

  // Function to reset the context to initial state
  const resetNotificationsContext = useMemo(() => {
    return () => {
      setNotificationPanelOpen(false)
      setHasUnreadNotifications(false)
      setSelectedBucketId(null)
      setNotifications([])
      setFilteredNotifications([])
      setFilteredDismissedNotifications([])
      setDismissedNotifications([])
      setCurrentTab(NotificationPanelTabs.Active)
    }
  }, [])

  // Sets filtered notifications and counts on initial load and whenever a filter is applied
  useEffect(() => {
    let filteredNotificationsTmp = notifications
    let filteredDismissedNotificationsTmp = dismissedNotifications

    if (selectedBucketId) {
      // Filter notifications where either:
      // 1. The main notification bucketId matches OR
      // 2. Any changedSegmentsNotifications has a matching bucketId
      filteredNotificationsTmp = notifications.filter((not) => {
        const changedSegmentsBucketMatch = not.changedSegmentsNotifications.find(
          (notification) => notification.change.bucketId === selectedBucketId
        )
        return changedSegmentsBucketMatch
      })

      filteredDismissedNotificationsTmp = dismissedNotifications.filter((not) => {
        const mainBucketMatch = not.bucketId === selectedBucketId
        const changedSegmentsBucketMatch = not.changedSegmentsNotifications.some(
          (notification) => notification.change.bucketId === selectedBucketId
        )
        return mainBucketMatch || changedSegmentsBucketMatch
      })
    }

    setFilteredNotifications(filteredNotificationsTmp)
    setFilteredDismissedNotifications(filteredDismissedNotificationsTmp)
  }, [selectedBucketId, notifications, dismissedNotifications])

  // Sort vessel notifications by soonest vessel ETA
  const sortSegmentChangesNotificationsByVessel = (
    changedSegmentNotifications: Record<string, NotificationsQuery['notifications'][number]>
  ) => {
    return Object.fromEntries(
      Object.entries(changedSegmentNotifications).sort(
        ([, a], [, b]) =>
          new Date(a.vesselSchedule.ETA).getTime() - new Date(b.vesselSchedule.ETA).getTime()
      )
    )
  }

  // Sort changed segment notifications within vessels by soonest departure date
  const sortSegmentChangesNotificationsByFlight = (
    changedSegmentNotifications: Record<string, NotificationsQuery['notifications'][number]>
  ) => {
    let sortedNotifications = changedSegmentNotifications
    for (const [key, notification] of Object.entries(changedSegmentNotifications)) {
      const sorted = notification.changedSegmentsNotifications.sort(
        (a, b) =>
          new Date(a?.change?.departure?.date ?? '9999-12-12').getTime() -
          new Date(b?.change?.departure?.date ?? '9999-12-12').getTime()
      )
      sortedNotifications[key].changedSegmentsNotifications = sorted
    }
    return sortedNotifications
  }

  const handleNotificationsDataUpdate = (
    notificationsData: NotificationsQuery['notifications']
  ) => {
    let hasUnreadNotificationsTemp = false
    let dismissedNotificationsTemp: Record<string, NotificationsQuery['notifications'][number]> = {}
    let notificationsTemp: Record<string, NotificationsQuery['notifications'][number]> = {}

    // Group notifications by dismissed and undismissed, for both types of notifications

    for (const notification of notificationsData) {
      for (const changedSegmentNotification of notification.changedSegmentsNotifications) {
        if (changedSegmentNotification?.notification.dismissed) {
          // Create group of dismissed notifications by bucketId
          if (!dismissedNotificationsTemp[notification.bucketId]) {
            dismissedNotificationsTemp[notification.bucketId] = {
              bucketId: notification.bucketId,
              vessel: notification.vessel,
              vesselSchedule: notification.vesselSchedule,
              changedSegmentsNotifications: [changedSegmentNotification],
              cheaperOfferNotifications: [],
            }
          } else {
            dismissedNotificationsTemp[notification.bucketId].changedSegmentsNotifications.push(
              changedSegmentNotification
            )
          }
        } else {
          // Create group of non dismissed notifications by bucketId
          // We disable the eslint rule here because it is much simpler to read this way
          // eslint-disable-next-line no-lonely-if
          if (!notificationsTemp[notification.bucketId]) {
            notificationsTemp[notification.bucketId] = {
              bucketId: notification.bucketId,
              vessel: notification.vessel,
              vesselSchedule: notification.vesselSchedule,
              changedSegmentsNotifications: [changedSegmentNotification],
              cheaperOfferNotifications: [],
            }
          } else {
            notificationsTemp[notification.bucketId].changedSegmentsNotifications.push(
              changedSegmentNotification
            )
          }
        }

        // TODO: Temporary filter to only show cancelled changes
        if (
          !changedSegmentNotification?.notification.read &&
          (changedSegmentNotification.change.changeType === FlightSegmentChangeType.Cancelled ||
            changedSegmentNotification.change.changeType === FlightSegmentChangeType.Removed)
        ) {
          hasUnreadNotificationsTemp = true
        }
      }

      for (const cheaperOfferNotification of notification.cheaperOfferNotifications) {
        if (cheaperOfferNotification.notification.dismissed) {
          // Create group of dismissed notifications by bucketId
          if (!dismissedNotificationsTemp[notification.bucketId]) {
            dismissedNotificationsTemp[notification.bucketId] = {
              bucketId: notification.bucketId,
              vessel: notification.vessel,
              vesselSchedule: notification.vesselSchedule,
              changedSegmentsNotifications: [],
              cheaperOfferNotifications: [cheaperOfferNotification],
            }
          } else {
            dismissedNotificationsTemp[notification.bucketId].cheaperOfferNotifications.push(
              cheaperOfferNotification
            )
          }
        } else {
          // Create group of non dismissed notifications by bucketId
          // We disable the eslint rule here because it is much simpler to read this way
          // eslint-disable-next-line no-lonely-if
          if (!notificationsTemp[notification.bucketId]) {
            notificationsTemp[notification.bucketId] = {
              bucketId: notification.bucketId,
              vessel: notification.vessel,
              vesselSchedule: notification.vesselSchedule,
              changedSegmentsNotifications: [],
              cheaperOfferNotifications: [cheaperOfferNotification],
            }
          } else {
            notificationsTemp[notification.bucketId].cheaperOfferNotifications.push(
              cheaperOfferNotification
            )
          }
        }
        if (!cheaperOfferNotification.notification.read) {
          hasUnreadNotificationsTemp = true
        }
      }
    }

    setHasUnreadNotifications(hasUnreadNotificationsTemp)

    dismissedNotificationsTemp = sortSegmentChangesNotificationsByVessel(dismissedNotificationsTemp)
    notificationsTemp = sortSegmentChangesNotificationsByVessel(notificationsTemp)

    dismissedNotificationsTemp = sortSegmentChangesNotificationsByFlight(dismissedNotificationsTemp)
    notificationsTemp = sortSegmentChangesNotificationsByFlight(notificationsTemp)

    setDismissedNotifications(Object.values(dismissedNotificationsTemp))
    setNotifications(Object.values(notificationsTemp))
  }

  const [fetchNotifications, { loading: notificationsLoading }] = useNotificationsLazyQuery({
    onCompleted(data) {
      handleNotificationsDataUpdate(data.notifications)
    },
    notifyOnNetworkStatusChange: true,
    // We are already caching the notifications in context, so this hook should always fetch from the network
    fetchPolicy: 'network-only',
  })

  const [updateNotificationsState] = useUpdateNotificationsStateMutation({
    onCompleted(data) {
      handleNotificationsDataUpdate(data.updateNotificationsState)
    },
  })

  const dismissNotifications = useCallback(
    // Dismiss notifications, or restore them if restore is true
    async (
      notificationsToDismiss: { id: string; createdAt: string }[],
      type: NotificationType,
      restore?: boolean
    ) => {
      await updateNotificationsState({
        variables: {
          notificationsToUpdate: notificationsToDismiss.map(({ id }) => ({
            id,
            type,
            value: !restore,
          })),
          notificationsUpdateType: NotificationUpdateType.Dismiss,
        },
      })

      notificationsToDismiss.forEach((notification) => {
        trackEvent({
          event: restore ? 'Restored notification' : 'Dismissed notification',
          metadata: {
            mixpanelProperties: {
              createdAt: notification.createdAt,
              age: DateTime.fromISO(notification.createdAt).toRelative(),
              type,
            },
          },
        })
      })
    },
    [trackEvent, updateNotificationsState]
  )

  const markNotificationsAsRead = useCallback(
    async (notificationIds: string[]) => {
      await updateNotificationsState({
        variables: {
          notificationsToUpdate: notificationIds.map((notificationId) => ({
            id: notificationId,
            type: NotificationType.ChangedSegments,
            value: true,
          })),
          notificationsUpdateType: NotificationUpdateType.Read,
        },
      })
    },
    [updateNotificationsState]
  )

  const getChangedSegmentsNotificationsForBooking = useCallback(
    (
      bookingId: string
    ): NotificationsQuery['notifications'][number]['changedSegmentsNotifications'] => {
      const allNotifications = [...filteredNotifications, ...filteredDismissedNotifications]

      const allChangedSegments = allNotifications.flatMap(
        (notification) => notification.changedSegmentsNotifications
      )

      const notificationsByBookingId = groupBy(
        allChangedSegments,
        (notification) => notification.change.flightBookingId
      )

      return notificationsByBookingId[bookingId] || []
    },
    [filteredNotifications, filteredDismissedNotifications]
  )

  const context = useMemo(
    () => ({
      notificationPanelOpen,
      setNotificationPanelOpen,
      hasUnreadNotifications,
      setHasUnreadNotifications,
      fetchNotifications,
      notificationsLoading,
      notifications,
      filteredNotifications,
      dismissedNotifications,
      filteredDismissedNotifications,
      notificationsCount,
      dismissedNotificationsCount,
      changedSegmentsNotificationsCount,
      cheaperOffersNotificationsCount,
      dismissNotifications,
      currentTab,
      setCurrentTab,
      markNotificationsAsRead,
      setSelectedBucketId,
      selectedBucketId,
      getChangedSegmentsNotificationsForBooking,
      resetNotificationsContext,
    }),
    [
      notificationPanelOpen,
      hasUnreadNotifications,
      fetchNotifications,
      notificationsLoading,
      notifications,
      filteredNotifications,
      dismissedNotifications,
      filteredDismissedNotifications,
      notificationsCount,
      dismissedNotificationsCount,
      changedSegmentsNotificationsCount,
      cheaperOffersNotificationsCount,
      dismissNotifications,
      currentTab,
      markNotificationsAsRead,
      selectedBucketId,
      resetNotificationsContext,
      getChangedSegmentsNotificationsForBooking,
    ]
  )

  return <NotificationsContext.Provider value={context}>{children}</NotificationsContext.Provider>
}
