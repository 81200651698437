import { useLoadCurrencyRatesQuery } from 'api/info.generated'
import { useCallback, useContext, useMemo } from 'react'
import { UserContext } from '~context/UserContext'

export default function useCurrencyConvert() {
  const { isAuthenticated, user } = useContext(UserContext)
  const { data: ratesData } = useLoadCurrencyRatesQuery({
    skip: !isAuthenticated,
  })
  const tenantCurrency = user.tenant.preferences.preferredCurrency

  // holds rates related to USD as our base currency
  const rates = useMemo(
    (): { [currency: string]: number } =>
      ratesData?.currencyRates ?? {
        EUR: 1,
        USD: 1,
        PHP: 56,
      },
    [ratesData?.currencyRates]
  )

  const convert = useCallback(
    (amount: number, currency: string, targetCurrency: string) => {
      if (currency === targetCurrency) {
        return amount
      }

      // All conversions are relative to USD (so USD conversion rate === 1)
      return (amount / rates[currency]) * rates[targetCurrency]
    },
    [rates]
  )

  const convertToTenantCurrency = useCallback(
    (amount: number, currency: string) => {
      if (!tenantCurrency) {
        return amount
      }
      return convert(amount, currency, tenantCurrency)
    },
    [convert, tenantCurrency]
  )

  return {
    convert,
    convertToTenantCurrency,
    tenantCurrency,
    rates,
  }
}
