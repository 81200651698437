import clsx from 'clsx'
import { ReactNode } from 'react'
import ErrorIcon from '../Icon/ErrorIcon'
import InfoIcon from '../Icon/InfoIcon'
import Tooltip from './Tooltip'

interface Props {
  content: ReactNode
  placement?: 'top' | 'bottom' | 'left' | 'right'
  variant?: 'info' | 'warning' | 'error'
  outline?: boolean
  contentBackground?: 'light' | 'dark'
}

const variants = {
  outline: {
    warning: 'fill-none stroke-yellow-500 stroke-1',
    info: 'fill-none stroke-gray-500 stroke-1',
    error: 'fill-none stroke-red-600 stroke-1',
  },
  filled: {
    warning: 'fill-yellow-500',
    info: 'fill-gray-400',
    error: 'fill-red-600',
  },
}

export default function TooltipWarning({
  content,
  placement = 'bottom',
  variant = 'warning',
  outline = false,
  contentBackground = 'dark',
}: Props) {
  const className = clsx('w-5 h-5', variants[outline ? 'outline' : 'filled'][variant])

  return (
    <Tooltip placement={placement} content={content} background={contentBackground}>
      {variant === 'error' ? (
        <ErrorIcon className={className} />
      ) : (
        <InfoIcon className={className} />
      )}
    </Tooltip>
  )
}
