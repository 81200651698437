export default function OfferVerificationFailureImage({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="254"
      height="105"
      viewBox="0 0 254 105"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_845_4331)">
        <mask
          id="mask0_845_4331"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="254"
          height="105"
        >
          <path d="M254 0H0V105H254V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_845_4331)">
          <path
            d="M144.851 92.335L90.574 100.913C88.7321 101.204 87.0049 99.9656 86.7165 98.1473L81.1339 62.9788C80.8454 61.1605 82.1046 59.4504 83.9464 59.1593L138.223 50.5816C140.066 50.2901 141.792 51.5287 142.081 53.347L147.663 88.5152C147.952 90.3338 146.693 92.0435 144.851 92.335Z"
            fill="#737373"
          />
          <path
            d="M151.518 91.2809L169.869 88.3807C171.709 88.09 172.967 86.3803 172.679 84.5616L167.097 49.3931C166.808 47.5748 165.082 46.3365 163.241 46.6273L144.891 49.5275C143.05 49.8182 141.792 51.5282 142.081 53.3466L147.663 88.515C147.952 90.3337 149.678 91.572 151.518 91.2809Z"
            fill="#737373"
          />
          <path
            d="M126.945 74.8719L87.5314 81.1009C87.3623 81.1277 87.2106 81.0549 87.1921 80.9387L86.9022 79.1135C86.884 78.9969 87.006 78.8807 87.1747 78.8542L126.588 72.6256C126.757 72.5991 126.909 72.6712 126.927 72.7878L127.217 74.613C127.235 74.7292 127.114 74.8451 126.945 74.8719Z"
            fill="#737373"
          />
          <path
            d="M103.171 83.6954L88.3132 86.0434C88.1456 86.0699 87.995 85.9967 87.9765 85.8805L87.6869 84.0553C87.6684 83.9391 87.7893 83.8236 87.9565 83.7968L102.814 81.4488C102.982 81.4223 103.133 81.4951 103.151 81.6113L103.441 83.4365C103.459 83.5528 103.338 83.669 103.171 83.6954Z"
            fill="#737373"
          />
          <path
            d="M115.326 86.8406L89.0985 90.9855C88.9305 91.012 88.7799 90.9392 88.7614 90.823L88.4711 88.9978C88.4526 88.8815 88.5738 88.7657 88.7414 88.7393L114.969 84.5943C115.137 84.5679 115.288 84.6406 115.307 84.7569L115.596 86.582C115.614 86.6983 115.494 86.8141 115.326 86.8406Z"
            fill="#737373"
          />
          <path
            d="M122.93 90.7052L89.8853 95.9277C89.7159 95.9545 89.5638 95.8817 89.5453 95.7655L89.2558 93.9403C89.2373 93.8237 89.3595 93.7078 89.5286 93.681L122.573 88.4586C122.743 88.4321 122.894 88.5045 122.913 88.6208L123.202 90.446C123.221 90.5622 123.099 90.6784 122.93 90.7052Z"
            fill="#737373"
          />
          <path
            d="M163.241 46.6276L144.891 49.5278C143.05 49.8185 141.791 51.5209 142.078 53.3298L143.215 60.4926L168.23 56.5392L167.094 49.3764C166.806 47.5675 165.082 46.3365 163.241 46.6276Z"
            fill="#525252"
          />
          <path
            d="M138.223 50.5817L83.9462 59.1595C82.1043 59.4506 80.8438 61.153 81.1312 62.9619L82.268 70.1255L143.215 60.493L142.078 53.3302C141.791 51.5213 140.065 50.2906 138.223 50.5817Z"
            fill="#525252"
          />
          <path
            d="M142.551 55.0148L142.141 55.0804C142.006 55.1014 141.882 55.0358 141.866 54.933L141.709 53.9424C141.693 53.8399 141.79 53.74 141.925 53.7183L142.335 53.6535C142.471 53.6321 142.593 53.6976 142.609 53.8001L142.767 54.7914C142.783 54.8935 142.687 54.9935 142.551 55.0148Z"
            fill="#737373"
          />
          <path
            d="M147.888 88.617L147.477 88.6826C147.342 88.7036 147.219 88.638 147.203 88.5352L147.046 87.5446C147.029 87.4421 147.126 87.3425 147.261 87.3208L147.671 87.256C147.807 87.2346 147.929 87.2998 147.946 87.4026L148.103 88.3936C148.119 88.4961 148.023 88.5957 147.888 88.617Z"
            fill="#737373"
          />
          <path
            d="M147.306 84.9681L146.895 85.0329C146.76 85.0546 146.638 84.9931 146.623 84.8964L146.279 82.7309C146.263 82.6338 146.361 82.5379 146.496 82.5162L146.906 82.4517C147.042 82.43 147.164 82.4915 147.179 82.5882L147.523 84.7537C147.538 84.8508 147.442 84.9464 147.306 84.9681ZM146.507 79.935L146.096 79.9998C145.961 80.0215 145.839 79.9596 145.824 79.8626L145.48 77.697C145.464 77.6 145.562 77.504 145.697 77.4823L146.107 77.4179C146.243 77.3965 146.365 77.4577 146.38 77.5544L146.724 79.7206C146.739 79.8173 146.643 79.9136 146.507 79.935ZM145.707 74.9011L145.298 74.9659C145.162 74.9869 145.04 74.9261 145.025 74.8294L144.681 72.6639C144.665 72.5669 144.763 72.4705 144.898 72.4495L145.308 72.3847C145.444 72.3634 145.566 72.4238 145.582 72.5212L145.925 74.6868C145.94 74.7834 145.843 74.8794 145.707 74.9011ZM144.909 69.8676L144.498 69.9321C144.363 69.9538 144.241 69.8926 144.226 69.7952L143.882 67.6293C143.866 67.5323 143.964 67.4367 144.099 67.4153L144.509 67.3509C144.645 67.3292 144.767 67.3904 144.782 67.487L145.126 69.6533C145.141 69.75 145.044 69.8459 144.909 69.8676ZM144.11 64.8334L143.699 64.8986C143.564 64.92 143.442 64.8588 143.427 64.7617L143.083 62.5962C143.067 62.4992 143.165 62.4032 143.3 62.3819L143.71 62.317C143.846 62.2957 143.968 62.3572 143.983 62.4539L144.327 64.6194C144.343 64.7161 144.245 64.8124 144.11 64.8334ZM143.311 59.8003L142.9 59.8651C142.765 59.8861 142.643 59.8249 142.627 59.7283L142.284 57.5631C142.268 57.466 142.366 57.3697 142.501 57.3487L142.911 57.2836C143.047 57.2622 143.169 57.323 143.184 57.4204L143.528 59.586C143.544 59.6826 143.446 59.7786 143.311 59.8003Z"
            fill="#737373"
          />
          <path
            d="M159.877 82.5161L161.438 81.6508L161.833 73.4463L166.125 71.0672C166.772 70.708 167.002 69.9122 166.638 69.2854C166.274 68.6587 165.457 68.4425 164.81 68.8017L160.517 71.1809L153.109 67.3027L151.548 68.1681L157.005 73.1277L152.713 75.5076L150.666 74.6459L149.496 75.295L151.811 77.5051L152.565 80.5809L153.735 79.9321L154.029 77.7727L158.321 75.3935L159.877 82.5161Z"
            fill="#525252"
          />
          <path
            d="M126.217 95.3657L87.0742 95.3125L92.2827 100.367L126.217 95.3657Z"
            fill="#737373"
          />
          <path
            d="M142.115 95.5647H87.1614C85.2963 95.5647 83.7847 94.0727 83.7847 92.2315V56.6242C83.7847 54.7835 85.2963 53.291 87.1614 53.291H142.115C143.98 53.291 145.491 54.7835 145.491 56.6242V92.2315C145.491 94.0727 143.98 95.5647 142.115 95.5647Z"
            fill="#F5F5F5"
          />
          <path
            d="M148.866 95.5647H167.444C169.308 95.5647 170.819 94.0727 170.819 92.2315V56.6242C170.819 54.7835 169.308 53.291 167.444 53.291H148.866C147.002 53.291 145.491 54.7835 145.491 56.6242V92.2315C145.491 94.0727 147.002 95.5647 148.866 95.5647Z"
            fill="#F5F5F5"
          />
          <path
            d="M127.167 75.5216H87.2622C87.0909 75.5216 86.9526 75.426 86.9526 75.3083V73.4607C86.9526 73.3427 87.0912 73.2471 87.2622 73.2471H127.167C127.338 73.2471 127.477 73.3427 127.477 73.4607V75.308C127.477 75.426 127.338 75.5216 127.167 75.5216Z"
            fill="#737373"
          />
          <path
            d="M102.303 80.5255H87.2596C87.0902 80.5255 86.9526 80.4295 86.9526 80.3119V78.4646C86.9526 78.3462 87.0902 78.251 87.2596 78.251H102.303C102.472 78.251 102.609 78.3466 102.609 78.4646V80.3119C102.609 80.4295 102.472 80.5255 102.303 80.5255Z"
            fill="#737373"
          />
          <path
            d="M113.814 85.5294H87.26C87.0902 85.5294 86.9526 85.4338 86.9526 85.3165V83.4685C86.9526 83.3505 87.0902 83.2549 87.26 83.2549H113.814C113.984 83.2549 114.122 83.3505 114.122 83.4685V85.3158C114.122 85.4335 113.984 85.5294 113.814 85.5294Z"
            fill="#737373"
          />
          <path
            d="M120.719 90.5333H87.2625C87.0912 90.5333 86.9526 90.4374 86.9526 90.3197V88.472C86.9526 88.354 87.0912 88.2588 87.2625 88.2588H120.719C120.891 88.2588 121.029 88.354 121.029 88.472V90.3197C121.029 90.4374 120.891 90.5333 120.719 90.5333Z"
            fill="#737373"
          />
          <path
            d="M167.444 53.291H148.865C147.002 53.291 145.491 54.7759 145.491 56.6072V63.8591H170.819V56.6072C170.819 54.7759 169.308 53.291 167.444 53.291Z"
            fill="#A3A3A3"
          />
          <path
            d="M142.115 53.291H87.1614C85.2963 53.291 83.7847 54.7759 83.7847 56.6072V63.8591H145.491V56.6072C145.491 54.7759 143.98 53.291 142.115 53.291Z"
            fill="#A3A3A3"
          />
          <path
            d="M145.694 58.3456H145.279C145.142 58.3456 145.031 58.2616 145.031 58.1576V57.1547C145.031 57.0512 145.142 56.9668 145.279 56.9668H145.694C145.832 56.9668 145.943 57.0512 145.943 57.1547V58.1576C145.943 58.2616 145.832 58.3456 145.694 58.3456Z"
            fill="#737373"
          />
          <path
            d="M145.697 92.367H145.282C145.145 92.367 145.034 92.2834 145.034 92.1791V91.1762C145.034 91.0723 145.145 90.9883 145.282 90.9883H145.697C145.835 90.9883 145.946 91.0723 145.946 91.1762V92.1791C145.946 92.2834 145.835 92.367 145.697 92.367Z"
            fill="#737373"
          />
          <path
            d="M145.694 88.6728H145.279C145.142 88.6728 145.031 88.5935 145.031 88.4953V86.3027C145.031 86.2049 145.142 86.1252 145.279 86.1252H145.694C145.832 86.1252 145.943 86.2049 145.943 86.3027V88.4953C145.943 88.5931 145.832 88.6728 145.694 88.6728ZM145.694 83.577H145.279C145.142 83.577 145.031 83.4973 145.031 83.3992V81.2062C145.031 81.1077 145.142 81.0284 145.279 81.0284H145.694C145.832 81.0284 145.943 81.1077 145.943 81.2062V83.3992C145.943 83.4973 145.832 83.577 145.694 83.577ZM145.694 78.4805H145.279C145.142 78.4805 145.031 78.4009 145.031 78.3027V76.1104C145.031 76.0119 145.142 75.9323 145.279 75.9323H145.694C145.832 75.9323 145.943 76.0119 145.943 76.1104V78.3027C145.943 78.4009 145.832 78.4805 145.694 78.4805ZM145.694 73.384H145.279C145.142 73.384 145.031 73.3047 145.031 73.2063V71.0136C145.031 70.9151 145.142 70.8358 145.279 70.8358H145.694C145.832 70.8358 145.943 70.9151 145.943 71.0136V73.2063C145.943 73.3047 145.832 73.384 145.694 73.384ZM145.694 68.2876H145.279C145.142 68.2876 145.031 68.2079 145.031 68.1098V65.9171C145.031 65.8193 145.142 65.7397 145.279 65.7397H145.694C145.832 65.7397 145.943 65.8193 145.943 65.9171V68.1098C145.943 68.2079 145.832 68.2876 145.694 68.2876ZM145.694 63.1914H145.279C145.142 63.1914 145.031 63.1118 145.031 63.0137V60.821C145.031 60.7228 145.142 60.6436 145.279 60.6436H145.694C145.832 60.6436 145.943 60.7228 145.943 60.821V63.0137C145.943 63.1114 145.832 63.1914 145.694 63.1914Z"
            fill="#737373"
          />
          <path
            d="M159.712 86.972L161.388 86.3612L163.065 78.3193L167.677 76.6389C168.373 76.3855 168.724 75.6353 168.463 74.9597C168.202 74.2837 167.43 73.943 166.734 74.1964L162.121 75.8761L155.411 70.8896L153.734 71.5008L158.347 77.2512L153.735 78.9312L151.848 77.761L150.59 78.219L152.53 80.764L152.792 83.9191L154.05 83.461L154.679 81.3741L159.291 79.6941L159.712 86.972Z"
            fill="#737373"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_845_4331">
          <rect width="254" height="105" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
