/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGProps } from 'react'

function OpenInNewIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path d="M21,13.5V19a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3h5.5a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H5V19H19V13.5a.5.5,0,0,1,.5-.5h1A.5.5,0,0,1,21,13.5Zm-.22-10-.27-.27A.75.75,0,0,0,20,3H13.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h4.09L9.15,13.44a.5.5,0,0,0,0,.71l.7.7a.5.5,0,0,0,.71,0L19,6.42V10.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V4A.75.75,0,0,0,20.78,3.49Z" />
    </svg>
  )
}

export default OpenInNewIcon
