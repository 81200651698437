import { isDev } from 'utils/checkEnv'
import TableCell from '~components/ui/Table/TableCell'
import TableHead from '~components/ui/Table/TableHead'
import TableRow from '~components/ui/Table/TableRow'

export default function CrewChangeTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell tagName="th">Vessel</TableCell>
        <TableCell tagName="th">Port</TableCell>
        <TableCell tagName="th">Seafarers</TableCell>
        <TableCell tagName="th">ETA</TableCell>
        <TableCell tagName="th">ETB</TableCell>
        <TableCell tagName="th">ETD</TableCell>
        {isDev() && <TableCell tagName="th">Summary</TableCell>}
        <TableCell tagName="th">Upcoming LTD</TableCell>
        <TableCell tagName="th">Status</TableCell>
        <TableCell tagName="th" shrink>
          <span className="sr-only">Actions</span>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
