import { Menu } from '@headlessui/react'
import { UserRole, VesselScheduleSource } from 'api/types.generated'
import clsx from 'clsx'
import { compact, sum } from 'lodash'
import { DateTime } from 'luxon'
import Link from 'next/link'
import { useMemo, useState } from 'react'
import { isDev } from 'utils/checkEnv'
import getCrewChangeRouteForUserRole from 'utils/getCrewChangeRouteForUserRole'
import getCurrentCrewChangeStatus from 'utils/getCurrentCrewChangeStatus'
import CrewChangeSeafarersCount from '~components/CrewChange/CrewChangeSeafarer/CrewChangeSeafarerCount/CrewChangeSeafarersCount'
import CrewChangeStatusBadge from '~components/CrewChange/CrewChangeStatusBadge'
import CancelCrewChangeModal from '~components/CrewChangeCreate/CancelCrewChangeModal'
import RestoreCrewChangeModal from '~components/CrewChangeRestore/RestoreCrewChangeModal'
import VesselSchedule from '~components/VesselSchedule/VesselSchedule'
import ActionMenu from '~components/ui/ActionMenu/ActionMenu'
import Button from '~components/ui/Button/Button'
import KebabMenuIcon from '~components/ui/Icon/KebabMenuIcon'
import TicketIcon from '~components/ui/Icon/TicketIcon'
import TableCell from '~components/ui/Table/TableCell'
import TableRow from '~components/ui/Table/TableRow'
import Tooltip from '~components/ui/Tooltip/Tooltip'
import TooltipWarning from '~components/ui/Tooltip/TooltipWarning'
import useCurrentUser from '~hooks/useCurrentUser'
import useIsAllowedCrewChangeManagement from '~hooks/useIsAllowedCrewChangeManagement'
import { CrewChangeOverViewTab } from '~pages/index'
import { formatToLocalDateTime } from '../../utils/formatDateTime'
import CrewChangeTransferVesselModal from './CrewChangeTransferVesselModal'
import { CrewChangeSummary } from './types'

export default function CrewChangesTableRow({
  crewChange,
  activeTab,
}: {
  crewChange: CrewChangeSummary
  activeTab: CrewChangeOverViewTab
}) {
  const { tenant, user } = useCurrentUser()
  const hasNoCurrentSeafarers =
    sum([
      crewChange.numOnsigners,
      crewChange.numOffsigners,
      crewChange.totalNumSeafarersIncludingPromotions,
    ]) === 0
  const containsActiveFlightBookings =
    sum([
      crewChange.openFlightBookings,
      crewChange.ticketedFlightBookings,
      crewChange.bookedFlightBookings,
    ]) > 0

  const vesselSchedule = {
    ETA: crewChange.ETA,
    ETB: crewChange.ETB,
    ETD: crewChange.ETD,
    seaport: {
      timezoneOlson: crewChange.portTimezoneOlson,
    },
  }

  const roleRoute = useMemo<string>(() => {
    return getCrewChangeRouteForUserRole(user.role, crewChange.bucketId)
  }, [user.role, crewChange.bucketId])

  const crewChangeStatus = useMemo(() => {
    return getCurrentCrewChangeStatus(crewChange)
  }, [crewChange])

  const [showVesselTransferModal, setShowVesselTransferModal] = useState(false)

  const [showCancelCrewChangeModal, setShowCancelCrewChangeModal] = useState(false)
  const [showRestoreCrewChangeModal, setShowRestoreCrewChangeModal] = useState(false)

  const isAllowedCrewChangeManagement = useIsAllowedCrewChangeManagement()

  /**
   * hiding action menu for L&B tenant temporary solution (6021)
   */
  const showActionMenu = !(
    tenant.name === 'Leonhardt & Blumberg' || tenant.dataSourceSystem === 'CrewStar'
  )

  const hasError = crewChange.isScheduleCancelled || hasNoCurrentSeafarers || crewChange.archived

  const isInCancelledTab = activeTab === CrewChangeOverViewTab.Cancelled

  /**
   * If any of these conditions are false, canCancel will be false.
   */
  const canCancel = !hasError && !isInCancelledTab

  /**
   * if the associated vessel Schedule is not cancelled,
   * and crew change is in the cancelled tab,
   * canRestore will be true.
   */
  const canRestore = isInCancelledTab && !crewChange.isScheduleCancelled

  return (
    <TableRow className="group hover:bg-gray-50">
      <TableCell className="w-1/4">
        <div className="flex items-center">
          {hasError && (
            <>
              {hasNoCurrentSeafarers && (
                <TooltipWarning
                  content="All seafarers for this crew change have been removed"
                  variant="error"
                  placement="right"
                />
              )}
              {crewChange.isScheduleCancelled && (
                <TooltipWarning
                  content="The schedule for this crew change has been cancelled on CFM"
                  variant="error"
                  placement="right"
                />
              )}
              {containsActiveFlightBookings && (
                <Tooltip placement="right" content="This crew change contains active bookings">
                  <TicketIcon className="w-5 h-5 fill-yellow-500" />
                </Tooltip>
              )}
              {crewChange.archived && (
                <TooltipWarning
                  content="The crew change has been archived"
                  variant="warning"
                  placement="right"
                />
              )}
            </>
          )}
          {user.role === UserRole.TillaBackoffice &&
            crewChange.vesselScheduleSource === VesselScheduleSource.Arbitrary && (
              <TooltipWarning
                placement="right"
                content="This crew change is linked to an arbitrary schedule"
              />
            )}
          <span className="font-semibold text-gray-900 mx-2">{crewChange.vesselName}</span>
          {showActionMenu && (
            <ActionMenu
              customToggleButton={
                <Menu.Button className="py-1 px-3 h-4 flex items-center rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 opacity-0 group-hover:opacity-100 focus-within:opacity-100 text-gray-800 bg-white border border-gray-300 shadow-sm">
                  &bull;&bull;&bull;
                </Menu.Button>
              }
              items={[
                {
                  data: [
                    {
                      children: `Transfer ${crewChange.vesselName}`,
                      onClick: () => setShowVesselTransferModal((prevState) => !prevState),
                    },
                  ],
                },
              ]}
              placement="right"
            />
          )}

          {showVesselTransferModal && (
            <CrewChangeTransferVesselModal
              crewChange={crewChange}
              onClose={() => setShowVesselTransferModal(false)}
            />
          )}
        </div>
      </TableCell>
      <TableCell>
        <span className="font-semibold text-gray-900">{crewChange.portName}</span>
      </TableCell>
      <TableCell>
        <CrewChangeSeafarersCount
          embarkingCount={crewChange.numOnsigners}
          disembarkingCount={crewChange.numOffsigners}
        />
      </TableCell>
      <TableCell>
        <VesselSchedule vesselSchedule={vesselSchedule} timeType="ETA" />
      </TableCell>
      <TableCell>
        <VesselSchedule vesselSchedule={vesselSchedule} timeType="ETB" />
      </TableCell>
      <TableCell>
        <VesselSchedule vesselSchedule={vesselSchedule} timeType="ETD" />
      </TableCell>
      {isDev() && (
        <TableCell>
          <div className="flex justify-between text-yellow-500 font-semibold">
            <div>Open</div>
            <div>{crewChange.openFlightBookings}</div>
          </div>
          <div className="flex justify-between text-blue-600 font-semibold">
            <div>Booked</div>
            <div>{crewChange.bookedFlightBookings}</div>
          </div>
          <div className="flex justify-between text-green-600 font-semibold">
            <div>Ticketed</div>
            <div>{crewChange.ticketedFlightBookings}</div>
          </div>
        </TableCell>
      )}
      <TableCell>{formatToLocalDateTime(crewChange.flightBookingsLTD)}</TableCell>
      <TableCell>
        <CrewChangeStatusBadge status={crewChangeStatus} />
      </TableCell>
      <TableCell shrink>
        <div className="opacity-0 group-hover:opacity-100 focus-within:opacity-100 flex gap-2 items-center">
          <Link href={roleRoute} passHref>
            <Button
              passEvent
              variant="outline"
              clickTrackingEvent={{
                event: 'View CrewChange click',
                metadata: {
                  mixpanelProperties: {
                    url: `${window.location.origin}${roleRoute}`,
                    ETA: DateTime.fromISO(vesselSchedule.ETA).valueOf(),
                    ETB: DateTime.fromISO(vesselSchedule.ETB).valueOf(),
                    ETD: DateTime.fromISO(vesselSchedule.ETD).valueOf(),
                    vessel: crewChange.vesselName,
                  },
                },
              }}
            >
              View
            </Button>
          </Link>
          {isAllowedCrewChangeManagement && (
            <ActionMenu
              toggle={<KebabMenuIcon className="w-6 h-6 text-neutral-500" />}
              items={[
                {
                  data: compact([
                    {
                      children: (
                        <span className="text-neutral-900 font-semibold leading-4 cursor-pointer">
                          Edit crew change
                        </span>
                      ),
                      href: `${roleRoute}/edit`,
                    },
                    canCancel
                      ? {
                          children: (
                            <span className="text-red-500 font-semibold leading-4 cursor-pointer">
                              Cancel crew change
                            </span>
                          ),
                          onClick: () => setShowCancelCrewChangeModal(true),
                        }
                      : null,
                    canRestore
                      ? {
                          children: (
                            <span
                              className={clsx({
                                'text-blue-500 font-semibold leading-4 cursor-pointer': canRestore,
                                'text-gray-400 cursor-not-allowed': !canRestore,
                              })}
                            >
                              Restore crew change
                            </span>
                          ),
                          onClick: () => (canRestore ? setShowRestoreCrewChangeModal(true) : null),
                        }
                      : null,
                  ]),
                },
              ]}
            />
          )}
        </div>
      </TableCell>
      {showCancelCrewChangeModal && (
        <CancelCrewChangeModal
          onCancel={() => setShowCancelCrewChangeModal(false)}
          isOpen={showCancelCrewChangeModal}
          crewChange={crewChange}
        />
      )}

      {showRestoreCrewChangeModal && (
        <RestoreCrewChangeModal
          onCancel={() => setShowRestoreCrewChangeModal(false)}
          isOpen={showRestoreCrewChangeModal}
          crewChange={crewChange}
        />
      )}
    </TableRow>
  )
}
