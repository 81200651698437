import React, { ReactNode, useEffect, useState } from 'react'

// This is a helper component that makes sure everything it wraps
// won't go through Next.js static or server-side generator
export default function ClientOnly({ children }: { children: ReactNode }) {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return <>{children}</>
}
