import { useMemo } from 'react'
import { formatToLocalDateTime } from 'utils/formatDateTime'
import { NotificationsQuery } from '~api/notifications-gql.generated'
import BoatIcon from '~components/ui/Icon/BoatIcon'

interface VesselHeaderProps {
  notification: NotificationsQuery['notifications'][number]
}

export default function VesselHeader(props: VesselHeaderProps) {
  const { notification } = props

  const formattedETA = useMemo(() => {
    const timezone = notification.vesselSchedule.seaport.timezoneOlson
    if (!notification.vesselSchedule.ETA || !timezone) {
      return ''
    }

    return formatToLocalDateTime(notification.vesselSchedule.ETA, timezone)
  }, [notification.vesselSchedule.ETA])

  return (
    <div className="flex flex-row items-center justify-between bg-gray-100 w-full p-2">
      <div className="flex flex-row items-center px-1">
        <BoatIcon height={18} className="mr-2" />
        <p className="font-bold text-sm">
          {notification.vessel.charterName} @ {notification.vesselSchedule.portName}
        </p>
      </div>
      <div className="font-light text-sm">ETA: {formattedETA}</div>
    </div>
  )
}
