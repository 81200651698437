import { DateTime } from 'luxon'
import { useMemo } from 'react'

export default function useRelativeDateTime(isoDateTime: string) {
  const relativeDateTime = useMemo(() => {
    const parsed = DateTime.fromISO(isoDateTime)

    if (Math.abs(parsed.diffNow().toMillis()) < 120000) {
      return 'Just now'
    }

    return parsed.toRelative()
  }, [isoDateTime])

  return relativeDateTime
}
