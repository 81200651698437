import { Dialog } from '@headlessui/react'
import { useState } from 'react'
import VesselFilter from './VesselFilter/VesselFilter'
import VesselFilterToggle from './VesselFilter/VesselFilterToggle'

export default function NotificationsPanelHeader() {
  const [isVesselFilterOpen, setIsVesselFilterOpen] = useState(false)

  return (
    <div className="flex flex-col gap-2 px-4 sm:px-6">
      <div className="flex flex-row justify-between items-center">
        <Dialog.Title className="text-lg font-semibold leading-6 text-gray-900">
          Notifications
        </Dialog.Title>
        <VesselFilterToggle
          isOpen={isVesselFilterOpen}
          onToggleOpen={() => {
            setIsVesselFilterOpen((prev) => !prev)
          }}
        />
      </div>
      {isVesselFilterOpen && <VesselFilter />}
    </div>
  )
}
