import { SVGProps } from 'react'

const EmptyNotificationsLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={209} height={264} fill="none" {...props}>
    <rect width={208} height={59} x={0.5} y={0.5} fill="#fff" rx={7.5} />
    <rect width={208} height={59} x={0.5} y={0.5} stroke="#6467F2" rx={7.5} />
    <path
      fill="#1E0DFF"
      fillRule="evenodd"
      d="m28.587 25.92.553.553a.667.667 0 0 1 .193.467v.393a.667.667 0 0 1-.666.667h-9.334a.667.667 0 0 1-.666-.667v-.393c0-.175.07-.343.193-.467l.553-.553A2 2 0 0 0 20 24.507v-1.84a4 4 0 0 1 .8-2.4l.6-.8a2 2 0 0 1 1.6-.8h.333v-1c0-.184.15-.334.334-.334h.666c.184 0 .334.15.334.334v1H25a2 2 0 0 1 1.6.8l.6.8a4 4 0 0 1 .8 2.4v1.84a2 2 0 0 0 .587 1.413ZM24 30.667c.736 0 1.333-.597 1.333-1.334h-2.666c0 .737.596 1.334 1.333 1.334Z"
      clipRule="evenodd"
    />
    <rect width={145} height={8} x={48} y={16} fill="#C8D3FE" rx={4} />
    <rect width={145} height={16} x={48} y={28} fill="#C8D3FE" rx={8} />
    <g
      opacity={0.6}
      style={{
        mixBlendMode: 'luminosity',
      }}
    >
      <rect width={208} height={59} x={0.5} y={68.5} fill="#fff" rx={7.5} />
      <rect width={208} height={59} x={0.5} y={68.5} stroke="#E0E8FF" rx={7.5} />
      <path
        fill="#1E0DFF"
        fillRule="evenodd"
        d="m24.542 85.58 6.728 11.507a.527.527 0 0 1 0 .506l-.093.154a.487.487 0 0 1-.434.253H17.26a.487.487 0 0 1-.433-.253l-.094-.154a.527.527 0 0 1 0-.506l6.742-11.507a.507.507 0 0 1 .433-.247h.2c.178 0 .343.094.434.247Zm-.54 1.753-5.475 9.334H29.47l-5.468-9.334ZM23.668 94a.333.333 0 0 0-.333.333V95c0 .184.15.333.333.333h.667c.184 0 .334-.149.334-.333v-.667a.333.333 0 0 0-.334-.333h-.667Zm-.186-1.48-.267-2.147a.333.333 0 0 1 .333-.373h.907a.334.334 0 0 1 .334.373l-.267 2.147a.167.167 0 0 1-.167.147h-.707a.167.167 0 0 1-.166-.147Z"
        clipRule="evenodd"
      />
      <rect width={145} height={8} x={48} y={84} fill="#C8D3FE" rx={4} />
      <rect width={145} height={16} x={48} y={96} fill="#C8D3FE" rx={8} />
    </g>
    <g
      opacity={0.4}
      style={{
        mixBlendMode: 'luminosity',
      }}
    >
      <rect width={208} height={59} x={0.5} y={136.5} fill="#fff" rx={7.5} />
      <rect width={208} height={59} x={0.5} y={136.5} stroke="#E0E8FF" rx={7.5} />
      <path
        fill="#1E0DFF"
        fillRule="evenodd"
        d="m31.107 154.421.187-.513a.668.668 0 0 0-.154-.701l-.346-.347a.665.665 0 0 0-.7-.153l-.514.186a2.68 2.68 0 0 0-.98.621l-2.88 2.883-3.566-.714 6.166 6.172-.713-3.57 2.88-2.883c.276-.277.488-.612.62-.981Zm-13.087-1.088c.088 0 .173.036.233.101l12.307 12.324a.319.319 0 0 1 0 .467l-.353.354a.322.322 0 0 1-.467 0l-.82-.814a1.207 1.207 0 0 1-.307.507l-.113.113a.294.294 0 0 1-.227.094.321.321 0 0 1-.273-.154l-1.466-2.949-2.087-2.089-2 1.675.293 3.47a.67.67 0 0 1-.186.454l-.354.353a.32.32 0 0 1-.513-.087l-1.614-3.229-3.226-1.615a.319.319 0 0 1-.087-.513l.353-.354a.663.663 0 0 1 .454-.187l3.42.287 1.713-2.002-2.08-2.082-2.933-1.454a.32.32 0 0 1-.154-.28.293.293 0 0 1 .094-.227l.113-.114a1.21 1.21 0 0 1 .507-.307l-.814-.82a.321.321 0 0 1 0-.467l.354-.354a.318.318 0 0 1 .233-.101Z"
        clipRule="evenodd"
      />
      <rect width={145} height={8} x={48} y={152} fill="#C8D3FE" rx={4} />
      <rect width={145} height={16} x={48} y={164} fill="#C8D3FE" rx={8} />
    </g>
    <g
      opacity={0.1}
      style={{
        mixBlendMode: 'luminosity',
      }}
    >
      <rect width={208} height={59} x={0.5} y={204.5} fill="#fff" rx={7.5} />
      <rect width={208} height={59} x={0.5} y={204.5} stroke="#E0E8FF" rx={7.5} />
      <path
        fill="#1E0DFF"
        fillRule="evenodd"
        d="M28.667 222.667H28v-1a.333.333 0 0 0-.333-.334H27a.333.333 0 0 0-.333.334v1h-5.334v-1a.333.333 0 0 0-.333-.334h-.667a.333.333 0 0 0-.333.334v1h-.667c-.736 0-1.333.597-1.333 1.333v8.667c0 .736.597 1.333 1.333 1.333h9.334c.736 0 1.333-.597 1.333-1.333V224c0-.736-.597-1.333-1.333-1.333Zm0 10h-9.334v-7.334h9.334v7.334ZM24.333 228h-.666a.333.333 0 0 1-.334-.333V227c0-.184.15-.333.334-.333h.666c.184 0 .334.149.334.333v.667c0 .184-.15.333-.334.333Zm2 0H27a.333.333 0 0 0 .333-.333V227a.333.333 0 0 0-.333-.333h-.667A.333.333 0 0 0 26 227v.667c0 .184.15.333.333.333Zm-4.666 0H21a.333.333 0 0 1-.333-.333V227c0-.184.149-.333.333-.333h.667c.184 0 .333.149.333.333v.667c0 .184-.15.333-.333.333Zm2 2.667h.666c.184 0 .334-.15.334-.334v-.666a.333.333 0 0 0-.334-.334h-.666a.333.333 0 0 0-.334.334v.666c0 .184.15.334.334.334Zm-2 0H21a.334.334 0 0 1-.333-.334v-.666c0-.185.149-.334.333-.334h.667c.184 0 .333.149.333.334v.666c0 .184-.15.334-.333.334Z"
        clipRule="evenodd"
      />
      <rect width={145} height={8} x={48} y={220} fill="#C8D3FE" rx={4} />
      <rect width={145} height={16} x={48} y={232} fill="#C8D3FE" rx={8} />
    </g>
  </svg>
)
export default EmptyNotificationsLogo
