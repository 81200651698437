import React, { ReactNode } from 'react'

function FormGroup({
  htmlFor,
  label,
  hideLabel = false,
  children,
}: {
  htmlFor: string
  label: string
  hideLabel?: boolean
  children: ReactNode
}): JSX.Element {
  return (
    <div>
      <label
        className={[
          'block mb-2 text-sm font-semibold leading-tight text-gray-800',
          hideLabel && 'sr-only',
        ]
          .filter(Boolean)
          .join(' ')}
        htmlFor={htmlFor}
      >
        {label}
      </label>
      <div>{children}</div>
    </div>
  )
}

FormGroup.defaultProps = {
  hideLabel: false,
}

export default FormGroup
