import { Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment, ReactNode, forwardRef, useImperativeHandle, useState } from 'react'

interface Props {
  content: ReactNode
  children: ReactNode
  placement?: 'top' | 'bottom' | 'left' | 'right'
  alignment?: 'start' | 'center' | 'end'
  background?: 'dark' | 'light'
  open?: boolean
  className?: string
  keepOpenOnHover?: boolean
}

export type TooltipRef = {
  setTooltipOpen: (value: boolean) => void
}

const Tooltip = forwardRef<TooltipRef, Props>((props, ref) => {
  const {
    content,
    children,
    placement = 'bottom',
    alignment = 'center',
    background = 'dark',
    open = false,
    className = '',
    keepOpenOnHover = false,
  } = props

  const [isOpen, setIsOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    setTooltipOpen(value: boolean) {
      setIsOpen(value)
    },
  }))

  return (
    <div className="relative flex" onBlur={() => setIsOpen(false)}>
      <Transition
        as={Fragment}
        show={open || isOpen}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          className={clsx(
            'absolute w-max whitespace-pre-line text-center p-2 bg-gray-50 text-gray-600 rounded-md shadow-lg border border-gray-200 focus:outline-none z-50',
            placement === 'top' && 'bottom-full mb-2',
            placement === 'bottom' && 'top-full mt-2',
            alignment === 'center' && ['top', 'bottom'].includes(placement) && '-translate-x-1/2',
            alignment === 'end' && ['top', 'bottom'].includes(placement) && 'right-0',
            placement === 'right' && 'ml-2 left-full',
            placement === 'left' && 'mr-2 right-full',
            background === 'dark' ? 'bg-gray-700 text-white' : 'bg-white text-gray-600 border',
            className
          )}
          onMouseLeave={() => keepOpenOnHover && setIsOpen(false)}
        >
          {content}
        </div>
      </Transition>
      <div
        className="w-full"
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => !keepOpenOnHover && setIsOpen(false)}
      >
        {children}
      </div>
    </div>
  )
})

Tooltip.displayName = 'Tooltip'
export default Tooltip
