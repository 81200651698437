import { Resource } from './resource'
import { ActionPermissions, Permissions, ResourcePermissions } from './permissions'
import { Action } from './action'

type PermissionsQueryMethods = {
  [method in keyof ResourcePermissions]: (resource: Resource) => boolean
} & {
  [method in keyof ActionPermissions]: (action: Action) => boolean
}

class PermissionsQuery implements PermissionsQueryMethods {
  private permissions: Permissions

  constructor(permissions: Permissions) {
    this.permissions = permissions
  }

  public perform(action: Action): boolean {
    if (!this.permissions.perform) {
      return false
    }
    return this.permissions.perform.includes(action)
  }

  public create(resource: Resource): boolean {
    if (!this.permissions.create) {
      return false
    }
    return this.permissions.create.includes(resource)
  }

  public read(resource: Resource): boolean {
    if (!this.permissions.read) {
      return false
    }
    return this.permissions.read.includes(resource)
  }

  public update(resource: Resource): boolean {
    if (!this.permissions.update) {
      return false
    }
    return this.permissions.update.includes(resource)
  }

  public delete(resource: Resource): boolean {
    if (!this.permissions.delete) {
      return false
    }
    return this.permissions.delete.includes(resource)
  }
}

export { PermissionsQuery }
