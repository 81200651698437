import { forOwn, isObject } from 'lodash'

export default function traverseObject(obj: any, cb: (v: any) => any) {
  cb(obj)
  if (Array.isArray(obj)) {
    obj.map((v) => traverseObject(v, cb))
  } else if (isObject(obj)) {
    forOwn(obj, (val) => traverseObject(val, cb))
  }
}
