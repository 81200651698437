/* eslint-disable react/jsx-props-no-spreading */
import { SVGProps } from 'react'

function PlanCrewChangeIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path d="M6.001 4.49599C6.001 3.11749 7.12067 2 8.50184 2C9.88302 2 11.0027 3.11749 11.0027 4.49599C11.0027 5.87448 9.88302 6.99197 8.50184 6.99197C7.83858 6.99197 7.20248 6.729 6.73348 6.26091C6.26448 5.79283 6.001 5.15796 6.001 4.49599ZM13.8937 12.5331L12.8933 10.6361C12.3914 9.64156 11.378 9.00705 10.2624 8.98876H6.74125C5.60759 8.98841 4.5705 9.62569 4.06035 10.6361L3.11003 12.5331C2.95401 12.8421 2.96944 13.2097 3.15081 13.5046C3.33218 13.7995 3.65362 13.9797 4.00033 13.9807H13.0034C13.3501 13.9797 13.6715 13.7995 13.8529 13.5046C14.0342 13.2097 14.0497 12.8421 13.8937 12.5331ZM18.005 4.49599C18.005 5.87448 16.8854 6.99197 15.5042 6.99197C14.8401 6.99464 14.2025 6.73253 13.7329 6.26386C13.2633 5.79519 13.0007 5.15878 13.0034 4.49599C13.0034 3.11749 14.123 2 15.5042 2C16.8854 2 18.005 3.11749 18.005 4.49599ZM20.896 12.5331L19.9457 10.6361C19.4355 9.62569 18.3984 8.98841 17.2648 8.98876H14.2638C14.4468 9.22644 14.6076 9.48043 14.7439 9.74754L15.6943 11.6345C16.056 12.3663 16.103 13.2136 15.8243 13.9807H20.0057C20.3524 13.9797 20.6739 13.7995 20.8552 13.5046C21.0366 13.2097 21.052 12.8421 20.896 12.5331ZM13.6136 15.9775H17.5049C17.6479 15.9636 17.7902 16.0094 17.8982 16.104C18.0062 16.1985 18.0702 16.3334 18.0751 16.4767V20.3605C18.0826 20.5651 17.9616 20.7528 17.7719 20.8307C17.5821 20.9087 17.3638 20.8605 17.2248 20.7099L16.2244 19.7115C14.4726 21.6353 11.7956 22.4297 9.27519 21.7735C6.7548 21.1174 4.8076 19.1193 4.22041 16.5865C4.19261 16.4389 4.22898 16.2865 4.32044 16.1672C4.41383 16.0468 4.55804 15.9767 4.71057 15.9775H5.71091C5.92258 15.9718 6.11491 16.0998 6.19107 16.297C6.68228 18.05 8.09054 19.3971 9.86615 19.8126C11.6418 20.2281 13.5032 19.6461 14.7239 18.2938L13.2634 16.8262C13.1233 16.6835 13.0819 16.4713 13.1581 16.2866C13.2343 16.1019 13.4135 15.9803 13.6136 15.9775Z" />
    </svg>
  )
}

export default PlanCrewChangeIcon
