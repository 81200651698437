import { Mobile, TabletAndAbove } from 'utils/getResponsiveLayout'
import Spinner from '~components/ui/Icon/Spinner'
import Table from '~components/ui/Table/Table'
import TableBody from '~components/ui/Table/TableBody'
import { CrewChangeOverViewTab } from '~pages/index'
import CancelledCrewChangesTable from './CancelledCrewChangesTable'
import CrewChangeTableHead from './CrewChangeTableHead'
import CrewChangesOverviewCard from './CrewChangesOverviewCard'
import CrewChangesTableRow from './CrewChangesTableRow'
import { CrewChangeSummary } from './types'

export default function CrewChangesTable({
  activeTab,
  crewChanges,
  loading,
}: {
  activeTab: CrewChangeOverViewTab
  crewChanges: CrewChangeSummary[]
  loading: boolean
}) {
  if (loading) {
    return (
      <div className="flex justify-center">
        <Spinner className="mr-2 h-5 w-5" />
        <span className=" text-center text-gray-500">Loading...</span>
      </div>
    )
  }

  if (!crewChanges.length) {
    return <div>No crew changes found</div>
  }

  if (activeTab === CrewChangeOverViewTab.Cancelled) {
    return <CancelledCrewChangesTable crewChanges={crewChanges} />
  }

  return (
    <>
      <TabletAndAbove>
        <Table className="overflow-visible">
          <CrewChangeTableHead />
          <TableBody>
            {crewChanges.map((crewChange) => (
              <CrewChangesTableRow
                activeTab={activeTab}
                crewChange={crewChange}
                key={crewChange.bucketId}
              />
            ))}
          </TableBody>
        </Table>
      </TabletAndAbove>

      <Mobile>
        <div className="grid grid-cols-1 gap-2">
          {crewChanges.map((crewChange) => (
            <CrewChangesOverviewCard key={crewChange.bucketId} crewChange={crewChange} />
          ))}
        </div>
      </Mobile>
    </>
  )
}
