import { maxBy } from 'lodash'
import { useRouter } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'
import { useSearchForCheaperOffersMutation } from '~api/booking.generated'
import { NotificationsQuery } from '~api/notifications-gql.generated'
import OfferVerificationFailureImage from '~components/Images/OfferVerificationFailureImage'
import OfferVerificationGif from '~components/Images/OfferVerificationGif'
import OfferVerificationSuccessImage from '~components/Images/OfferVerificationSuccess'
import Button from '~components/ui/Button/Button'
import Modal from '~components/ui/Modal/Modal'
import useRelativeDateTime from '~hooks/useRelativeDateTime'
import { useTrackEvent } from '~hooks/useTrackEvent'

export default function CheaperOfferNotificationModal({
  notification,
  onDismiss,
  isOpen,
  onClose,
}: {
  notification: NotificationsQuery['notifications'][number]
  isOpen: boolean
  onClose: () => void
  onDismiss: () => void
}) {
  const [searchForCheaperOffers] = useSearchForCheaperOffersMutation()
  const router = useRouter()

  const bookingIdsToVerify = notification.cheaperOfferNotifications.map(
    (n) => n.cheaperOffer.flightBooking.id
  )

  const [status, setStatus] = useState<'verifying' | 'success' | 'error'>('verifying')
  const [numBookingsLeftToVerify, setNumBookingsLeftToVerify] = useState(bookingIdsToVerify.length)
  const [redirectCountdown, setRedirectCountdown] = useState(3)
  const redirectTimerInterval = useRef<ReturnType<typeof setInterval> | null>(null)

  const trackEvent = useTrackEvent()
  const relativeCreatedAt = useRelativeDateTime(
    maxBy(notification.cheaperOfferNotifications, 'notification.createdAt')?.notification
      .createdAt ?? new Date().toISOString()
  )

  const { bucketId } = notification

  const onVerificationSuccess = () => {
    redirectTimerInterval.current = setInterval(() => {
      setRedirectCountdown((prev) => prev - 1)
    }, 1000)
  }

  useEffect(() => {
    if (redirectCountdown === 0 && redirectTimerInterval.current) {
      clearInterval(redirectTimerInterval.current)
      router.push(`/crew-changes/${bucketId}`)
    }
  }, [redirectCountdown, router, bucketId])

  const verifyCheaperOffers = async () => {
    setStatus('verifying')

    const responses = await Promise.all(
      bookingIdsToVerify.map(async (bookingId) => {
        const { data } = await searchForCheaperOffers({
          variables: { bookingId },
          onCompleted: () => {
            setNumBookingsLeftToVerify((prev) => prev - 1)
          },
        })

        return data?.booking.searchForCheaperOffers
      })
    )

    if (responses.some((r) => !!r)) {
      setStatus('success')
      onVerificationSuccess()
    } else {
      setStatus('error')
      trackEvent({
        event: 'Cheaper offer expired',
        metadata: {
          mixpanelProperties: {
            source: 'Notification center',
            age: relativeCreatedAt,
          },
        },
      })
    }
  }

  useEffect(() => {
    if (isOpen) {
      verifyCheaperOffers()
    }
  }, [isOpen])

  const getModalContentForStatus = () => {
    switch (status) {
      case 'verifying':
        return (
          <>
            <OfferVerificationGif className="-mt-16" />
            <h3 className="text-lg font-bold">Verifying offers</h3>
            <div className="text-sm text-neutral-400">
              <span className="font-bold">Est.Time: </span>
              <span className="italic">Less than {numBookingsLeftToVerify * 15} seconds</span>
            </div>
          </>
        )
      case 'success':
        return (
          <>
            <OfferVerificationSuccessImage className="-mt-16" />
            <h3 className="text-lg text-emerald-700 font-bold">Offers verified</h3>
            <div className="flex flex-col items-center text-emerald-500 text-sm bg-emerald-50 px-8 py-2 rounded-md border border-emerald-200">
              <div className="font-bold">Redirecting to Crew Change</div>
              <hr className="my-1 w-full border border-emerald-500 " />
              <div className="italic">{redirectCountdown} seconds</div>
            </div>
          </>
        )
      case 'error':
        return (
          <div className="flex flex-col items-center gap-4">
            <OfferVerificationFailureImage className="-mt-16" />
            <h3 className="text-lg font-bold">Offers Lapsed</h3>
            <div className="flex flex-col gap-2 items-center text-neutral-500 text-sm bg-neutral-100 px-8 py-2 rounded-md border border-neutral-200">
              <div className="font-bold">But no worries!</div>
              <div className="text-center">Tilla is still searching for better deals for you!</div>
            </div>
            <Button variant="default" fullWidth onClick={onDismiss}>
              Done
            </Button>
          </div>
        )
      default:
        return <>Invalid status: {status}</>
    }
  }

  return (
    <Modal open={isOpen} onClose={onClose} inset width="sm" disableClosing={status === 'verifying'}>
      <div className="flex flex-col items-center gap-2">{getModalContentForStatus()}</div>
    </Modal>
  )
}
