import React, { ReactNode, useMemo, useState } from 'react'
import ConfirmPopup from '~components/ui/ConfirmPopup/ConfirmPopup'

interface PopupParams {
  title: string
  text: ReactNode
  onConfirm?: () => void
  onCancel?: () => void
  showCancelButton?: boolean
  confirmText?: string
}

interface IConfirmPopupContext {
  showConfirmPopup: (opts: PopupParams) => void
}

export const ConfirmPopupContext = React.createContext<IConfirmPopupContext>({
  showConfirmPopup: () => undefined,
})

interface Props {
  children: ReactNode
}

export default function ConfirmPopupPresenter({ children }: Props) {
  const [popupParams, setPopupParams] = useState<PopupParams | null>(null)
  const [remountKey, setRemountKey] = useState(0)

  const showConfirmPopup = (opts: PopupParams) => {
    setPopupParams(opts)
    setRemountKey(Math.random())
  }

  const value = useMemo(
    () => ({
      showConfirmPopup,
    }),
    []
  )

  const dismissPopup = () => {
    setPopupParams(null)
  }

  const handleConfirm = () => {
    popupParams?.onConfirm?.()
    dismissPopup()
  }

  const handleCancel = () => {
    popupParams?.onCancel?.()
    dismissPopup()
  }

  return (
    <ConfirmPopupContext.Provider value={value}>
      {children}
      <ConfirmPopup
        key={remountKey}
        isOpen={!!popupParams}
        showCancelButton={popupParams?.showCancelButton}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        title={popupParams?.title ?? ''}
        text={popupParams?.text ?? ''}
        confirmText={popupParams?.confirmText}
      />
    </ConfirmPopupContext.Provider>
  )
}
