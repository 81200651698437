/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import { VesselFieldsFragmentDoc } from './fragments.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type AssignVesselScheduleCrewOperatorMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input']
  crewOperatorId: Types.Scalars['UUID']['input']
  type: Types.ActiveCrewOperatorType
}>

export type AssignVesselScheduleCrewOperatorMutation = {
  vessel: { assignCrewOperator: { id: string } } | null
}

export type AssignVesselScheduleCrewSupervisorMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID']['input']
  crewSupervisorId: Types.Scalars['UUID']['input']
  type: Types.ActiveCrewSupervisorType
}>

export type AssignVesselScheduleCrewSupervisorMutation = {
  vessel: { assignCrewSupervisor: { id: string } } | null
}

export type SearchVesselsQueryVariables = Types.Exact<{
  search: Types.Scalars['String']['input']
}>

export type SearchVesselsQuery = {
  vessels: Array<{
    id: string
    vesselScheduleSource: Types.VesselScheduleSource
    charterName: string
    imo: number
  }>
}

export type GetRecentVesselsQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetRecentVesselsQuery = {
  recentVessels: Array<{
    id: string
    vesselScheduleSource: Types.VesselScheduleSource
    charterName: string
    imo: number
  }>
}

export type GetAllVesselsQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetAllVesselsQuery = {
  allVessels: Array<{
    id: string
    vesselScheduleSource: Types.VesselScheduleSource
    charterName: string
    imo: number
  }>
}

export const AssignVesselScheduleCrewOperatorDocument = gql`
  mutation AssignVesselScheduleCrewOperator(
    $id: UUID!
    $crewOperatorId: UUID!
    $type: ActiveCrewOperatorType!
  ) {
    vessel(id: $id) {
      assignCrewOperator(crewOperatorId: $crewOperatorId, type: $type) {
        id
      }
    }
  }
`
export type AssignVesselScheduleCrewOperatorMutationFn = Apollo.MutationFunction<
  AssignVesselScheduleCrewOperatorMutation,
  AssignVesselScheduleCrewOperatorMutationVariables
>

/**
 * __useAssignVesselScheduleCrewOperatorMutation__
 *
 * To run a mutation, you first call `useAssignVesselScheduleCrewOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignVesselScheduleCrewOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignVesselScheduleCrewOperatorMutation, { data, loading, error }] = useAssignVesselScheduleCrewOperatorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      crewOperatorId: // value for 'crewOperatorId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAssignVesselScheduleCrewOperatorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignVesselScheduleCrewOperatorMutation,
    AssignVesselScheduleCrewOperatorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AssignVesselScheduleCrewOperatorMutation,
    AssignVesselScheduleCrewOperatorMutationVariables
  >(AssignVesselScheduleCrewOperatorDocument, options)
}
export type AssignVesselScheduleCrewOperatorMutationHookResult = ReturnType<
  typeof useAssignVesselScheduleCrewOperatorMutation
>
export type AssignVesselScheduleCrewOperatorMutationResult =
  Apollo.MutationResult<AssignVesselScheduleCrewOperatorMutation>
export type AssignVesselScheduleCrewOperatorMutationOptions = Apollo.BaseMutationOptions<
  AssignVesselScheduleCrewOperatorMutation,
  AssignVesselScheduleCrewOperatorMutationVariables
>
export const AssignVesselScheduleCrewSupervisorDocument = gql`
  mutation AssignVesselScheduleCrewSupervisor(
    $id: UUID!
    $crewSupervisorId: UUID!
    $type: ActiveCrewSupervisorType!
  ) {
    vessel(id: $id) {
      assignCrewSupervisor(crewSupervisorId: $crewSupervisorId, type: $type) {
        id
      }
    }
  }
`
export type AssignVesselScheduleCrewSupervisorMutationFn = Apollo.MutationFunction<
  AssignVesselScheduleCrewSupervisorMutation,
  AssignVesselScheduleCrewSupervisorMutationVariables
>

/**
 * __useAssignVesselScheduleCrewSupervisorMutation__
 *
 * To run a mutation, you first call `useAssignVesselScheduleCrewSupervisorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignVesselScheduleCrewSupervisorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignVesselScheduleCrewSupervisorMutation, { data, loading, error }] = useAssignVesselScheduleCrewSupervisorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      crewSupervisorId: // value for 'crewSupervisorId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAssignVesselScheduleCrewSupervisorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignVesselScheduleCrewSupervisorMutation,
    AssignVesselScheduleCrewSupervisorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AssignVesselScheduleCrewSupervisorMutation,
    AssignVesselScheduleCrewSupervisorMutationVariables
  >(AssignVesselScheduleCrewSupervisorDocument, options)
}
export type AssignVesselScheduleCrewSupervisorMutationHookResult = ReturnType<
  typeof useAssignVesselScheduleCrewSupervisorMutation
>
export type AssignVesselScheduleCrewSupervisorMutationResult =
  Apollo.MutationResult<AssignVesselScheduleCrewSupervisorMutation>
export type AssignVesselScheduleCrewSupervisorMutationOptions = Apollo.BaseMutationOptions<
  AssignVesselScheduleCrewSupervisorMutation,
  AssignVesselScheduleCrewSupervisorMutationVariables
>
export const SearchVesselsDocument = gql`
  query SearchVessels($search: String!) {
    vessels(search: $search) {
      ...VesselFields
    }
  }
  ${VesselFieldsFragmentDoc}
`

/**
 * __useSearchVesselsQuery__
 *
 * To run a query within a React component, call `useSearchVesselsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchVesselsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchVesselsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchVesselsQuery(
  baseOptions: Apollo.QueryHookOptions<SearchVesselsQuery, SearchVesselsQueryVariables> &
    ({ variables: SearchVesselsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchVesselsQuery, SearchVesselsQueryVariables>(
    SearchVesselsDocument,
    options
  )
}
export function useSearchVesselsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchVesselsQuery, SearchVesselsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchVesselsQuery, SearchVesselsQueryVariables>(
    SearchVesselsDocument,
    options
  )
}
export function useSearchVesselsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SearchVesselsQuery, SearchVesselsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SearchVesselsQuery, SearchVesselsQueryVariables>(
    SearchVesselsDocument,
    options
  )
}
export type SearchVesselsQueryHookResult = ReturnType<typeof useSearchVesselsQuery>
export type SearchVesselsLazyQueryHookResult = ReturnType<typeof useSearchVesselsLazyQuery>
export type SearchVesselsSuspenseQueryHookResult = ReturnType<typeof useSearchVesselsSuspenseQuery>
export type SearchVesselsQueryResult = Apollo.QueryResult<
  SearchVesselsQuery,
  SearchVesselsQueryVariables
>
export const GetRecentVesselsDocument = gql`
  query GetRecentVessels {
    recentVessels {
      ...VesselFields
    }
  }
  ${VesselFieldsFragmentDoc}
`

/**
 * __useGetRecentVesselsQuery__
 *
 * To run a query within a React component, call `useGetRecentVesselsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentVesselsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentVesselsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecentVesselsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRecentVesselsQuery, GetRecentVesselsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRecentVesselsQuery, GetRecentVesselsQueryVariables>(
    GetRecentVesselsDocument,
    options
  )
}
export function useGetRecentVesselsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRecentVesselsQuery, GetRecentVesselsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRecentVesselsQuery, GetRecentVesselsQueryVariables>(
    GetRecentVesselsDocument,
    options
  )
}
export function useGetRecentVesselsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRecentVesselsQuery,
    GetRecentVesselsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetRecentVesselsQuery, GetRecentVesselsQueryVariables>(
    GetRecentVesselsDocument,
    options
  )
}
export type GetRecentVesselsQueryHookResult = ReturnType<typeof useGetRecentVesselsQuery>
export type GetRecentVesselsLazyQueryHookResult = ReturnType<typeof useGetRecentVesselsLazyQuery>
export type GetRecentVesselsSuspenseQueryHookResult = ReturnType<
  typeof useGetRecentVesselsSuspenseQuery
>
export type GetRecentVesselsQueryResult = Apollo.QueryResult<
  GetRecentVesselsQuery,
  GetRecentVesselsQueryVariables
>
export const GetAllVesselsDocument = gql`
  query GetAllVessels {
    allVessels {
      ...VesselFields
    }
  }
  ${VesselFieldsFragmentDoc}
`

/**
 * __useGetAllVesselsQuery__
 *
 * To run a query within a React component, call `useGetAllVesselsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllVesselsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllVesselsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllVesselsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllVesselsQuery, GetAllVesselsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAllVesselsQuery, GetAllVesselsQueryVariables>(
    GetAllVesselsDocument,
    options
  )
}
export function useGetAllVesselsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllVesselsQuery, GetAllVesselsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAllVesselsQuery, GetAllVesselsQueryVariables>(
    GetAllVesselsDocument,
    options
  )
}
export function useGetAllVesselsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllVesselsQuery, GetAllVesselsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetAllVesselsQuery, GetAllVesselsQueryVariables>(
    GetAllVesselsDocument,
    options
  )
}
export type GetAllVesselsQueryHookResult = ReturnType<typeof useGetAllVesselsQuery>
export type GetAllVesselsLazyQueryHookResult = ReturnType<typeof useGetAllVesselsLazyQuery>
export type GetAllVesselsSuspenseQueryHookResult = ReturnType<typeof useGetAllVesselsSuspenseQuery>
export type GetAllVesselsQueryResult = Apollo.QueryResult<
  GetAllVesselsQuery,
  GetAllVesselsQueryVariables
>
