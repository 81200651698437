import { getLogrocketUrl } from '../utils/log-rocket'

export const prefix: string = process.env.NEXT_PUBLIC_API_URL ?? ''

export default function fetchApi(input: string, init?: RequestInit) {
  const headers: HeadersInit = {
    'X-LogRocket-URL': getLogrocketUrl(),
  }

  if (!(init?.body instanceof FormData)) {
    headers['Content-Type'] = 'application/json'
  }

  return fetch(`${prefix}/${input}`, {
    credentials: 'include',
    headers,
    ...init,
  })
}
