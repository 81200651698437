/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGProps } from 'react'

function UsersSwitchIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path d="M6,4.5A2.5,2.5,0,1,1,8.5,7,2.5,2.5,0,0,1,6,4.5Zm7.89,8.05-1-1.9A3,3,0,0,0,10.26,9H6.74a3,3,0,0,0-2.68,1.65l-.95,1.9A1,1,0,0,0,4,14h9A1,1,0,0,0,13.89,12.55ZM15.5,7A2.5,2.5,0,1,0,13,4.5,2.49,2.49,0,0,0,15.5,7Zm5.39,5.55-.95-1.9A3,3,0,0,0,17.26,9h-3a4.89,4.89,0,0,1,.48.76l.95,1.89A3,3,0,0,1,15.82,14H20A1,1,0,0,0,20.89,12.55ZM17.5,16H13.61a.5.5,0,0,0-.35.85l1.46,1.47a5,5,0,0,1-8.53-2A.5.5,0,0,0,5.71,16h-1a.49.49,0,0,0-.39.19.53.53,0,0,0-.1.42,7,7,0,0,0,12,3.13l1,1a.49.49,0,0,0,.85-.35V16.5A.52.52,0,0,0,17.5,16Z" />
    </svg>
  )
}

export default UsersSwitchIcon
