import Alert from '~components/ui/Alert/Alert'
import Button from '~components/ui/Button/Button'
import Spinner from '~components/ui/Icon/Spinner'

interface Props {
  isLoading: boolean
  onCancel: () => void
}

export default function RestoreCrewChangeModalRestoringState({ isLoading, onCancel }: Props) {
  return (
    <>
      <div className="flex flex-col mb-6 px-6">
        <span className="text-sm mb-4">
          Running a quick check before we restore the Crew Change
        </span>
        <Alert
          title={
            <span className="flex items-center justify-between gap-2">
              {isLoading && <Spinner className="w-6 h-6" />}
              <span>Restoring this Crew Change</span>
            </span>
          }
        />
      </div>
      <hr />
      <div className="flex justify-between py-4 px-6">
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled>
          <span className="inline-flex items-center">
            <span>Restoring</span> <Spinner className="w-4 h-4 ml-2" />
          </span>
        </Button>
      </div>
    </>
  )
}
