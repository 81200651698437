import { useContext, useMemo } from 'react'
import getCrewChangeRouteForUserRole from 'utils/getCrewChangeRouteForUserRole'
import getCurrentCrewChangeStatus from 'utils/getCurrentCrewChangeStatus'
import CrewChangeStatusBadge from '~components/CrewChange/CrewChangeStatusBadge'
import VesselSchedule from '~components/VesselSchedule/VesselSchedule'
import Card from '~components/ui/Card/Card'
import BoatIcon from '~components/ui/Icon/BoatIcon'
import PointOfInterestIcon from '~components/ui/Icon/PointOfInterestIcon'
import { UserContext } from '~context/UserContext'
import { CrewChangeSummary } from './types'

export default function CrewChangesOverviewCard({ crewChange }: { crewChange: CrewChangeSummary }) {
  const { user } = useContext(UserContext)

  const vesselSchedule = {
    ETA: crewChange.ETA,
    ETB: crewChange.ETB,
    ETD: crewChange.ETD,
    seaport: {
      timezoneOlson: crewChange.portTimezoneOlson,
    },
  }

  const roleRoute = useMemo<string>(() => {
    return getCrewChangeRouteForUserRole(user.role, crewChange.bucketId)
  }, [user.role, crewChange.bucketId])

  const crewChangeStatus = useMemo(() => {
    return getCurrentCrewChangeStatus(crewChange)
  }, [crewChange])

  return (
    <>
      <Card
        href={roleRoute}
        title={
          <div className="flex justify-between w-full">
            <div className="flex gap-2 items-start w-2/3">
              <BoatIcon className="grow-0 shrink-0 block w-4 h-4 mt-[2px] text-gray-300 sm:mr-3" />{' '}
              <span className="text-sm">{crewChange.vesselName}</span>{' '}
              <PointOfInterestIcon className="grow-0 shrink-0 block w-4 h-4 mt-[2px] text-gray-300 sm:mr-3" />{' '}
              <span className="text-sm">{crewChange.portName}</span>
            </div>
            <div className="flex flex-col text-right">
              <span className="text-gray-400 text-xs">ETA</span>
              <VesselSchedule vesselSchedule={vesselSchedule} timeType="ETA" />
            </div>
          </div>
        }
        key={crewChange.bucketId}
      >
        <div className="flex justify-between items-end w-full">
          <div className="flex flex-col gap-6">
            <span className="text-xs">
              {crewChange.numOnsigners} On / {crewChange.numOffsigners} Off
            </span>
            <div className="flex gap-4">
              <div className="flex flex-col">
                <span className="text-gray-400 text-xs">ETB</span>{' '}
                <VesselSchedule vesselSchedule={vesselSchedule} timeType="ETB" />
              </div>
              <div className="flex flex-col">
                <span className="text-gray-400 text-xs">ETD</span>{' '}
                <VesselSchedule vesselSchedule={vesselSchedule} timeType="ETD" />
              </div>
            </div>
          </div>
          <div>
            <CrewChangeStatusBadge status={crewChangeStatus} />
          </div>
        </div>
      </Card>
    </>
  )
}
