/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGProps } from 'react'

function ClearIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path d="M17.85,16.44a.5.5,0,0,1,0,.71l-.7.7a.5.5,0,0,1-.71,0L12,13.41,7.56,17.85a.5.5,0,0,1-.71,0l-.7-.7a.5.5,0,0,1,0-.71L10.59,12,6.15,7.56a.5.5,0,0,1,0-.71l.7-.7a.5.5,0,0,1,.71,0L12,10.59l4.44-4.44a.5.5,0,0,1,.71,0l.7.7a.5.5,0,0,1,0,.71L13.41,12Z" />
    </svg>
  )
}

export default ClearIcon
