import { Dialog } from '@headlessui/react'
import clsx from 'clsx'
import ClearIcon from '../Icon/ClearIcon'

interface Props {
  title?: string | JSX.Element
  showCloseButton?: boolean
  disableClosing?: boolean
  handleClose: () => void
  className?: string
  onMouseDown?: () => void
  onMouseUp?: () => void
}

export default function ModalHeader({
  title,
  showCloseButton,
  handleClose,
  disableClosing,
  className,
  onMouseDown,
  onMouseUp,
}: Props) {
  return (
    <div
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      className={`${className} flex items-center justify-between p-6`}
    >
      <div>
        {title && (
          <Dialog.Title className="text-xl font-bold text-gray-900 leading-none">
            {title}
          </Dialog.Title>
        )}
      </div>

      <div>
        {showCloseButton && (
          <button
            aria-label="Close button"
            type="button"
            tabIndex={0}
            disabled={disableClosing}
            className={clsx(
              'cancel-drag flex items-center justify-center w-10 h-10 text-gray-600 transition-transform rounded-full focus:ring focus:ring-blue-200 focus:outline-none',
              { 'opacity-50': disableClosing }
            )}
            onClick={(ev) => {
              ev.stopPropagation()
              handleClose()
            }}
          >
            <ClearIcon className="w-6 h-6 text-gray-400" />
          </button>
        )}
      </div>
    </div>
  )
}
