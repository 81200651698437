/* eslint-disable react/jsx-props-no-spreading */
import { SVGProps } from 'react'

function TicketIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.35 9.44925L27.1375 10.2351C27.3684 10.4675 27.4986 10.7811 27.5 11.1084V12.5804C27.4986 12.9076 27.3684 13.2212 27.1375 13.4536L13.475 27.1382C13.2422 27.3687 12.9279 27.4986 12.6 27.5H11.125C10.7971 27.4986 10.4828 27.3687 10.25 27.1382L9.4625 26.3523C9.06989 26.0015 8.9399 25.4427 9.1375 24.9552C9.29921 24.5593 9.37996 24.1352 9.375 23.7077C9.375 21.9853 7.97589 20.5891 6.25 20.5891C5.82167 20.5841 5.39667 20.6647 5 20.8261C4.52568 21.0003 3.99291 20.8723 3.65 20.5017L2.8625 19.7158C2.63157 19.4835 2.50138 19.1699 2.5 18.8426V17.3706C2.50138 17.0434 2.63157 16.7298 2.8625 16.4974L16.525 2.86265C16.7578 2.63219 17.0721 2.50227 17.4 2.50089H18.875C19.196 2.48879 19.5093 2.60047 19.75 2.81276L20.5375 3.59866C20.9301 3.94945 21.0601 4.50828 20.8625 4.99581C20.7008 5.39167 20.62 5.81581 20.625 6.24327C20.625 7.96566 22.0241 9.36192 23.75 9.36192C24.1783 9.36688 24.6033 9.28629 25 9.12491C25.4743 8.9507 26.0071 9.0787 26.35 9.44925ZM12.5 14.9755C12.5 16.3534 13.6193 17.4704 15 17.4704C16.3807 17.4704 17.5 16.3534 17.5 14.9755C17.5 13.5976 16.3807 12.4806 15 12.4806C13.6193 12.4806 12.5 13.5976 12.5 14.9755Z"
      />{' '}
    </svg>
  )
}

export default TicketIcon
