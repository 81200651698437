import Link from 'next/link'
import { CrewChangeSummary } from '~components/CrewChanges/types'
import Alert from '~components/ui/Alert/Alert'
import Button from '~components/ui/Button/Button'
import ButtonIcon from '~components/ui/Button/ButtonIcon'
import BoatIcon from '~components/ui/Icon/BoatIcon'
import Icon from '~components/ui/Icon/Icon'
import OpenInNewIcon from '~components/ui/Icon/OpenInNewIcon'
import PointOfInterestIcon from '~components/ui/Icon/PointOfInterestIcon'
import VesselSchedule from '~components/VesselSchedule/VesselSchedule'

interface Props {
  conflictingCrewChanges: CrewChangeSummary[]
  onCancel: () => void
}

export default function RestoreCrewChangeModalConflictCrewChangeState({
  conflictingCrewChanges,
  onCancel,
}: Props) {
  return (
    <>
      <div className="flex flex-col mb-6 px-6">
        <Alert variant="danger" title="Unable to restore, duplicate crew change exists" />
      </div>
      <hr />
      <div className="flex flex-col space-y-5 py-4 px-6">
        <p className="text-sm font-semibold">Another Crew Change exists with the same schedule:</p>
        <div className="space-y-4 text-sm">
          {conflictingCrewChanges.map(({ bucketId, vesselName, ETA, ETB, ETD, portName }) => (
            <div key={bucketId} className="flex items-center gap-10">
              <div className="flex flex-row space-x-4 my-2">
                <div className="flex items-center ext-sm font-semibold leading-none text-gray-700">
                  <Icon icon={BoatIcon} className="mr-2 text-gray-400" />
                  {vesselName}
                </div>
                <div className="flex items-center text-sm font-semibold leading-none text-gray-700">
                  <Icon icon={PointOfInterestIcon} className="mr-2 text-gray-400" />
                  {portName}
                </div>
              </div>
              <dl className="flex items-center space-x-4 flex-1 my-2">
                <div className="flex flex-col md:flex-row md:space-x-1 w-full">
                  <dt className="text-gray-500 text-xs md:text-sm">ETA:</dt>
                  <VesselSchedule
                    showRelativeTime={false}
                    vesselSchedule={{
                      ETA,
                      ETB,
                      ETD,
                      seaport: {
                        timezoneOlson: 'Etc/UTC',
                      },
                    }}
                    timeType="ETA"
                  />
                </div>
                <div className="flex flex-col md:flex-row md:space-x-1 w-full">
                  <dt className="text-gray-500 text-xs md:text-sm">ETB:</dt>
                  <VesselSchedule
                    showRelativeTime={false}
                    vesselSchedule={{
                      ETA,
                      ETB,
                      ETD,
                      seaport: {
                        timezoneOlson: 'Etc/UTC',
                      },
                    }}
                    timeType="ETB"
                  />
                </div>
                <div className="flex flex-col md:flex-row md:space-x-1 w-full">
                  <dt className="text-gray-500 text-xs md:text-sm">ETD:</dt>
                  <VesselSchedule
                    showRelativeTime={false}
                    vesselSchedule={{
                      ETA,
                      ETB,
                      ETD,
                      seaport: {
                        timezoneOlson: 'Etc/UTC',
                      },
                    }}
                    timeType="ETD"
                  />
                </div>
              </dl>
            </div>
          ))}
        </div>
      </div>
      <hr />
      <div className="flex justify-between py-4 px-6">
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Link target="_blank" href={`/crew-changes/${conflictingCrewChanges[0].bucketId}`} passHref>
          <ButtonIcon variant="outline" icon={OpenInNewIcon} passEvent>
            View Crew Change
          </ButtonIcon>
        </Link>
      </div>
    </>
  )
}
