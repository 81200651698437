import fetchApi from './fetch-api'

export async function verifyUserToken(token: string): Promise<boolean> {
  const response = await fetchApi(`verify`, {
    body: JSON.stringify({ token }),
    method: 'POST',
  })
  return response.ok
}

export async function login(email: string, password: string, tenantId: string): Promise<boolean> {
  const response = await fetchApi(`login`, {
    method: 'POST',
    body: JSON.stringify({ email, password, tenantId }),
  })

  return response.ok
}
export async function forgotPassword(email: string, tenantId: string): Promise<boolean> {
  const response = await fetchApi(`forgot-password`, {
    method: 'POST',
    body: JSON.stringify({ email, tenantId }),
  })

  return response.ok
}

export async function resetPassword(
  token: string,
  password: string
): Promise<{ ok: boolean; message?: string }> {
  const response = await fetchApi(`reset-password`, {
    method: 'POST',
    body: JSON.stringify({ token, password }),
  })
  if (!response.ok) {
    const json = await response.json()
    return {
      ok: false,
      message: json.message,
    }
  }
  return {
    ok: true,
    message: '',
  }
}

export async function requestTokenRefresh({
  token,
  email,
  redirect,
}: {
  token: string
  email: string
  redirect: string
}): Promise<boolean> {
  const response = await fetchApi(`refresh-token`, {
    method: 'POST',
    body: JSON.stringify({ email, token, redirect }),
  })

  return response.ok
}
