/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGProps } from 'react'

function BoatIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path d="m20.64 10.51-1.64-.51v-5a2.49 2.49 0 0 0 -.73-1.77l-.54-.54a2.49 2.49 0 0 0 -1.73-.69h-8a2.49 2.49 0 0 0 -1.77.73l-.54.54a2.49 2.49 0 0 0 -.69 1.73v5l-1.64.49a.5.5 0 0 0 -.34.61l1.77 6.56a6.63 6.63 0 0 0 2.21.34c2.71 0 2.77-1 5-1s2.36 1 5 1a6.63 6.63 0 0 0 2.21-.33l1.79-6.55a.49.49 0 0 0 -.36-.61zm-3.64-1.1-.83-.25-3.6-1.08a2 2 0 0 0 -1.14 0l-4.43 1.33v-4.41h10zm5 10.1v1a.5.5 0 0 1 -.45.5c-1.72.12-2.1 1-4.55 1-2.65 0-2.85-1-5-1s-2.28 1-5 1c-2.46 0-2.81-.86-4.55-1a.5.5 0 0 1 -.45-.5v-1a.5.5 0 0 1 .54-.5c2.15.13 2.46.99 4.46.99s2.35-1 5-1 2.85 1 5 1c2 0 2.31-.86 4.46-1a.5.5 0 0 1 .54.51z" />
    </svg>
  )
}

export default BoatIcon
