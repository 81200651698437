import { SVGProps } from 'react'

function ArrowRightIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth={0.005}
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M6.83 3.92005C6.73534 3.82617 6.6821 3.69837 6.6821 3.56505C6.6821 3.43174 6.73534 3.30394 6.83 3.21005L7.89 2.15005C7.98073 2.05338 8.10741 1.99854 8.24 1.99854C8.37259 1.99854 8.49927 2.05338 8.59 2.15005L17.78 11.3401C17.9207 11.4806 17.9998 11.6712 18 11.8701V12.1301C17.9998 12.3289 17.9207 12.5195 17.78 12.6601L8.59 21.8501C8.49927 21.9467 8.37259 22.0016 8.24 22.0016C8.10741 22.0016 7.98073 21.9467 7.89 21.8501L6.83 20.7901C6.73534 20.6962 6.6821 20.5684 6.6821 20.4351C6.6821 20.3017 6.73534 20.1739 6.83 20.0801L14.91 12.0001L6.83 3.92005Z"
      />
    </svg>
  )
}

export default ArrowRightIcon
