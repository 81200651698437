import { DateTime } from 'luxon'
import { useContext } from 'react'
import Select, { ControlProps, OptionProps, components } from 'react-select'
import { NotificationsContext } from '~context/NotificationsContext'

type CrewChangeOption = {
  value: { bucketId: string; ETA: string }
  label: string
}

const ControlComponent = (props: ControlProps<CrewChangeOption, false>) => (
  <div>
    <p className="bg-neutral-50 border border-neutral-200 px-2 py-1 text-sm text-neutral-500 rounded-t-lg">
      Select a vessel
    </p>
    <components.Control {...props} />
  </div>
)

const OptionComponent = (props: OptionProps<CrewChangeOption>) => {
  const { ETA } = props.data.value
  return (
    <components.Option {...props}>
      <div className="hover:cursor-pointer hover:text-brand p-2 flex flex-col border border-white hover:bg-indigo-50 hover:border hover:border-indigo-200 rounded-md">
        <p className="font-semibold">{props.data.label}</p>
        <p className="text-neutral-600 ">
          <span className="font-semibold">ETA</span>{' '}
          {DateTime.fromISO(ETA).toFormat('LLL dd, hh:mm')}
        </p>
      </div>
    </components.Option>
  )
}

/**
 * This actually filters by crew changes (vessel + port + ETA) than by actual vessels,
 * but the name VesselFilter is still used to be consistent with the name in the UI
 */
export default function VesselFilter() {
  const { notifications, dismissedNotifications, setSelectedBucketId, selectedBucketId } =
    useContext(NotificationsContext)
  const crewChanges = [...notifications, ...dismissedNotifications].map((not) => {
    return {
      value: {
        bucketId: not.bucketId,
        ETA: not.vesselSchedule.ETA,
      },
      label: `${not.vessel.charterName} @ ${not.vesselSchedule.portName ?? '--Unk'}`,
    }
  })

  return (
    <Select
      placeholder="No vessel selected"
      options={crewChanges}
      defaultValue={
        selectedBucketId ? crewChanges.find((cc) => cc.value.bucketId === selectedBucketId) : null
      }
      onChange={(val) => {
        setSelectedBucketId(val?.value.bucketId || null)
      }}
      isClearable
      components={{ Control: ControlComponent, Option: OptionComponent }}
      styles={{
        control: (base) => ({
          ...base,
          borderTop: 'none',
          borderRadius: '0px',
        }),
        container: (base) => ({
          ...base,
          fontSize: '0.875rem',
        }),
        option: (base) => ({
          ...base,
          backgroundColor: 'inherit',
        }),
      }}
    />
  )
}
