import { QueryCrewChangesArgs } from '~api/types.generated'

export enum CrewChangesDateFilteringOption {
  Current = 'Current', // also interpreted as 'recent'
  All = 'All',
}

export type CrewChangesSearchParams = QueryCrewChangesArgs

export interface CrewChangesSearchFilters {
  cancelled: boolean
  completed: boolean
  archived: boolean
  dateFilterOption: CrewChangesDateFilteringOption
}
