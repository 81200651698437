import { useApolloClient } from '@apollo/client'
import { DateTime, Duration } from 'luxon'
import { useRouter } from 'next/router'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useAuthToken } from '~hooks/useAuthToken'
import { DataSourceSystem, ETicketEmailAttachmentFormat, UserRole } from '../api/types.generated'
import { login as apiLogin, verifyUserToken } from '../api/user'
import {
  MeQuery,
  TenantsForUserQuery,
  useLogoutMutation,
  useMeLazyQuery,
  useRefreshAuthLazyQuery,
  useTenantsForUserLazyQuery,
} from '../api/user-gql.generated'
import { useFernand } from './FernandContext'
import { useMixpanel } from './MixpanelContext'
import { NotificationsContext } from './NotificationsContext'

// interval to refresh auth token to keep it working
const AUTH_REFRESH_INTERVAL = Duration.fromObject({ minutes: 30 }).toMillis()

export type Me = Omit<MeQuery['me'], '__typename'>

type DeepLink = {
  path: string
  attemptAt: DateTime
}

export interface IUserContext {
  user: Me
  tenantsForUser: TenantsForUserQuery['tenantsForUser'] | undefined
  isLoading: boolean
  loadUser: () => void
  setUser: (user: Me) => void
  logout: (args?: { redirect: boolean }) => void
  login: (email: string, password: string, tenantId: string) => void
  isAuthenticated: boolean
  popDeepLink: () => DeepLink | null
}

const PUBLIC_USER: Me = {
  id: 'PUBLIC',
  role: UserRole.Public,
  email: 'noreply@tilla.tech',
  firstName: null,
  lastName: null,
  phoneNumber: null,
  city: null,
  countryAlpha2: null,
  timezoneOlson: null,
  emailSignature: null,
  tenant: {
    id: '',
    name: '',
    dataSourceSystem: DataSourceSystem.Manual,
    preferences: {
      id: '',
      preferredCurrency: 'USD',
      preferredFlightHistoryExportCurrency: 'USD',
      preferredTravelOffice: null,
      autoTicketHoursBeforeLTD: 6,
      defaultETicketEmailAttachmentFormat: ETicketEmailAttachmentFormat.Zip,
      monitoringPreferences: null,
    },
  },
  fernandHash: null,
}

const initialContext = {
  user: PUBLIC_USER,
  tenantsForUser: undefined,
  isLoading: true,
  loadUser: () => undefined,
  logout: () => undefined,
  login: () => undefined,
  isAuthenticated: false,
  setUser: () => PUBLIC_USER,
  popDeepLink: () => null,
}

export const UserContext = createContext<IUserContext>(initialContext)

export default function UserProvider({ children }: { children: ReactNode }) {
  const { upsertMixpanelSuperProperties, setUserProperties, resetMixpanel } = useMixpanel()
  const router = useRouter()
  const { isFernandLoaded, initUser } = useFernand()
  const token = useAuthToken()

  const [isLoading, setIsLoading] = useState(initialContext.isLoading)
  const [user, setUser] = useState<Me>(PUBLIC_USER)
  const [tenantsForUser, setTenantsForUser] = useState<
    TenantsForUserQuery['tenantsForUser'] | undefined
  >(undefined)
  const isAuthenticated = useMemo(
    () => !isLoading && user.role !== UserRole.Public,
    [isLoading, user]
  )

  const { resetNotificationsContext } = useContext(NotificationsContext)

  const deepLink = useRef<DeepLink | null>(null)
  const [refreshAuth] = useRefreshAuthLazyQuery({ fetchPolicy: 'network-only' })

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isAuthenticated) {
        return
      }
      try {
        refreshAuth()
      } catch (e) {
        // doing nothing, auth error should be handled by logout link.
      }
    }, AUTH_REFRESH_INTERVAL)
    return () => clearInterval(timer)
  }, [isAuthenticated, refreshAuth])

  const configureMixpanel = (myUser: MeQuery['me']) => {
    /** Mixpanel stuff */
    upsertMixpanelSuperProperties({
      userId: myUser.id,
      user: `${myUser.firstName} ${myUser.lastName}`,
    })
    setUserProperties(myUser)
  }

  // we don't use { loading } from useMeLazyQuery as it causes a race condition on RouteGuard
  const [loadUser] = useMeLazyQuery({
    onError(error) {
      if (error.message === 'Unauthorized') {
        // to redirect to the original path after authentication at /login
        deepLink.current = { path: router.asPath, attemptAt: DateTime.now() }
      }
      setUser(PUBLIC_USER)
      setIsLoading(false)
    },
    onCompleted(res) {
      const { me } = res

      setUser(me)

      configureMixpanel(me)

      setIsLoading(false)

      if (isFernandLoaded) {
        initUser(me)
      }
    },
  })

  const [loadTenantsForUser] = useTenantsForUserLazyQuery({
    onError: () => undefined,
    onCompleted(data) {
      const { tenantsForUser: value } = data

      setTenantsForUser(value)
    },
  })

  useEffect(() => {
    async function loginWithToken() {
      if (!token) {
        return
      }
      const verified = await verifyUserToken(token)

      if (verified) {
        // The token logged in a valid user, so we replace the current route to remove the `?token`
        // This is because otherwise using your back button would get you back to a `?token=`
        // URL, and that single-use token now being expired would log you out again.
        router.replace(router.asPath.split('?')[0], undefined, { shallow: true, scroll: false })
        setIsLoading(true)
        loadUser()
        loadTenantsForUser()
      }
    }
    loginWithToken()
  }, [loadUser, loadTenantsForUser, router, token])

  useEffect(() => {
    setIsLoading(true)
    loadUser()
    loadTenantsForUser()
  }, [loadUser, loadTenantsForUser])

  const client = useApolloClient()
  const [apiLogout] = useLogoutMutation()

  const clearCachedUserData = useCallback(async () => {
    await client.clearStore()
    setUser(PUBLIC_USER)
    deepLink.current = null
    resetNotificationsContext()
  }, [client, resetNotificationsContext])

  const logout = useCallback(async () => {
    await apiLogout({
      onError: () => undefined,
    })
    // Clear tilla-jwt cookie
    await fetch('/api/logout', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    await clearCachedUserData()
    resetMixpanel()
    router.push(`/login${token ? `?token=${token}` : ''}`)
  }, [apiLogout, router, token, clearCachedUserData, resetMixpanel])

  const login = useCallback(
    async (email: string, password: string, tenantId: string) => {
      setIsLoading(true)
      client.clearStore()
      const success = await apiLogin(email, password, tenantId)
      if (success) {
        await loadUser({ fetchPolicy: 'network-only' })
        await loadTenantsForUser()

        upsertMixpanelSuperProperties({
          tenantId,
        })
      } else {
        setUser(PUBLIC_USER)
        setIsLoading(false)
        throw new Error(`Cannot login`)
      }
      setIsLoading(false)
    },
    [client, loadUser, loadTenantsForUser, upsertMixpanelSuperProperties]
  )

  const popDeepLink = useCallback(() => {
    const { current } = deepLink
    deepLink.current = null
    return current
  }, [])

  const context = useMemo(
    () => ({
      user,
      isLoading,
      loadUser,
      tenantsForUser,
      login,
      isAuthenticated,
      logout,
      setUser,
      popDeepLink,
    }),
    [isAuthenticated, loadUser, isLoading, login, logout, user, popDeepLink, tenantsForUser]
  )

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}
