import { ApolloProvider } from '@apollo/client'
import client from 'api/api-client'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { ReactElement, ReactNode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '~components/Fallback/ErrorFallback'
import DefaultLayout from '~components/Layouts/App'
import RouteGuard from '~components/RouteGuard/RouteGuard'
import { BottomSheetProvider } from '~context/BottomSheetContext'
import ConfirmPopupPresenter from '~context/ConfirmPopupContext'
import CrewChangeOverviewFilterProvider from '~context/CrewChangeOverviewFilterContext'
import FernandProvider from '~context/FernandContext'
import LogProvider from '~context/LogContext'
import MixpanelProvider from '~context/MixpanelContext'
import NotificationsProvider from '~context/NotificationsContext'
import ToastProvider from '~context/ToastContext'
import TravelAgentParsedFlightProvider from '~context/TravelAgent/TravelAgentParsedFlightContext'
import TravelAgentPnrProvider from '~context/TravelAgent/TravelAgentPnrContext'
import TravelAgentProvider from '~context/TravelAgentContext'
import TravelOfficeConfigProvider from '~context/TravelOfficeConfigContext'
import UserProvider from '~context/UserContext'
import '../styles/main.css'

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => <DefaultLayout>{page}</DefaultLayout>)

  return (
    <ApolloProvider client={client}>
      <FernandProvider>
        <MixpanelProvider>
          <NotificationsProvider>
            <UserProvider>
              <LogProvider>
                <CrewChangeOverviewFilterProvider>
                  <TravelAgentProvider>
                    <TravelAgentParsedFlightProvider>
                      <TravelAgentPnrProvider>
                        <TravelOfficeConfigProvider>
                          <ConfirmPopupPresenter>
                            <ToastProvider>
                              <BottomSheetProvider>
                                <Head>
                                  <title>Tilla</title>
                                  <meta name="description" content="Tilla App" />
                                  <meta name="robots" content="noindex, nofollow" />
                                  <link rel="icon" href="/favicon.ico" />
                                </Head>
                                <ErrorBoundary fallback={<ErrorFallback />}>
                                  <RouteGuard>{getLayout(<Component {...pageProps} />)}</RouteGuard>
                                </ErrorBoundary>
                              </BottomSheetProvider>
                            </ToastProvider>
                          </ConfirmPopupPresenter>
                        </TravelOfficeConfigProvider>
                      </TravelAgentPnrProvider>
                    </TravelAgentParsedFlightProvider>
                  </TravelAgentProvider>
                </CrewChangeOverviewFilterProvider>
              </LogProvider>
            </UserProvider>
          </NotificationsProvider>
        </MixpanelProvider>
      </FernandProvider>
    </ApolloProvider>
  )
}

export default App
