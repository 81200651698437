/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import {
  FlightWithSegmentsFragmentDoc,
  CompleteOfferFragmentDoc,
  CompleteFlightFragmentDoc,
} from './flight-fragments.generated'
export type FlightBookingFieldsFragment = {
  id: string
  active: boolean
  monitored: boolean
  ticketLine: string | null
  comment: string | null
  pnr: string | null
  status: Types.FlightBookingStatus
  amadeusSessionId: string | null
  managedByTravelTeam: boolean
  isAmadeus: boolean
  lastTicketingTime: string | null
  lastTicketingTimeSource: Types.LastTicketingTimeSource
  pendingUpdatedByTravelAgentNotification: boolean
  switchForIdenticalCheaperOfferAutomatically: boolean
  switchForSimilarCheaperOfferAutomatically: boolean
  ticketedAt: string | null
  bucketId: string | null
  autoTicket: boolean
  travelOfficeConfigurationId: string | null
  ticketLineData: { documentNumber: string } | null
  splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
}

export type CompleteFlightBookingFragment = {
  id: string
  active: boolean
  monitored: boolean
  ticketLine: string | null
  comment: string | null
  pnr: string | null
  status: Types.FlightBookingStatus
  amadeusSessionId: string | null
  managedByTravelTeam: boolean
  isAmadeus: boolean
  lastTicketingTime: string | null
  lastTicketingTimeSource: Types.LastTicketingTimeSource
  pendingUpdatedByTravelAgentNotification: boolean
  switchForIdenticalCheaperOfferAutomatically: boolean
  switchForSimilarCheaperOfferAutomatically: boolean
  ticketedAt: string | null
  bucketId: string | null
  autoTicket: boolean
  travelOfficeConfigurationId: string | null
  flight: {
    id: string
    updatedAt: string
    createdAt: string
    flightHash: string
    majorityCarrier: string | null
    travelOfficeConfigurationId: string
    validatingCarrier: string | null
    segments: Array<{
      id: string
      flightNumber: number
      index: number
      departure: {
        timezoneOlson: string | null
        time: string
        name: string | null
        iata: string
        countryAlpha2: string | null
        city: string | null
        date: string
        terminal: string | null
      }
      arrival: {
        timezoneOlson: string | null
        time: string
        name: string | null
        iata: string
        countryAlpha2: string | null
        city: string | null
        date: string
        terminal: string | null
      }
      marketingCarrier: {
        id: string
        createdAt: string
        updatedAt: string
        iata: string
        name: string
        logo: string | null
      } | null
      operatingCarrier: {
        id: string
        createdAt: string
        updatedAt: string
        iata: string
        name: string
        logo: string | null
      } | null
    }>
    travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
  }
  offer: {
    id: string
    amount: number
    tax: number | null
    passengerType: Types.PassengerType | null
    currency: string
    createdAt: string
    updatedAt: string
    flightId: string
    amadeusSessionId: string | null
    segmentFares: Array<{
      availableSeats: number | null
      fareTypes: Array<Types.FareType>
      fareBasisCode: string
      flightSegmentId: string
      bookingClass: string | null
      baggageDetails: {
        freeBagAllowance: number | null
        freeBagAllowanceType: Types.FreeBagAllowanceType | null
      }
    }>
    rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
    minirules: {
      beforeDeparture: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
      beforeDepartureNoShow: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
      afterDeparture: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
      afterDepartureNoShow: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
    } | null
    taxDetails: Array<{
      amount: number
      code: string | null
      isNonRefundable: boolean | null
    }> | null
  }
  finalOffer: { amount: number; currency: Types.Currency } | null
  seaman: {
    id: string
    firstName: string
    lastName: string
    nationalityIso: string
    rank: number | null
    birthday: string | null
    placeOfBirth: string | null
    firstVaccinationDate: string | null
    firstVaccinationBrand: string | null
    secondVaccinationDate: string | null
    secondVaccinationBrand: string | null
    updatedAt: string
  }
  ticketLineData: { documentNumber: string } | null
  splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
}

export type SeamanFieldsFragment = {
  id: string
  firstName: string
  lastName: string
  nationalityIso: string
  rank: number | null
  birthday: string | null
  placeOfBirth: string | null
  firstVaccinationDate: string | null
  firstVaccinationBrand: string | null
  secondVaccinationDate: string | null
  secondVaccinationBrand: string | null
  updatedAt: string
}

export type CompleteSeamanFragment = {
  id: string
  firstName: string
  lastName: string
  nationalityIso: string
  rank: number | null
  birthday: string | null
  placeOfBirth: string | null
  firstVaccinationDate: string | null
  firstVaccinationBrand: string | null
  secondVaccinationDate: string | null
  secondVaccinationBrand: string | null
  updatedAt: string
  seamanBooks: Array<{
    id: string
    bookNumber: string
    issueAt: string | null
    expiryAt: string | null
    isFlagStateSeamanBook: boolean
    nationalityAlpha2: string | null
  }>
  seamanPassports: Array<{
    id: string
    passportNumber: string
    passportIssuePlace: string | null
    passportNationalityIso: string
    passportIssueAt: string
    passportExpiryAt: string
  }>
  seamanVisas: Array<{
    id: string
    visaNumber: string
    visaName: string
    issueAt: string
    expiryAt: string
  }>
  seamanContracts: Array<{
    id: string
    hanseaticAssignmentId: number
    contractFrom: string
    contractUntil: string
  }>
  seamanRank: { name: string; sortOrder: number | null } | null
  seamanCommunications: Array<{ id: string; type: string; value: string | null }>
}

export type CompletePortAgencyFragment = {
  id: string
  name: string
  type: Types.PortAgencyType
  countryAlpha2: string
  state: string | null
  zipCode: string | null
  city: string | null
  address: string | null
  email: string | null
  phone: string | null
  portAgents: Array<{
    id: string
    role: string | null
    mobile: string | null
    user: {
      id: string
      firstName: string | null
      lastName: string | null
      email: string
      countryAlpha2: string | null
      timezoneOlson: string | null
    }
  }>
  portAgencyServices: Array<{
    id: string
    name: string
    category: Types.PortAgencyServiceCategory
    unit: Types.PortAgencyServiceUnit
    priceMin: string | null
    priceMax: string | null
    currencyAlpha3: string | null
  }>
}

export type PortAgentFieldsFragment = {
  id: string
  role: string | null
  mobile: string | null
  user: {
    id: string
    firstName: string | null
    lastName: string | null
    email: string
    countryAlpha2: string | null
    timezoneOlson: string | null
  }
}

export type PortAgencyServiceFieldsFragment = {
  id: string
  name: string
  category: Types.PortAgencyServiceCategory
  unit: Types.PortAgencyServiceUnit
  priceMin: string | null
  priceMax: string | null
  currencyAlpha3: string | null
}

export type FlightRecommendationResultFieldsFragment = {
  result: Types.FlightRecommendationResultType
  scheduleId: string
  seamanId: string
  flights: Array<{
    id: string
    status: Types.FlightBookingStatus
    active: boolean
    scheduleId: string
    planned: string
    createdAt: string
    updatedAt: string
    bucketId: string | null
    flight: {
      id: string
      updatedAt: string
      createdAt: string
      flightHash: string
      majorityCarrier: string | null
      travelOfficeConfigurationId: string
      validatingCarrier: string | null
      segments: Array<{
        id: string
        flightNumber: number
        index: number
        departure: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        arrival: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }>
      offers: Array<{
        id: string
        amount: number
        tax: number | null
        passengerType: Types.PassengerType | null
        currency: string
        createdAt: string
        updatedAt: string
        flightId: string
        amadeusSessionId: string | null
        segmentFares: Array<{
          availableSeats: number | null
          fareTypes: Array<Types.FareType>
          fareBasisCode: string
          flightSegmentId: string
          bookingClass: string | null
          baggageDetails: {
            freeBagAllowance: number | null
            freeBagAllowanceType: Types.FreeBagAllowanceType | null
          }
        }>
        rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
        minirules: {
          beforeDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          beforeDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
        } | null
        taxDetails: Array<{
          amount: number
          code: string | null
          isNonRefundable: boolean | null
        }> | null
      }>
      travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
    }
    offer: {
      id: string
      amount: number
      tax: number | null
      passengerType: Types.PassengerType | null
      currency: string
      createdAt: string
      updatedAt: string
      flightId: string
      amadeusSessionId: string | null
      segmentFares: Array<{
        availableSeats: number | null
        fareTypes: Array<Types.FareType>
        fareBasisCode: string
        flightSegmentId: string
        bookingClass: string | null
        baggageDetails: {
          freeBagAllowance: number | null
          freeBagAllowanceType: Types.FreeBagAllowanceType | null
        }
      }>
      rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
      minirules: {
        beforeDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        beforeDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
      } | null
      taxDetails: Array<{
        amount: number
        code: string | null
        isNonRefundable: boolean | null
      }> | null
    }
  }> | null
}

export type FlightRecommendationFieldsFragment = {
  id: string
  status: Types.FlightBookingStatus
  active: boolean
  scheduleId: string
  planned: string
  createdAt: string
  updatedAt: string
  bucketId: string | null
  flight: {
    id: string
    updatedAt: string
    createdAt: string
    flightHash: string
    majorityCarrier: string | null
    travelOfficeConfigurationId: string
    validatingCarrier: string | null
    segments: Array<{
      id: string
      flightNumber: number
      index: number
      departure: {
        timezoneOlson: string | null
        time: string
        name: string | null
        iata: string
        countryAlpha2: string | null
        city: string | null
        date: string
        terminal: string | null
      }
      arrival: {
        timezoneOlson: string | null
        time: string
        name: string | null
        iata: string
        countryAlpha2: string | null
        city: string | null
        date: string
        terminal: string | null
      }
      marketingCarrier: {
        id: string
        createdAt: string
        updatedAt: string
        iata: string
        name: string
        logo: string | null
      } | null
      operatingCarrier: {
        id: string
        createdAt: string
        updatedAt: string
        iata: string
        name: string
        logo: string | null
      } | null
    }>
    offers: Array<{
      id: string
      amount: number
      tax: number | null
      passengerType: Types.PassengerType | null
      currency: string
      createdAt: string
      updatedAt: string
      flightId: string
      amadeusSessionId: string | null
      segmentFares: Array<{
        availableSeats: number | null
        fareTypes: Array<Types.FareType>
        fareBasisCode: string
        flightSegmentId: string
        bookingClass: string | null
        baggageDetails: {
          freeBagAllowance: number | null
          freeBagAllowanceType: Types.FreeBagAllowanceType | null
        }
      }>
      rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
      minirules: {
        beforeDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        beforeDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
      } | null
      taxDetails: Array<{
        amount: number
        code: string | null
        isNonRefundable: boolean | null
      }> | null
    }>
    travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
  }
  offer: {
    id: string
    amount: number
    tax: number | null
    passengerType: Types.PassengerType | null
    currency: string
    createdAt: string
    updatedAt: string
    flightId: string
    amadeusSessionId: string | null
    segmentFares: Array<{
      availableSeats: number | null
      fareTypes: Array<Types.FareType>
      fareBasisCode: string
      flightSegmentId: string
      bookingClass: string | null
      baggageDetails: {
        freeBagAllowance: number | null
        freeBagAllowanceType: Types.FreeBagAllowanceType | null
      }
    }>
    rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
    minirules: {
      beforeDeparture: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
      beforeDepartureNoShow: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
      afterDeparture: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
      afterDepartureNoShow: {
        changeAllowed: boolean | null
        changeMaxPenaltyAmount: number | null
        refundAllowed: boolean | null
        refundMaxPenaltyAmount: number | null
        revalidationAllowed: boolean | null
        revalidationMaxPenaltyAmount: number | null
        isoTicketValidityDate: string | null
        taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
      } | null
    } | null
    taxDetails: Array<{
      amount: number
      code: string | null
      isNonRefundable: boolean | null
    }> | null
  }
}

export type FlightBookingRefundFieldsFragment = {
  id: string
  status: Types.FlightBookingRefundStatus
  amount: number | null
  currencyCode: string | null
  decimalPlaces: string | null
  penalties: Array<{
    id: string
    amount: number
    currencyCode: string | null
    decimalPlaces: string | null
    penaltyType: string | null
    percentOrAmount: Types.PenaltyCalculationType | null
  }>
  taxes: Array<{
    id: string
    amount: number
    currencyCode: string
    decimalPlaces: string | null
    taxIsoCode: string
    category: string
  } | null>
}

export type CompleteFlightBookingRefundFragment = {
  id: string
  status: Types.FlightBookingRefundStatus
  amount: number | null
  currencyCode: string | null
  decimalPlaces: string | null
  flightBooking: {
    id: string
    active: boolean
    monitored: boolean
    ticketLine: string | null
    comment: string | null
    pnr: string | null
    status: Types.FlightBookingStatus
    amadeusSessionId: string | null
    managedByTravelTeam: boolean
    isAmadeus: boolean
    lastTicketingTime: string | null
    lastTicketingTimeSource: Types.LastTicketingTimeSource
    pendingUpdatedByTravelAgentNotification: boolean
    switchForIdenticalCheaperOfferAutomatically: boolean
    switchForSimilarCheaperOfferAutomatically: boolean
    ticketedAt: string | null
    bucketId: string | null
    autoTicket: boolean
    travelOfficeConfigurationId: string | null
    finalOffer: {
      amount: number
      fixedCommission: number
      fixedCommissionCurrency: Types.Currency
      percentageCommission: number
      currency: Types.Currency
    } | null
    flight: {
      id: string
      updatedAt: string
      createdAt: string
      flightHash: string
      majorityCarrier: string | null
      travelOfficeConfigurationId: string
      validatingCarrier: string | null
      segments: Array<{
        id: string
        flightNumber: number
        index: number
        departure: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        arrival: {
          timezoneOlson: string | null
          time: string
          name: string | null
          iata: string
          countryAlpha2: string | null
          city: string | null
          date: string
          terminal: string | null
        }
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }>
      travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
    }
    offer: {
      id: string
      amount: number
      tax: number | null
      passengerType: Types.PassengerType | null
      currency: string
      createdAt: string
      updatedAt: string
      flightId: string
      amadeusSessionId: string | null
      segmentFares: Array<{
        availableSeats: number | null
        fareTypes: Array<Types.FareType>
        fareBasisCode: string
        flightSegmentId: string
        bookingClass: string | null
        baggageDetails: {
          freeBagAllowance: number | null
          freeBagAllowanceType: Types.FreeBagAllowanceType | null
        }
      }>
      rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
      minirules: {
        beforeDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        beforeDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDeparture: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
        afterDepartureNoShow: {
          changeAllowed: boolean | null
          changeMaxPenaltyAmount: number | null
          refundAllowed: boolean | null
          refundMaxPenaltyAmount: number | null
          revalidationAllowed: boolean | null
          revalidationMaxPenaltyAmount: number | null
          isoTicketValidityDate: string | null
          taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
        } | null
      } | null
      taxDetails: Array<{
        amount: number
        code: string | null
        isNonRefundable: boolean | null
      }> | null
    }
    seaman: {
      id: string
      firstName: string
      lastName: string
      nationalityIso: string
      rank: number | null
      birthday: string | null
      placeOfBirth: string | null
      firstVaccinationDate: string | null
      firstVaccinationBrand: string | null
      secondVaccinationDate: string | null
      secondVaccinationBrand: string | null
      updatedAt: string
    }
    ticketLineData: { documentNumber: string } | null
    splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
  }
  penalties: Array<{
    id: string
    amount: number
    currencyCode: string | null
    decimalPlaces: string | null
    penaltyType: string | null
    percentOrAmount: Types.PenaltyCalculationType | null
  }>
  taxes: Array<{
    id: string
    amount: number
    currencyCode: string
    decimalPlaces: string | null
    taxIsoCode: string
    category: string
  } | null>
}

export type VesselFieldsFragment = {
  id: string
  vesselScheduleSource: Types.VesselScheduleSource
  charterName: string
  imo: number
}

export type ParsedFlightOfferFieldsFragment = {
  baggageWeight: number | null
  baggagePieces: number | null
  fareAmount: number | null
  fareCurrency: Types.Currency | null
  fareBasisCode: string | null
  refundable: Types.ParsedRefundInfo | null
  ltd: string | null
  marketingCarrier: string | null
  segments: Array<{
    carrier: string
    flightNumber: number
    arrival: { dateTime: string; iata: string }
    departure: { dateTime: string; iata: string }
  } | null>
}

export type ParsedSegmentInfoFieldsFragment = { dateTime: string; iata: string }

export const SeamanFieldsFragmentDoc = gql`
  fragment SeamanFields on Seaman {
    id
    firstName
    lastName
    nationalityIso
    rank
    birthday
    placeOfBirth
    firstVaccinationDate
    firstVaccinationBrand
    secondVaccinationDate
    secondVaccinationBrand
    updatedAt
  }
`
export const CompleteSeamanFragmentDoc = gql`
  fragment CompleteSeaman on Seaman {
    ...SeamanFields
    seamanBooks {
      id
      bookNumber
      issueAt
      expiryAt
      isFlagStateSeamanBook
      nationalityAlpha2
    }
    seamanPassports {
      id
      passportNumber
      passportIssuePlace
      passportNationalityIso
      passportIssueAt
      passportExpiryAt
    }
    seamanVisas {
      id
      visaNumber
      visaName
      issueAt
      expiryAt
    }
    seamanContracts {
      id
      hanseaticAssignmentId
      contractFrom
      contractUntil
    }
    seamanRank {
      name
      sortOrder
    }
    seamanCommunications {
      id
      type
      value
    }
  }
  ${SeamanFieldsFragmentDoc}
`
export const PortAgentFieldsFragmentDoc = gql`
  fragment PortAgentFields on PortAgencyUser {
    id
    role
    mobile
    user {
      id
      firstName
      lastName
      email
      countryAlpha2
      timezoneOlson
    }
  }
`
export const PortAgencyServiceFieldsFragmentDoc = gql`
  fragment PortAgencyServiceFields on PortAgencyService {
    id
    name
    category
    unit
    priceMin
    priceMax
    currencyAlpha3
  }
`
export const CompletePortAgencyFragmentDoc = gql`
  fragment CompletePortAgency on PortAgency {
    id
    name
    type
    countryAlpha2
    state
    zipCode
    city
    address
    email
    phone
    portAgents {
      ...PortAgentFields
    }
    portAgencyServices {
      ...PortAgencyServiceFields
    }
  }
  ${PortAgentFieldsFragmentDoc}
  ${PortAgencyServiceFieldsFragmentDoc}
`
export const FlightRecommendationFieldsFragmentDoc = gql`
  fragment FlightRecommendationFields on FlightRecommendation {
    id
    status
    active
    scheduleId
    planned
    createdAt
    updatedAt
    bucketId
    flight {
      ...CompleteFlight
    }
    offer {
      ...CompleteOffer
    }
  }
  ${CompleteFlightFragmentDoc}
  ${CompleteOfferFragmentDoc}
`
export const FlightRecommendationResultFieldsFragmentDoc = gql`
  fragment FlightRecommendationResultFields on FlightRecommendationResult {
    result
    scheduleId
    seamanId
    flights {
      ...FlightRecommendationFields
    }
  }
  ${FlightRecommendationFieldsFragmentDoc}
`
export const FlightBookingRefundFieldsFragmentDoc = gql`
  fragment FlightBookingRefundFields on FlightBookingRefund {
    id
    status
    amount
    currencyCode
    decimalPlaces
    penalties {
      id
      amount
      currencyCode
      decimalPlaces
      penaltyType
      percentOrAmount
    }
    taxes {
      id
      amount
      currencyCode
      decimalPlaces
      taxIsoCode
      category
    }
  }
`
export const FlightBookingFieldsFragmentDoc = gql`
  fragment FlightBookingFields on FlightBooking {
    id
    active
    monitored
    ticketLine
    comment
    pnr
    status
    amadeusSessionId
    managedByTravelTeam
    isAmadeus
    lastTicketingTime
    lastTicketingTimeSource
    pendingUpdatedByTravelAgentNotification
    switchForIdenticalCheaperOfferAutomatically
    switchForSimilarCheaperOfferAutomatically
    ticketedAt
    bucketId
    ticketedAt
    ticketLineData {
      documentNumber
    }
    splitTicketingInfo {
      splitTicketingBookingId
      index
    }
    autoTicket
    travelOfficeConfigurationId
  }
`
export const CompleteFlightBookingFragmentDoc = gql`
  fragment CompleteFlightBooking on FlightBooking {
    ...FlightBookingFields
    flight {
      ...FlightWithSegments
    }
    offer {
      ...CompleteOffer
    }
    finalOffer {
      amount
      currency
    }
    seaman {
      ...SeamanFields
    }
  }
  ${FlightBookingFieldsFragmentDoc}
  ${FlightWithSegmentsFragmentDoc}
  ${CompleteOfferFragmentDoc}
  ${SeamanFieldsFragmentDoc}
`
export const CompleteFlightBookingRefundFragmentDoc = gql`
  fragment CompleteFlightBookingRefund on FlightBookingRefund {
    ...FlightBookingRefundFields
    flightBooking {
      ...CompleteFlightBooking
      finalOffer {
        amount
        fixedCommission
        fixedCommissionCurrency
        percentageCommission
      }
    }
  }
  ${FlightBookingRefundFieldsFragmentDoc}
  ${CompleteFlightBookingFragmentDoc}
`
export const VesselFieldsFragmentDoc = gql`
  fragment VesselFields on Vessel {
    id
    vesselScheduleSource
    charterName
    imo
  }
`
export const ParsedSegmentInfoFieldsFragmentDoc = gql`
  fragment ParsedSegmentInfoFields on ParsedSegmentInfo {
    dateTime
    iata
  }
`
export const ParsedFlightOfferFieldsFragmentDoc = gql`
  fragment ParsedFlightOfferFields on ParsedFlightOffer {
    segments {
      arrival {
        ...ParsedSegmentInfoFields
      }
      departure {
        ...ParsedSegmentInfoFields
      }
      carrier
      flightNumber
    }
    baggageWeight
    baggagePieces
    fareAmount
    fareCurrency
    fareBasisCode
    refundable
    ltd
    marketingCarrier
  }
  ${ParsedSegmentInfoFieldsFragmentDoc}
`
