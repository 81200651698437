import { isRole } from './isRole'
import { Permissions } from './permissions'
import { PermissionsQuery } from './permissions-query'
import { Role, roles } from './roles'

export class RBAC {
  private roles: Map<Role, Permissions>

  constructor(rolesMap: Map<Role, Permissions>) {
    this.roles = rolesMap
  }

  can(role: string | Role): PermissionsQuery {
    if (!isRole(role)) {
      throw new Error(`Invalid role: ${role}`)
    }
    const permissions = this.roles.get(role)
    if (!permissions) {
      throw new Error(`Role ${role} not found`)
    }
    return new PermissionsQuery(permissions)
  }
}

const rbac = new RBAC(roles)

export { rbac }
