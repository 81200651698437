import { useCallback } from 'react'
import { useLoadPnrInfoLazyQuery } from '~api/landing-gql.generated'

export default function usePnrValidation() {
  const [loadPnrInfo, { loading: loadingAmadeusCheck }] = useLoadPnrInfoLazyQuery({
    fetchPolicy: 'network-only',
  })

  const isAmadeus = useCallback(
    async (pnrParam: string) => {
      const { data } = await loadPnrInfo({ variables: { params: { pnr: pnrParam } } })

      if (data?.pnrInfo) {
        return true
      }

      return false
    },
    [loadPnrInfo]
  )

  const isPnrInvalid = useCallback(
    ({ lazyValidation = true, pnr }: { lazyValidation?: boolean; pnr: string }) => {
      if (lazyValidation && pnr === '') {
        return false
      }

      return !pnr || pnr.length !== 6
    },
    []
  )

  return { isPnrInvalid, isAmadeus, loadingAmadeusCheck }
}
