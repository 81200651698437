import clsx from 'clsx'
import { UiVariants } from '../types'

export function getButtonVariantClasses(
  variant: UiVariants,
  disabled?: boolean,
  fullWidth?: boolean,
  selected = false
) {
  const fullWidthClass = clsx({ 'w-full text-center justify-center': fullWidth })
  const disabledClass = clsx({ 'opacity-60 cursor-not-allowed': disabled })

  const base = { fullWidthClass, disabledClass }

  let misc = ''
  let text = ''
  let background = ''
  let border = ''

  /*
   * Classes when two elements are placed in one container
   */
  let peerLeft = ''
  let peerRight = ''

  if (variant === 'outline') {
    misc = 'shadow-sm'
    text = clsx({ 'text-gray-800 hover:text-brand': !selected, 'text-indigo-500': selected })
    background = clsx({ 'bg-white': !selected, 'bg-indigo-100': selected })
    border = 'border border-gray-300  hover:border-brand'
    peerLeft = 'peer rounded-r-none border-r-0 hover:border-r'
    peerRight = 'peer-hover:border-l-0 hover:border-l rounded-l-none'

    return {
      base,
      misc,
      text,
      background,
      border,
      addons: { peerLeft, peerRight, fullWidth: fullWidthClass, disabled: disabledClass },
      all: clsx(Object.values(base), misc, text, background, border),
    }
  }

  if (variant === 'danger-outline') {
    misc = 'shadow-sm'
    text = 'text-red-500 hover:text-red-700'
    background = 'bg-white'
    border = 'border border-red-500  hover:border-red-700'
    peerLeft = 'peer rounded-r-none border-r-0 hover:border-r'
    peerRight = 'peer-hover:border-l-0 hover:border-l rounded-l-none border-l'

    return {
      base,
      misc,
      text,
      background,
      border,
      addons: { peerLeft, peerRight, fullWidth: fullWidthClass, disabled: disabledClass },
      all: clsx(Object.values(base), misc, text, background, border),
    }
  }

  if (variant === 'danger') {
    text = 'text-white'
    background = 'bg-red-600 hover:bg-red-700'
    peerLeft = 'peer rounded-r-none border-r-0 hover:border-r'
    peerRight = 'peer-hover:border-l-0 hover:border-l rounded-l-none border-l'

    return {
      base,
      text,
      background,
      addons: { peerLeft, peerRight, fullWidth: fullWidthClass, disabled: disabledClass },
      all: clsx(Object.values(base), text, background),
    }
  }

  if (variant === 'success') {
    text = 'text-white'
    background = 'bg-green-600 hover:bg-green-700'
    peerLeft = 'peer rounded-r-none border-r-0 hover:border-r border-green-700'
    peerRight = 'peer-hover:border-l-0 hover:border-l rounded-l-none border-l border-green-700'

    return {
      base,
      text,
      background,
      addons: { peerLeft, peerRight, fullWidth: fullWidthClass, disabled: disabledClass },
      all: clsx(Object.values(base), text, background),
    }
  }

  if (variant === 'warning') {
    text = 'text-white'
    background = 'bg-yellow-500 hover:bg-yellow-600'
    peerLeft = 'peer rounded-r-none border-r-0 hover:border-r'
    peerRight = 'peer-hover:border-l-0 hover:border-l rounded-l-none border-l'

    return {
      base,
      text,
      background,
      addons: { peerLeft, peerRight, fullWidth: fullWidthClass, disabled: disabledClass },
      all: clsx(Object.values(base), text, background),
    }
  }

  if (variant === 'loading') {
    text = 'text-indigo-500'
    background = 'bg-indigo-100'
    peerLeft = 'peer rounded-r-none border-r-0 hover:border-r'
    peerRight = 'peer-hover:border-l-0 hover:border-l rounded-l-none border-l'

    return {
      base,
      text,
      background,
      addons: { peerLeft, peerRight, fullWidth: fullWidthClass, disabled: disabledClass },
      all: clsx(Object.values(base), text, background),
    }
  }

  if (variant === 'custom') {
    return {
      base,
      text,
      background,
      addons: { peerLeft, peerRight, fullWidth: fullWidthClass, disabled: disabledClass },
      all: clsx(Object.values(base), text, background),
    }
  }

  // Default variant
  text = 'text-white'
  background = 'bg-brand hover:bg-blue-900'
  peerLeft = 'peer rounded-r-none border-r-0 hover:border-r'
  peerRight = 'peer-hover:border-l-0 hover:border-l rounded-l-none border-l'

  return {
    base,
    text,
    background,
    addons: { peerLeft, peerRight, fullWidth: fullWidthClass, disabled: disabledClass },
    all: clsx(Object.values(base), text, background),
  }
}
