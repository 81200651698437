import clsx from 'clsx'
import Link from 'next/link'
import React, { ReactNode, SVGProps, useState } from 'react'

import ChevronDownIcon from '../Icon/ChevronDownIcon'
import TooltipWarning from '../Tooltip/TooltipWarning'

import CardFooter from './CardFooter'
import CardHeader from './CardHeader'

export interface CardProps {
  title: string | ReactNode
  subtitle?: string
  children: ReactNode
  inset?: boolean
  icon?: ((props: SVGProps<SVGSVGElement>) => JSX.Element) | null
  variant?: 'default' | 'info' | 'success' | 'danger' | 'warning'
  collapsible?: boolean
  initialCollapsed?: boolean
  href?: string
  slim?: boolean
  warnings?: {
    content: string
    variant: React.ComponentProps<typeof TooltipWarning>['variant']
  }[]
}

function Card({
  children,
  icon,
  title,
  subtitle,
  inset = true,
  variant = 'default',
  collapsible = false,
  initialCollapsed = false,
  href,
  slim = false,
  warnings = [],
}: CardProps) {
  const [collapsed, setCollapsed] = useState(collapsible && initialCollapsed)
  const CardIcon = icon ?? null

  const card = (
    <div
      className={clsx(
        'bg-white border border-gray-200 rounded-lg shadow-sm',
        href && 'cursor-pointer'
      )}
    >
      <div
        className={clsx(
          'rounded-t-lg flex',
          { 'cursor-pointer': collapsible },
          { 'p-5': !slim },
          { 'p-3': slim }
        )}
        onClick={() => collapsible && setCollapsed((prev) => !prev)}
      >
        {CardIcon && (
          <div
            className={clsx([
              'grow-0 shrink-0 block w-6 h-6 mt-[-2px] text-gray-300 sm:mr-3',
              variant === 'default' && 'text-gray-300',
              variant === 'success' && 'text-green-600',
              variant === 'danger' && 'text-red-600',
              variant === 'warning' && 'text-orange-600',
              variant === 'info' && 'text-blue-600',
            ])}
          >
            <CardIcon />
          </div>
        )}
        <div className="flex flex-col w-full">
          <div className="flex flex-row gap-2">
            {typeof title === 'string' ? (
              <h3 className="text-xl font-bold leading-none text-gray-800">{title}</h3>
            ) : (
              title
            )}
            {warnings.map((w, idx) => {
              return (
                <TooltipWarning
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  content={w.content}
                  variant={w.variant}
                  placement="right"
                />
              )
            })}
          </div>
          {subtitle && (
            <div
              className={clsx([
                'text-xs leading-none pt-3',
                variant === 'default' && 'text-gray-500',
                variant === 'success' && 'text-green-500',
                variant === 'danger' && 'text-red-500',
                variant === 'warning' && 'text-orange-500',
                variant === 'info' && 'text-blue-500',
              ])}
            >
              {subtitle && <span>{subtitle}</span>}
            </div>
          )}
        </div>
        {collapsible && (
          <span className="grow-0 shrink-0 block w-6 h-6  mt-[-2px] text-gray-300 sm:mr-3 ml-auto">
            <ChevronDownIcon className={clsx({ 'transform-gpu rotate-180': !collapsed })} />
          </span>
        )}
      </div>

      {!collapsed && (
        <div className={clsx(['space-y-2 text-sm rounded-b-lg', inset && 'pb-5 px-5'])}>
          <div>{children}</div>
        </div>
      )}
    </div>
  )

  if (href) {
    return <Link href={href}>{card}</Link>
  }

  return card
}

Card.Footer = CardFooter
Card.Header = CardHeader

export default Card
