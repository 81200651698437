import { useMemo } from 'react'
import Badge from '~components/ui/Badge/Badge'

interface Props {
  status: 'clean' | 'started' | 'completed'
}

const CrewChangeStatusBadge = ({ status }: Props) => {
  const variant = useMemo(() => {
    if (status === 'completed') {
      return 'success'
    }

    if (status === 'started') {
      return 'info'
    }

    return 'default'
  }, [status])

  const label = useMemo(() => {
    if (status === 'completed') {
      return 'Completed'
    }

    if (status === 'clean') {
      return 'Not started'
    }

    return 'In progress'
  }, [status])

  return (
    <Badge variant={variant} icon={null}>
      {label}
    </Badge>
  )
}

export default CrewChangeStatusBadge
