import React, { ReactNode } from 'react'

export interface AppShellActionsProps {
  children?: ReactNode
}

function AppShellNavigation({ children }: AppShellActionsProps): JSX.Element {
  return (
    <ul className="flex items-center space-x-4">
      {React.Children.map(children, (child) => {
        return <li className="h-full flex items-center">{child}</li>
      })}
    </ul>
  )
}

export default AppShellNavigation
