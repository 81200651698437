import clsx from 'clsx'
import { useCallback, useContext } from 'react'
import ArchivedNotificationsIcon from '~components/ui/Icon/ArchivedNotificationsIcon'
import MenuVsIcon from '~components/ui/Icon/MenuVsIcon'
import TabBarHeader from '~components/ui/TabBar/TabBarHeader'
import TabBarNavigation from '~components/ui/TabBar/TabBarNavigation'
import TabBarNavigationItem from '~components/ui/TabBar/TabBarNavigationItem'
import { NotificationPanelTabs, NotificationsContext } from '~context/NotificationsContext'
import { useTrackEvent } from '~hooks/useTrackEvent'
import FlightCancelledIcon from '../ui/Icon/FlightCancelledIcon'
import GlassesIcon from '../ui/Icon/GlassesIcon'

interface NotificationsTabHeaderProps {
  currentTab: NotificationPanelTabs
  setCurrentTab: (tab: NotificationPanelTabs) => void
}

function NotificationTab({
  active,
  onClick,
  notificationsCount,
  icon,
}: {
  active: boolean
  onClick: () => void
  notificationsCount: number
  icon: React.ReactNode
}) {
  return (
    <TabBarNavigationItem isActive={active} onClick={onClick}>
      <div
        className={clsx(
          'flex flex-col items-center justify-end h-full pb-1',
          active ? 'text-brand' : 'text-black'
        )}
      >
        {icon}
        <span
          className={clsx(
            'rounded-full bg-opacity-10 p-1 mt-1.5 text-xxs',
            active ? 'bg-brand text-white' : 'bg-black'
          )}
        >
          {notificationsCount}
        </span>
      </div>
    </TabBarNavigationItem>
  )
}

export default function NotificationsTabHeader(props: NotificationsTabHeaderProps) {
  const { currentTab, setCurrentTab } = props

  const {
    dismissedNotificationsCount,
    notificationsCount,
    changedSegmentsNotificationsCount,
    cheaperOffersNotificationsCount,
  } = useContext(NotificationsContext)
  const trackEvent = useTrackEvent()

  const handleTabClick = useCallback(
    (tab: NotificationPanelTabs) => {
      setCurrentTab(tab)
      trackEvent({
        event: 'Viewed notification tab',
        metadata: { mixpanelProperties: { tab } },
      })
    },
    [setCurrentTab, trackEvent]
  )

  return (
    <TabBarHeader>
      <TabBarNavigation>
        <NotificationTab
          active={currentTab === NotificationPanelTabs.Active}
          onClick={() => handleTabClick(NotificationPanelTabs.Active)}
          notificationsCount={notificationsCount}
          icon={<MenuVsIcon height={28} className="-mr-[9px] -mb-[10px]" />}
        />
        <NotificationTab
          active={currentTab === NotificationPanelTabs.Cancelled}
          onClick={() => handleTabClick(NotificationPanelTabs.Cancelled)}
          notificationsCount={changedSegmentsNotificationsCount}
          icon={<FlightCancelledIcon height={16} width={16} />}
        />
        <NotificationTab
          active={currentTab === NotificationPanelTabs.CheaperOffers}
          onClick={() => handleTabClick(NotificationPanelTabs.CheaperOffers)}
          notificationsCount={cheaperOffersNotificationsCount}
          icon={<GlassesIcon height={16} width={16} />}
        />
        <NotificationTab
          active={currentTab === NotificationPanelTabs.Dismissed}
          onClick={() => handleTabClick(NotificationPanelTabs.Dismissed)}
          notificationsCount={dismissedNotificationsCount}
          icon={<ArchivedNotificationsIcon height={28} className="-mr-[9px] -mb-[10px]" />}
        />
      </TabBarNavigation>
    </TabBarHeader>
  )
}
