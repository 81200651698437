import mixpanel, { OverridedMixpanel } from 'mixpanel-browser'
import React, { ReactNode, useContext, useEffect, useMemo, useRef } from 'react'
import { isProd } from 'utils/checkEnv'
import { MixpanelEvent } from '~hooks/useTrackEvent'
import { Me } from './UserContext'

export interface MixpanelSuperProperties {
  userId: string
  user: string
  tenantId: string
}

interface IMixpanelContext {
  mixpanel: OverridedMixpanel
  trackMixpanelEvent: (event: MixpanelEvent, properties?: Record<string, unknown>) => void
  upsertMixpanelSuperProperties: (properties: Partial<MixpanelSuperProperties>) => void
  setUserProperties: (user: Me) => void
  resetMixpanel: () => void
}

export const MixpanelContext = React.createContext<IMixpanelContext>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mixpanel,
  trackMixpanelEvent: () => undefined,
  upsertMixpanelSuperProperties: () => undefined,
  setUserProperties: () => undefined,
  resetMixpanel: () => undefined,
})

interface Props {
  children: ReactNode
}

export default function MixpanelProvider({ children }: Props) {
  const didInit = useRef(false)

  const shouldInit = !didInit.current && !!process.env.NEXT_PUBLIC_MIXPANEL_TOKEN

  // Init if necessary
  useEffect(() => {
    if (shouldInit) {
      mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN ?? '', {
        debug: !isProd(),
        loaded: () => {
          didInit.current = true
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const trackMixpanelEvent = (event: MixpanelEvent, properties?: Record<string, unknown>) => {
    if (didInit.current) {
      mixpanel.track(event, properties)
    }
  }

  const setUserProperties = (user: Me) => {
    if (didInit.current) {
      mixpanel.identify(user.id)

      /**
       * There are the ones that get shown by default on the Users overview table
       * If we don't use these specific names MP does not recognize them and does not display them
       * (or displays default values in the case of city/region/country)
       * */
      const mixpanelDefaultProps = {
        $name: `${user.firstName} ${user.lastName}`,
        $email: user.email,
        $city: user.city,
        $country_code: user.countryAlpha2,
      }

      /**
       * Custom props added by us. We can configure the overview columns to display them,
       * or view them in each user's detail page
       */
      const customProps = {
        Role: user.role,
        Tenant: user.tenant?.name,
      }

      mixpanel.people.set({
        ...mixpanelDefaultProps,
        ...customProps,
      })
    }
  }

  const upsertMixpanelSuperProperties = (properties: Partial<MixpanelSuperProperties>) => {
    if (didInit.current) {
      mixpanel.register(properties)
    }
  }

  const resetMixpanel = () => {
    if (didInit.current) {
      mixpanel.reset()
    }
  }

  const value = useMemo(
    () => ({
      mixpanel,
      trackMixpanelEvent,
      upsertMixpanelSuperProperties,
      setUserProperties,
      resetMixpanel,
    }),
    []
  )

  return <MixpanelContext.Provider value={value}>{children}</MixpanelContext.Provider>
}

export function useMixpanel() {
  const { trackMixpanelEvent, upsertMixpanelSuperProperties, setUserProperties, resetMixpanel } =
    useContext(MixpanelContext)

  return { trackMixpanelEvent, upsertMixpanelSuperProperties, setUserProperties, resetMixpanel }
}
