import React, { ReactNode } from 'react'

export interface AppShellNavigationProps {
  children?: ReactNode
}

function AppShellNavigation({ children }: AppShellNavigationProps): JSX.Element {
  return (
    <ul className="block items-center space-y-6 lg:flex lg:space-x-8 lg:space-y-0 lg:px-0">
      {React.Children.map(children, (child) => (
        <li className="h-full whitespace-nowrap">{child}</li>
      ))}
    </ul>
  )
}

export default AppShellNavigation
