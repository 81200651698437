import { DateTime } from 'luxon'
import { useContext, useState } from 'react'
import Alert from '~components/ui/Alert/Alert'
import Modal from '~components/ui/Modal/Modal'
import ToggleSwitch from '~components/ui/ToggleSwitch/ToggleSwitch'
import { TravelOfficeConfigContext } from '~context/TravelOfficeConfigContext'

interface Props {
  onClose: () => void
}

export default function TravelOfficeSettingsModal({ onClose }: Props) {
  const { currentTravelOffices, updateTravelOffice } = useContext(TravelOfficeConfigContext)

  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const onToggle = async (id: string, active: boolean) => {
    setShowSuccessMessage(false)
    await updateTravelOffice(id, active)
    setShowSuccessMessage(true)
  }

  return (
    <Modal open onClose={onClose} title="Travel office settings" inset>
      {currentTravelOffices.length > 0 && (
        <div className="flex flex-col space-y-2">
          {showSuccessMessage && <Alert title="Changes saved successfully" variant="success" />}
          {currentTravelOffices.map((value) => (
            <div
              className="flex flex-col space-y-4 items-center justify-between px-4 py-3 border border-gray-200 rounded-lg xl:flex-row xl:space-y-0 xl:space-x-4"
              key={value.id}
            >
              <div className="flex flex-col space-y-1">
                <span className="font-semibold">
                  OID: <span className="text-brand">{value.officeId}</span>
                </span>
                <span className="text-xs">
                  Since {DateTime.fromISO(value.updatedAt).toRelative()}
                </span>
              </div>
              <ToggleSwitch
                label={value.active ? 'Active' : 'Inactive'}
                toggled={value.active}
                onToggle={() => onToggle(value.id, !value.active)}
              />
            </div>
          ))}
        </div>
      )}
    </Modal>
  )
}
