import { DateTime } from 'luxon'
import { formatToLocalDateTime } from '../../../utils/formatDateTime'
import { VesselScheduleAbridgedSummary } from '../types'

export const formatVesselScheduleToLocalTime = (vesselSchedule: VesselScheduleAbridgedSummary) => {
  const timezone = vesselSchedule.seaport.timezoneOlson

  const parsed = [
    DateTime.fromISO(vesselSchedule.ETA.startsWith('9999') ? '' : vesselSchedule.ETA),
    DateTime.fromISO(vesselSchedule.ETB.startsWith('9999') ? '' : vesselSchedule.ETB),
    DateTime.fromISO(vesselSchedule.ETD.startsWith('9999') ? '' : vesselSchedule.ETD),
  ]

  return parsed.map((dateTime: DateTime | null) => {
    return formatToLocalDateTime(dateTime, timezone)
  })
}
