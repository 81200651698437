import { formatCurrency } from '@tilla/backend/shared/utils/currency'
import { maxBy } from 'lodash'
import { useCallback, useContext, useState } from 'react'
import { NotificationsQuery } from '~api/notifications-gql.generated'
import { NotificationType } from '~api/types.generated'
import GlassesIcon from '~components/ui/Icon/GlassesIcon'
import Icon from '~components/ui/Icon/Icon'
import { NotificationPanelTabs, NotificationsContext } from '~context/NotificationsContext'
import useCurrencyConvert from '~hooks/useCurrencyConvert'
import useRelativeDateTime from '~hooks/useRelativeDateTime'
import { useTrackEvent } from '~hooks/useTrackEvent'
import CheaperOfferNotificationModal from '../CheaperOfferNotificationModal'
import DismissNotificationButton from './DismissNotificationButton'

interface Props {
  notification: NotificationsQuery['notifications'][number]
}

export default function CheaperOfferCard({ notification }: Props) {
  const { dismissNotifications, currentTab } = useContext(NotificationsContext)
  const [isHovered, setIsHovered] = useState(false)
  const [isDismissing, setIsDismissing] = useState(false)
  const { tenantCurrency, convertToTenantCurrency } = useCurrencyConvert()
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false)
  const cheaperOfferNotificationsForBucket = notification.cheaperOfferNotifications

  const isPlural = cheaperOfferNotificationsForBucket.length > 1
  const relativeCreatedAt = useRelativeDateTime(
    maxBy(cheaperOfferNotificationsForBucket, 'notification.createdAt')?.notification.createdAt ??
      new Date().toISOString()
  )
  const trackEvent = useTrackEvent()

  const totalSavings = cheaperOfferNotificationsForBucket.reduce((acc, curr) => {
    const cheaperAmount = convertToTenantCurrency(
      curr.cheaperOffer.flightOffer.amount,
      curr.cheaperOffer.flightOffer.currency
    )
    const originalAmount = convertToTenantCurrency(
      curr.cheaperOffer.flightBooking.offer.amount,
      curr.cheaperOffer.flightBooking.offer.currency
    )

    const savings = originalAmount - cheaperAmount

    return acc + savings
  }, 0)

  const handleNotificationClick = useCallback(() => {
    // Only verify offers if notification is active
    if (currentTab !== NotificationPanelTabs.Active) {
      return
    }
    setIsVerificationModalOpen(true)
    trackEvent({
      event: 'Clicked on a notification',
      metadata: {
        mixpanelProperties: {
          type: NotificationType.CheaperOffers,
          age: relativeCreatedAt,
          vessel: notification.vessel.charterName,
          ETA: notification.vesselSchedule.ETA,
          ccUrl: `https://${window.location.host}/crew-changes/${notification.bucketId}`,
        },
      },
    })
  }, [
    currentTab,
    notification.bucketId,
    notification.vessel.charterName,
    notification.vesselSchedule.ETA,
    relativeCreatedAt,
    trackEvent,
  ])

  return (
    <div
      className="my-2 p-2 cursor-pointer hover:bg-indigo-50 active:bg-indigo-100 hover:rounded-lg"
      onMouseLeave={() => setIsHovered(false)}
      onMouseEnter={() => setIsHovered(true)}
    >
      <div className="flex flex-row my-2 mx-2">
        <div className="text-brand my-2 items-start mr-2">
          <Icon size="large" icon={GlassesIcon} className="text-indigo-500" />
        </div>

        <button
          type="button"
          onClick={handleNotificationClick}
          className="flex flex-row justify-between w-full"
        >
          <div className="flex flex-col items-start justify-start">
            <div className="flex flex-row gap-2 text-sm">
              <p className="text-sm font-semibold text-left">
                Found {cheaperOfferNotificationsForBucket.length} lower fare
                {`${isPlural ? 's' : ''}`} with total savings of up to{' '}
                <span className="text-brand">{formatCurrency(totalSavings, tenantCurrency)}</span>
              </p>
            </div>
            <p className="text-sm mt-2">{relativeCreatedAt}</p>
          </div>
        </button>
        {isHovered && (
          <DismissNotificationButton
            dismissHandler={async (ev, restore) => {
              setIsDismissing(true)
              await dismissNotifications(
                cheaperOfferNotificationsForBucket.map((not) => not.notification),
                NotificationType.CheaperOffers,
                restore
              )
              setIsDismissing(false)
            }}
            isDismissing={isDismissing}
          />
        )}
      </div>
      <CheaperOfferNotificationModal
        notification={notification}
        isOpen={isVerificationModalOpen}
        onClose={() => setIsVerificationModalOpen(false)}
        onDismiss={async () => {
          setIsVerificationModalOpen(false)
          setIsDismissing(true)

          await dismissNotifications(
            cheaperOfferNotificationsForBucket.map((not) => not.notification),
            NotificationType.CheaperOffers,
            false
          )

          setIsDismissing(false)
        }}
      />
    </div>
  )
}
