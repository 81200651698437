import { omit } from 'lodash'
import { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
  hasNotification?: boolean
}

export default function GlassesIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      fill="currentColor"
      {...omit(props, ['hasNotification'])}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.583 107.25L157.583 42.7501C154.803 36.9945 148.975 33.3362 142.583 33.3334H129.166C126.865 33.3334 125 35.1989 125 37.5001V45.8334C125 48.1346 126.865 50.0001 129.166 50.0001H142.583L158.333 84.2501C140.656 80.4523 122.57 88.6334 113.75 104.417C109.701 101.624 104.918 100.088 99.9997 100C95.0109 100.048 90.1556 101.618 86.0831 104.5C77.2891 88.7739 59.3056 80.5751 41.6664 84.2501L57.4164 50.0001H70.8331C73.1343 50.0001 74.9997 48.1346 74.9997 45.8334V37.5001C74.9997 35.1989 73.1343 33.3334 70.8331 33.3334H57.4164C50.9261 33.3023 45.0081 37.0417 42.2497 42.9168L12.2497 107.417C9.65945 112.916 8.32174 118.921 8.33308 125C8.33308 148.012 26.9879 166.667 49.9997 166.667C73.0116 166.667 91.6664 148.012 91.6664 125C91.6664 120.398 95.3974 116.667 99.9997 116.667C104.602 116.667 108.333 120.398 108.333 125C108.333 148.012 126.988 166.667 150 166.667C173.012 166.667 191.666 148.012 191.666 125C191.65 118.852 190.255 112.787 187.583 107.25ZM49.9997 150C36.1926 150 24.9997 138.807 24.9997 125C24.9997 111.193 36.1926 100 49.9997 100C63.8069 100 74.9997 111.193 74.9997 125C74.9997 138.807 63.8069 150 49.9997 150ZM125 125C125 138.807 136.193 150 150 150C163.807 150 175 138.807 175 125C175 111.193 163.807 100 150 100C136.193 100 125 111.193 125 125Z"
      />
      {props.hasNotification && <circle cx="20" cy="30" r="20" fill="red" />}
    </svg>
  )
}
