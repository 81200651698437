/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type LoadAirportsQueryVariables = Types.Exact<{
  iatas: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']
}>

export type LoadAirportsQuery = {
  airports: Array<{
    id: string
    alternativeIatas: Array<string>
    city: string
    countryAlpha2: string
    iata: string
    name: string
    timezoneOlson: string | null
  }>
}

export type LoadAirportQueryVariables = Types.Exact<{
  iata: Types.Scalars['String']['input']
}>

export type LoadAirportQuery = {
  airport: {
    id: string
    alternativeIatas: Array<string>
    city: string
    countryAlpha2: string
    iata: string
    name: string
    timezoneOlson: string | null
  } | null
}

export type SearchAirportsQueryVariables = Types.Exact<{
  search: Types.Scalars['String']['input']
}>

export type SearchAirportsQuery = {
  airports: Array<{
    id: string
    alternativeIatas: Array<string>
    city: string
    countryAlpha2: string
    iata: string
    name: string
    timezoneOlson: string | null
  }>
}

export type LoadPopularAirportsQueryVariables = Types.Exact<{
  alpha2: Types.Scalars['String']['input']
}>

export type LoadPopularAirportsQuery = {
  popularAirports: Array<{
    id: string
    alternativeIatas: Array<string>
    city: string
    countryAlpha2: string
    iata: string
    name: string
    timezoneOlson: string | null
  }>
}

export type AirportFieldsFragment = {
  id: string
  alternativeIatas: Array<string>
  city: string
  countryAlpha2: string
  iata: string
  name: string
  timezoneOlson: string | null
}

export const AirportFieldsFragmentDoc = gql`
  fragment AirportFields on Airport {
    id
    alternativeIatas
    city
    countryAlpha2
    iata
    name
    timezoneOlson
  }
`
export const LoadAirportsDocument = gql`
  query LoadAirports($iatas: [String!]!) {
    airports(iatas: $iatas) {
      ...AirportFields
    }
  }
  ${AirportFieldsFragmentDoc}
`

/**
 * __useLoadAirportsQuery__
 *
 * To run a query within a React component, call `useLoadAirportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAirportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAirportsQuery({
 *   variables: {
 *      iatas: // value for 'iatas'
 *   },
 * });
 */
export function useLoadAirportsQuery(
  baseOptions: Apollo.QueryHookOptions<LoadAirportsQuery, LoadAirportsQueryVariables> &
    ({ variables: LoadAirportsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadAirportsQuery, LoadAirportsQueryVariables>(
    LoadAirportsDocument,
    options
  )
}
export function useLoadAirportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoadAirportsQuery, LoadAirportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadAirportsQuery, LoadAirportsQueryVariables>(
    LoadAirportsDocument,
    options
  )
}
export function useLoadAirportsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<LoadAirportsQuery, LoadAirportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LoadAirportsQuery, LoadAirportsQueryVariables>(
    LoadAirportsDocument,
    options
  )
}
export type LoadAirportsQueryHookResult = ReturnType<typeof useLoadAirportsQuery>
export type LoadAirportsLazyQueryHookResult = ReturnType<typeof useLoadAirportsLazyQuery>
export type LoadAirportsSuspenseQueryHookResult = ReturnType<typeof useLoadAirportsSuspenseQuery>
export type LoadAirportsQueryResult = Apollo.QueryResult<
  LoadAirportsQuery,
  LoadAirportsQueryVariables
>
export const LoadAirportDocument = gql`
  query LoadAirport($iata: String!) {
    airport(iata: $iata) {
      ...AirportFields
    }
  }
  ${AirportFieldsFragmentDoc}
`

/**
 * __useLoadAirportQuery__
 *
 * To run a query within a React component, call `useLoadAirportQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAirportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAirportQuery({
 *   variables: {
 *      iata: // value for 'iata'
 *   },
 * });
 */
export function useLoadAirportQuery(
  baseOptions: Apollo.QueryHookOptions<LoadAirportQuery, LoadAirportQueryVariables> &
    ({ variables: LoadAirportQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadAirportQuery, LoadAirportQueryVariables>(LoadAirportDocument, options)
}
export function useLoadAirportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoadAirportQuery, LoadAirportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadAirportQuery, LoadAirportQueryVariables>(
    LoadAirportDocument,
    options
  )
}
export function useLoadAirportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<LoadAirportQuery, LoadAirportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LoadAirportQuery, LoadAirportQueryVariables>(
    LoadAirportDocument,
    options
  )
}
export type LoadAirportQueryHookResult = ReturnType<typeof useLoadAirportQuery>
export type LoadAirportLazyQueryHookResult = ReturnType<typeof useLoadAirportLazyQuery>
export type LoadAirportSuspenseQueryHookResult = ReturnType<typeof useLoadAirportSuspenseQuery>
export type LoadAirportQueryResult = Apollo.QueryResult<LoadAirportQuery, LoadAirportQueryVariables>
export const SearchAirportsDocument = gql`
  query SearchAirports($search: String!) {
    airports(search: $search) {
      ...AirportFields
    }
  }
  ${AirportFieldsFragmentDoc}
`

/**
 * __useSearchAirportsQuery__
 *
 * To run a query within a React component, call `useSearchAirportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAirportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAirportsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchAirportsQuery(
  baseOptions: Apollo.QueryHookOptions<SearchAirportsQuery, SearchAirportsQueryVariables> &
    ({ variables: SearchAirportsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchAirportsQuery, SearchAirportsQueryVariables>(
    SearchAirportsDocument,
    options
  )
}
export function useSearchAirportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchAirportsQuery, SearchAirportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchAirportsQuery, SearchAirportsQueryVariables>(
    SearchAirportsDocument,
    options
  )
}
export function useSearchAirportsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SearchAirportsQuery, SearchAirportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SearchAirportsQuery, SearchAirportsQueryVariables>(
    SearchAirportsDocument,
    options
  )
}
export type SearchAirportsQueryHookResult = ReturnType<typeof useSearchAirportsQuery>
export type SearchAirportsLazyQueryHookResult = ReturnType<typeof useSearchAirportsLazyQuery>
export type SearchAirportsSuspenseQueryHookResult = ReturnType<
  typeof useSearchAirportsSuspenseQuery
>
export type SearchAirportsQueryResult = Apollo.QueryResult<
  SearchAirportsQuery,
  SearchAirportsQueryVariables
>
export const LoadPopularAirportsDocument = gql`
  query LoadPopularAirports($alpha2: String!) {
    popularAirports(alpha2: $alpha2) {
      ...AirportFields
    }
  }
  ${AirportFieldsFragmentDoc}
`

/**
 * __useLoadPopularAirportsQuery__
 *
 * To run a query within a React component, call `useLoadPopularAirportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadPopularAirportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadPopularAirportsQuery({
 *   variables: {
 *      alpha2: // value for 'alpha2'
 *   },
 * });
 */
export function useLoadPopularAirportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadPopularAirportsQuery,
    LoadPopularAirportsQueryVariables
  > &
    ({ variables: LoadPopularAirportsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LoadPopularAirportsQuery, LoadPopularAirportsQueryVariables>(
    LoadPopularAirportsDocument,
    options
  )
}
export function useLoadPopularAirportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadPopularAirportsQuery,
    LoadPopularAirportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LoadPopularAirportsQuery, LoadPopularAirportsQueryVariables>(
    LoadPopularAirportsDocument,
    options
  )
}
export function useLoadPopularAirportsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LoadPopularAirportsQuery,
    LoadPopularAirportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LoadPopularAirportsQuery, LoadPopularAirportsQueryVariables>(
    LoadPopularAirportsDocument,
    options
  )
}
export type LoadPopularAirportsQueryHookResult = ReturnType<typeof useLoadPopularAirportsQuery>
export type LoadPopularAirportsLazyQueryHookResult = ReturnType<
  typeof useLoadPopularAirportsLazyQuery
>
export type LoadPopularAirportsSuspenseQueryHookResult = ReturnType<
  typeof useLoadPopularAirportsSuspenseQuery
>
export type LoadPopularAirportsQueryResult = Apollo.QueryResult<
  LoadPopularAirportsQuery,
  LoadPopularAirportsQueryVariables
>
