import clsx from 'clsx'
import Link from 'next/link'
import { forwardRef, MouseEventHandler, ReactNode, SVGProps } from 'react'

export interface ActionMenuItemProps {
  href?: string
  hasFocus?: boolean
  children?: ReactNode
  onClick?: MouseEventHandler
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element
  iconPosition?: 'start' | 'end'
  color?: 'green'
}

const ActionMenuItem = forwardRef<HTMLDivElement & HTMLAnchorElement, ActionMenuItemProps>(
  function ActionMenuItem(
    {
      href,
      children,
      onClick,
      icon: ActionItemIcon,
      iconPosition = 'start',
      hasFocus,
      color,
    }: ActionMenuItemProps,
    ref
  ) {
    const Component = typeof href === 'string' ? Link : 'div'

    return (
      <Component
        href={href ?? ''}
        target="_blank"
        ref={ref}
        className={clsx(
          'flex items-center px-4 py-2 text-sm rounded-sm cursor-pointer',
          hasFocus && color !== 'green' && 'text-brand bg-blue-50',
          hasFocus && color === 'green' && 'text-emerald-600 bg-emerald-50',
          iconPosition === 'end' && 'flex-row-reverse justify-between w-full',
          color === 'green'
            ? 'text-emerald-500 hover:text-emerald-600 hover:bg-emerald-50'
            : 'text-gray-800 hover:bg-blue-50 hover:text-brand'
        )}
        onClick={onClick}
      >
        {ActionItemIcon && <ActionItemIcon className={clsx('w-4 h-4 mr-1.5')} />}
        {children}
      </Component>
    )
  }
)

export default ActionMenuItem
