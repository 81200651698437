import clsx from 'clsx'
import { ChangeEventHandler, ClassAttributes, TextareaHTMLAttributes, useState } from 'react'

type FormTextAreaProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> &
  ClassAttributes<HTMLTextAreaElement> & {
    className?: string
    characterLimit?: number
    onChange: (value: string) => void
  }

export default function FormTextArea({
  onChange,
  className,
  characterLimit,
  ...props
}: FormTextAreaProps): JSX.Element {
  const [characterCount, setCharacterCount] = useState(0)
  const [value, setValue] = useState('')

  const wrappedOnChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setCharacterCount(e.target.value.length)
    setValue(e.target.value)
    onChange(e.target.value)
  }

  return (
    <div
      className={clsx('h-full min-h-[10rem]', {
        'relative flex flex-col items-end justify-end': characterLimit,
      })}
    >
      <textarea
        value={props.value ? props.value : value}
        onChange={wrappedOnChange}
        rows={props.rows}
        maxLength={characterLimit}
        className={clsx(
          className,
          'h-full block w-full border border-gray-200 px-2.5 pt-2 pb-2.5 text-sm rounded-md focus:border-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-200 focus:opacity-100'
        )}
        {...props}
      />
      {characterLimit && (
        <div className="text-xs text-neutral-400 absolute bottom-4 right-4">{`${characterCount} / ${characterLimit}`}</div>
      )}
    </div>
  )
}
