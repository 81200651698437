export interface CrewChangeSeafarersCountProps {
  embarkingCount: number
  disembarkingCount: number
  hideTotal?: boolean
  hideZeros?: boolean
}

const defaults: CrewChangeSeafarersCountProps = {
  embarkingCount: 0,
  disembarkingCount: 0,
  hideTotal: false,
  hideZeros: false,
}

function CrewChangeSeafarersCount({
  embarkingCount,
  disembarkingCount,
  hideTotal,
  hideZeros,
}: CrewChangeSeafarersCountProps = defaults): JSX.Element {
  const total = embarkingCount + disembarkingCount

  const shouldShowTotal = !hideTotal && (total > 0 || hideZeros)
  const shouldShowEmbarkingCount = !(embarkingCount === 0 && hideZeros)
  const shouldShowDisembarkingCount = !(disembarkingCount === 0 && hideZeros)

  return (
    <span className="">
      {shouldShowTotal && (
        <span className="block mb-2 text-sm leading-none text-gray-800">{total} seafarers</span>
      )}
      <span className="flex items-center space-x-1.5 text-xs leading-none">
        {shouldShowEmbarkingCount && (
          <span className="flex items-center text-gray-500">{embarkingCount} on</span>
        )}
        {shouldShowEmbarkingCount && shouldShowDisembarkingCount && (
          <span className="w-0.5 h-0.5 mx-1 bg-gray-400 rounded-full" />
        )}
        {shouldShowDisembarkingCount && (
          <span className="flex items-center text-gray-500">{disembarkingCount} off</span>
        )}
      </span>
    </span>
  )
}

export default CrewChangeSeafarersCount
