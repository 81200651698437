/* eslint-disable react/jsx-props-no-spreading */
import { SVGProps } from 'react'

function RestoreIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        d="M13.7497 6.5C13.7497 9.6066 11.2313 12.125 8.12466 12.125C6.96992 12.1288 5.84268 11.7727 4.89966 11.1062C4.82432 11.052 4.77639 10.9675 4.76841 10.875C4.76096 10.7849 4.79287 10.696 4.85591 10.6312L5.30591 10.175C5.41155 10.0705 5.57619 10.0548 5.69966 10.1375C6.41604 10.6203 7.26076 10.8772 8.12466 10.875C10.5409 10.875 12.4997 8.91625 12.4997 6.5C12.4997 4.08375 10.5409 2.125 8.12466 2.125C5.70842 2.125 3.74966 4.08375 3.74966 6.5H5.22466C5.3094 6.49877 5.39087 6.53271 5.44966 6.59375L5.57466 6.71875C5.63382 6.77743 5.6671 6.8573 5.6671 6.94063C5.6671 7.02395 5.63382 7.10382 5.57466 7.1625L3.36841 9.375C3.24478 9.49486 3.0483 9.49486 2.92466 9.375L0.718414 7.1625C0.659253 7.10382 0.625977 7.02395 0.625977 6.94063C0.625977 6.8573 0.659253 6.77743 0.718414 6.71875L0.843414 6.59375C0.902211 6.53271 0.983672 6.49877 1.06841 6.5H2.49966C2.49966 3.3934 5.01806 0.875 8.12466 0.875C11.2313 0.875 13.7497 3.3934 13.7497 6.5Z"
        fill="#6467F2"
      />
    </svg>
  )
}

export default RestoreIcon
