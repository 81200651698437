/* eslint-disable react/jsx-props-no-spreading */
import React, { SVGProps } from 'react'

function WarningIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path d="M22.9,19.63,12.81,2.37A.76.76,0,0,0,12.16,2h-.3a.76.76,0,0,0-.65.37L1.1,19.63a.79.79,0,0,0,0,.76l.14.23a.73.73,0,0,0,.65.38H22.11a.73.73,0,0,0,.65-.38l.14-.23A.79.79,0,0,0,22.9,19.63ZM13,16.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5Zm-.22-3.72a.25.25,0,0,1-.25.22H11.47a.25.25,0,0,1-.25-.22l-.4-3.22a.5.5,0,0,1,.5-.56h1.36a.5.5,0,0,1,.5.56Z" />
    </svg>
  )
}

export default WarningIcon
