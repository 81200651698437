/* eslint-disable react/jsx-props-no-spreading */
import { SVGProps } from 'react'

function ArchivedNotificationsIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path d="M8.66657 14C11.9803 14 14.6666 11.3137 14.6666 8C14.6666 4.68629 11.9803 2 8.66657 2C5.35286 2 2.66657 4.68629 2.66657 8H1.1399C1.04951 7.99869 0.962619 8.03489 0.899902 8.1L0.766568 8.23333C0.703464 8.29592 0.667969 8.38112 0.667969 8.47C0.667969 8.55888 0.703464 8.64408 0.766568 8.70667L3.1199 11.0667C3.25178 11.1945 3.46136 11.1945 3.59324 11.0667L5.94657 8.70667C6.00967 8.64408 6.04517 8.55888 6.04517 8.47C6.04517 8.38112 6.00967 8.29592 5.94657 8.23333L5.81324 8.1C5.75052 8.03489 5.66363 7.99869 5.57324 8H3.9999C3.9999 5.42267 6.08924 3.33333 8.66657 3.33333C11.2439 3.33333 13.3332 5.42267 13.3332 8C13.3332 10.5773 11.2439 12.6667 8.66657 12.6667C7.74507 12.6691 6.84404 12.395 6.0799 11.88C5.9482 11.7918 5.77258 11.8085 5.6599 11.92L5.1799 12.4067C5.11265 12.4758 5.07861 12.5706 5.08657 12.6667C5.09507 12.7654 5.1462 12.8554 5.22657 12.9133C6.23246 13.6242 7.43484 14.004 8.66657 14ZM8.66657 6.66667C9.40295 6.66667 9.9999 7.26362 9.9999 8C9.9999 8.73638 9.40295 9.33333 8.66657 9.33333C7.93019 9.33333 7.33323 8.73638 7.33323 8C7.33323 7.26362 7.93019 6.66667 8.66657 6.66667Z" />
    </svg>
  )
}

export default ArchivedNotificationsIcon
