import { Dialog } from '@headlessui/react'
import clsx from 'clsx'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full'
  className?: string
}
export default function ModalContent({ children, width = 'lg', className }: Props) {
  const baseClass = clsx(
    className,
    `relative z-10 w-full bg-white rounded-lg shadow-md max-w-${width}`
  )

  return <Dialog.Panel className={baseClass}>{children}</Dialog.Panel>
}
