import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { UiVariants } from '~components/ui/types'

export interface BottomSheetItem {
  _id: string
  title: string
  onClick?: (item: BottomSheetItem) => void
  variant?: UiVariants
  left?: ReactNode
  right?: ReactNode
  children?: () => ReactElement
  toggled?: boolean
  disabled?: boolean
}

interface BottomSheetContextType {
  bottomSheetItems: BottomSheetItem[]
  isLoading: boolean
  addBottomSheetItem: (item: BottomSheetItem) => void
  removeBottomSheetItem: (id: string) => void
  updateBottomSheetItem: (id: string, item: Partial<BottomSheetItem>) => void
  setBottomSheetItems: Dispatch<SetStateAction<BottomSheetItem[]>>
  setIsLoading: Dispatch<SetStateAction<boolean>>
  setActiveItem: Dispatch<SetStateAction<BottomSheetItem | null>>
  activeItem: BottomSheetItem | null
  handleClose: (closeFully?: boolean) => void
  reset: () => void
}

const BottomSheetContext = createContext<BottomSheetContextType | undefined>(undefined)

export function BottomSheetProvider({ children }: { children: ReactNode }) {
  const [bottomSheetItems, setBottomSheetItems] = useState<BottomSheetItem[]>([])
  const [activeItem, setActiveItem] = useState<BottomSheetItem | null>(null)

  const [isLoading, setIsLoading] = useState(false)

  const addBottomSheetItem = useCallback((item: BottomSheetItem) => {
    setBottomSheetItems((prev) => [...prev, item])
  }, [])

  const removeBottomSheetItem = useCallback((id: string) => {
    setBottomSheetItems((prev) => prev.filter((item) => item._id !== id))
  }, [])

  const updateBottomSheetItem = useCallback((id: string, item: Partial<BottomSheetItem>) => {
    setBottomSheetItems((prev) =>
      prev.map((prevItem) => (prevItem._id === id ? { ...prevItem, ...item } : prevItem))
    )
  }, [])

  const reset = useCallback(() => {
    setActiveItem(null)
    setBottomSheetItems([])
    setIsLoading(false)
  }, [])

  const handleClose = useCallback(
    (closeFully = false) => {
      if (closeFully) {
        reset()
        return
      }

      if (activeItem) {
        // Go back to the main list
        setActiveItem(null)
      } else {
        // Close the bottom sheet
        reset()
      }
    },
    [activeItem, reset]
  )

  const contextValue = useMemo(
    () => ({
      isLoading,
      bottomSheetItems,
      addBottomSheetItem,
      updateBottomSheetItem,
      removeBottomSheetItem,
      setBottomSheetItems,
      setIsLoading,
      setActiveItem,
      activeItem,
      handleClose,
      reset,
    }),
    [
      isLoading,
      bottomSheetItems,
      addBottomSheetItem,
      updateBottomSheetItem,
      removeBottomSheetItem,
      activeItem,
      handleClose,
      reset,
    ]
  )

  return <BottomSheetContext.Provider value={contextValue}>{children}</BottomSheetContext.Provider>
}

export function useBottomSheet() {
  const context = useContext(BottomSheetContext)
  if (!context) {
    throw new Error('useBottomSheet must be used within a BottomSheetProvider')
  }
  return context
}
