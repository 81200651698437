import { GetConflictingSeamenForCrewChangeQuery } from '~api/manage-crew-changes-gql.generated'
import Alert from '~components/ui/Alert/Alert'
import Button from '~components/ui/Button/Button'
import BoatIcon from '~components/ui/Icon/BoatIcon'
import Icon from '~components/ui/Icon/Icon'
import PointOfInterestIcon from '~components/ui/Icon/PointOfInterestIcon'
import UsersSwitchIcon from '~components/ui/Icon/UsersSwitchIcon'
import VesselSchedule from '~components/VesselSchedule/VesselSchedule'

interface Props {
  conflictingCrewChangeSeamen: GetConflictingSeamenForCrewChangeQuery['getConflictingSeamenForCrewChange']
  onCancel: () => void
  onRestore: () => void
}

export default function RestoreCrewChangeModalConflictSeamanState({
  conflictingCrewChangeSeamen,
  onCancel,
  onRestore,
}: Props) {
  return (
    <>
      <div className="flex flex-col mb-6 px-6">
        <Alert variant="warning" title="Crew Change can be partially restored" />
      </div>
      <hr />
      <div className="flex flex-col space-y-5 py-4 px-6">
        <div className="gap-2">
          <p className="text-sm space-x-1">
            <span className="text-red-500 font-semibold">
              {conflictingCrewChangeSeamen.length} seafarers
            </span>
            <span>are included in other crew changes.</span>
          </p>
          <p className="text-sm font-semibold">
            You can restore this crew change without the {conflictingCrewChangeSeamen.length}{' '}
            seafarers.
          </p>
        </div>
        <div className="space-y-4">
          {conflictingCrewChangeSeamen.map(
            ({ id, seaman, conflictingCrewChanges: seamanConflictingCrewChanges }) => (
              <div key={id} className="tx-sm">
                <p className="text-red-500 font-semibold inline-flex items-center gap-2">
                  <Icon icon={UsersSwitchIcon} />
                  <span>
                    {seaman.firstName} {seaman.lastName}
                  </span>
                </p>
                {seamanConflictingCrewChanges.map((conflictingCrewChange) => {
                  if (!conflictingCrewChange) {
                    return <></>
                  }
                  return (
                    <div key={conflictingCrewChange.bucketId} className="flex items-center gap-10">
                      <div className="flex flex-row space-x-4 my-2">
                        <div className="flex items-center ext-sm font-semibold leading-none text-gray-700">
                          <Icon icon={BoatIcon} className="mr-2 text-gray-400" />
                          {conflictingCrewChange.vesselName}
                        </div>
                        <div className="flex items-center text-sm font-semibold leading-none text-gray-700">
                          <Icon icon={PointOfInterestIcon} className="mr-2 text-gray-400" />
                          {conflictingCrewChange.portName}
                        </div>
                      </div>
                      <dl className="flex items-center space-x-4 flex-1 my-2">
                        <div className="flex flex-col md:flex-row md:space-x-1 w-full">
                          <dt className="text-gray-500 text-xs md:text-sm">ETA:</dt>
                          <VesselSchedule
                            showRelativeTime={false}
                            vesselSchedule={{
                              ETA: conflictingCrewChange.ETA,
                              ETB: conflictingCrewChange.ETB,
                              ETD: conflictingCrewChange.ETD,
                              seaport: {
                                timezoneOlson: 'Etc/UTC',
                              },
                            }}
                            timeType="ETA"
                          />
                        </div>
                        <div className="flex flex-col md:flex-row md:space-x-1 w-full">
                          <dt className="text-gray-500 text-xs md:text-sm">ETB:</dt>
                          <VesselSchedule
                            showRelativeTime={false}
                            vesselSchedule={{
                              ETA: conflictingCrewChange.ETA,
                              ETB: conflictingCrewChange.ETB,
                              ETD: conflictingCrewChange.ETD,
                              seaport: {
                                timezoneOlson: 'Etc/UTC',
                              },
                            }}
                            timeType="ETB"
                          />
                        </div>
                        <div className="flex flex-col md:flex-row md:space-x-1 w-full">
                          <dt className="text-gray-500 text-xs md:text-sm">ETD:</dt>
                          <VesselSchedule
                            showRelativeTime={false}
                            vesselSchedule={{
                              ETA: conflictingCrewChange.ETA,
                              ETB: conflictingCrewChange.ETB,
                              ETD: conflictingCrewChange.ETD,
                              seaport: {
                                timezoneOlson: 'Etc/UTC',
                              },
                            }}
                            timeType="ETD"
                          />
                        </div>
                      </dl>
                    </div>
                  )
                })}
              </div>
            )
          )}
        </div>
      </div>
      <hr />
      <div className="flex justify-between py-4 px-6">
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onRestore}>Restore Crew Change</Button>
      </div>
    </>
  )
}
